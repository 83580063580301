// TODO - take array of promises instead of a single one
export default (target) => {
  return async (
    promise: Promise<any | void>,
    successMessage?: string,
    successRedirectPath?: string,
  ): Promise<any | void> => {
    const { showNotification } = target.props;

    try {
      const promiseData = await promise;
      successMessage && showNotification && showNotification(successMessage);

      target.setState({
        loading: false,
        error: null,
      });

      if (successRedirectPath) {
        // @ts-ignore
        const { history } = target.props;
        history && history.push(successRedirectPath);
      }

      return promiseData;
    } catch ({ message }) {
      message && showNotification && showNotification(message);

      target.setState({
        loading: false,
        error: message,
      });
    }
  };
};
