import React, { FunctionComponent } from 'react';
import { Agreement, Profile } from 'westside-types';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import AppContextHeader from '../UI/Header/AppContextHeader';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { Account } from '../../store/account';
import { Box, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { MainSidebarMenu } from '../UI/Menu';
import ContentHeader from '../UI/Header/ContentHeader';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import { StyledFormControl } from './List.styles';

export enum AgreementCategory {
  NDA = 'NDA',
  DEVELOPER = 'DEVELOPER',
}

export interface Agreement {
  id: string;
  category: AgreementCategory;
  name: string;
  selected: boolean;
}

type AgreementsListProps = {
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
  agreements: Agreement.AgreementsListEntry[];
  onAgreementSelected: (agreementId: string, type: Agreement.AgreementType) => void;
};

const AgreementsList: FunctionComponent<AgreementsListProps> = ({
  logOut,
  account,
  personalProfile,
  companyProfile,
  agreements,
  onAgreementSelected,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <Box flex="1" display="flex" flexDirection="row">
      <Sidebar
        logoType={account.role === 'ADMIN' ? LogoType.LOGO : LogoType.AVATAR}
        logo={account.role === 'ADMIN' ? companyProfile?.logoFileId : personalProfile?.avatarFileId}
      >
        <MainSidebarMenu userRole={account.role} />
      </Sidebar>
      <Box flex="1" display="flex" justifyContent="center" flexDirection="column">
        <AppContextHeader account={account} avatar={personalProfile?.avatarFileId} />
        <PageLayout className="with-sidebar">
          <Box height="100%" mt={4} mb={4}>
            <ContentHeader
              title="Agreements"
              icon={<BookmarksIcon fontSize="large" color="primary" />}
            />
            {agreements &&
              agreements.map((agreement, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <Box flex={1}>
                    <Typography variant="body1">{agreement.name}</Typography>
                  </Box>
                  <StyledFormControl variant="outlined">
                    <InputLabel id={`category-${index}`}>Agreement Type</InputLabel>
                    <Select
                      className="rounded"
                      disableUnderline={true}
                      value={agreement.type}
                      onChange={(event) =>
                        onAgreementSelected(
                          agreement.id,
                          event.target.value as Agreement.AgreementType,
                        )
                      }
                      label="Agreement Type"
                    >
                      <MenuItem value={Agreement.AgreementType.DEVELOPER}>Developer</MenuItem>
                      <MenuItem value={Agreement.AgreementType.NDA}>NDA</MenuItem>
                    </Select>
                  </StyledFormControl>
                </Box>
              ))}
            {(!agreements || agreements.length <= 0) && (
              <Typography variant="body1">No Data</Typography>
            )}
          </Box>
        </PageLayout>
      </Box>
    </Box>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default AgreementsList;
