import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { StyledFormControl } from './FaqSearch.styles';

type FaqSearchProps = {
  searchQuery: string;
  searchQueryChange: (q: string) => void;
};

const FaqSearchComponent: FunctionComponent<FaqSearchProps> = ({
  searchQuery,
  searchQueryChange,
}) => (
  <Box mb={4}>
    <StyledFormControl variant="outlined">
      <OutlinedInput
        type="text"
        className="rounded"
        endAdornment={<SearchIcon color="primary" />}
        placeholder="Search for a topic"
        id="search"
        value={searchQuery}
        onChange={(e) => searchQueryChange(e.target.value)}
      />
    </StyledFormControl>
  </Box>
);

export default FaqSearchComponent;
