import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

export const SidebarContainer = styled.div`
  background: #fbfbfb;
  width: 228px;
  box-sizing: border-box;
`;

export const SidebarHeader = styled.div`
  min-height: 90px;
  background-image: linear-gradient(180deg, #ffffff, #f3f8fa);
  padding-left: ${({ theme }) => theme.spacing() * 4}px;
  padding-right: ${({ theme }) => theme.spacing() * 2}px;
  padding-top: ${({ theme }) => theme.spacing() * 2}px;

  .MuiTypography-subtitle1 {
    font-size: 18px;
  }

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
