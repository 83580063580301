import { getCookie, setCookie, deleteCookie } from './cookies';

export const setAuthToken = (token: string): void => {
  if (!token) {
    removeAuthToken();
    return;
  }

  setCookie('Auth:Token', token);
};

export const removeAuthToken = (): void => {
  deleteCookie('Auth:Token');
};

export const getAuthToken = (): string | null => {
  return getCookie('Auth:Token') || null;
};

export const hasActiveAuthToken = (): boolean => {
  const token = getCookie('Auth:Token') || null;
  return !!token;
};
