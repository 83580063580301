import formatErrorMessage from '../lib/format-error-messages';
import initialState, { Account } from '../store/account';
import { Auth } from 'westside-types';
import { Dispatch } from '../store';
import api from '../lib/api';
import { setAuthToken } from '../lib/jwt';

export default {
  namespace: 'account',

  state: initialState,

  reducers: {
    setAccount(state: Account, account: Auth.AccountDto): Account {
      return {
        ...state,
        ...account,
      };
    },
    setToken(state: Account, token: string): Account {
      setAuthToken(token);
      return {
        ...state,
      };
    },
  },

  effects: (dispatch: Dispatch) => ({
    async logIn(credentials: Auth.CredentialsDto): Promise<void> {
      try {
        const response = await api.post('/auth/login', credentials);
        const { token } = response.data;
        const parsedToken: string = token.split('.');
        const accountData: Auth.AccountDto = JSON.parse(atob(parsedToken[0]));
        const secret: string = token;

        dispatch.account.setToken(secret);
        dispatch.account.setAccount(accountData);
        await dispatch.profile.loadProfile();
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async register(user: Auth.RegisterDto): Promise<void> {
      try {
        await api.post('/auth/register', user);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async setPassword({
      id,
      credentials,
    }: {
      id: string;
      credentials: Auth.FinishRegistrationDto;
    }): Promise<void> {
      try {
        await api.post(`/auth/${id}/verify`, credentials);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async resetPassword({ email }: Auth.ForgotPasswordDto): Promise<void> {
      try {
        await api.post(`/auth/resetPassword`, email);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async logOut(): Promise<void> {
      try {
        dispatch.account.setToken('');
        dispatch({ type: 'RESET_APP' });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
  }),
};
