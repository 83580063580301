import React, { FunctionComponent, useState, useEffect } from 'react';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { Account } from '../../store/account';
import { App, Profile } from 'westside-types';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import Checkbox from '../UI/Checkbox';
import Pill from '../UI/Pill';
import Button from '../UI/Button';
import AppContextHeader from '../UI/Header/AppContextHeader';
import { RejectDialog } from './RejectDialog';

interface ReviewApp {
  logOut: () => void;
  submitReviewForm: (reviewData: App.SetAppReleaseReviewData) => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  initialReviewData: App.AppReleaseReviewPreview | undefined;
  reviewedAppName: string | undefined;
  loading: boolean;
  error: string | null;
  downloadApk: (apkFileId: string) => void;
}

const { PENDING, APPROVED, REJECTED } = App.AppReleaseState;

const initialValues: App.AppReleaseReviewPreview = {
  securityTest: false,
  performanceTest: false,
  experienceTest: false,
  efficiencyTest: false,
  documentationReview: false,
  apkFileId: '',
  state: PENDING,
};

const ReviewAppComponent: FunctionComponent<ReviewApp> = ({
  account,
  personalProfile,
  initialReviewData,
  reviewedAppName,
  loading,
  logOut,
  submitReviewForm,
  downloadApk,
}) => {
  const initialComputedData = initialReviewData || initialValues;
  const [formData, setFormData] = useState<App.AppReleaseReviewPreview>(initialComputedData);
  const [enableSave, setSaveState] = useState<boolean>(false);
  const [enableApprove, setApproveState] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const checkboxes = [
    { name: 'securityTest', label: 'Security Test' },
    { name: 'performanceTest', label: 'Performance Test' },
    { name: 'experienceTest', label: 'Experience Test' },
    { name: 'efficiencyTest', label: 'Efficiency Test' },
    { name: 'documentationReview', label: 'Documentation Review' },
  ];

  useEffect(() => {
    validateButtons(initialComputedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReviewData]);

  const validateButtons = (values) => {
    const checkboxFields = checkboxes.map((checkbox) => checkbox.name);
    const trulySet = Object.keys(values).filter(
      (key) => values[key] && checkboxFields.includes(key),
    ).length;

    setSaveState(trulySet > 0);
    setApproveState(trulySet === checkboxFields.length);
  };

  const submitForm = (state, reason = '') => {
    const {
      securityTest,
      performanceTest,
      experienceTest,
      efficiencyTest,
      documentationReview,
    } = formData;
    submitReviewForm({
      securityTest,
      performanceTest,
      experienceTest,
      efficiencyTest,
      documentationReview,
      state,
      reason,
    });
  };

  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <AppContextHeader
        avatar={personalProfile?.avatarFileId}
        account={account}
        closeButtonText={`Review ${reviewedAppName || ''}`}
        closeButtonLink="/app-center"
      />
      <PageLayout>
        <Box height="100%" pt={4}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box flex="1">
              <Typography variant="h1">Review {reviewedAppName || ''}</Typography>
            </Box>
            <Pill
              label="Download"
              onClick={() => downloadApk(initialComputedData.apkFileId)}
              IconComponent={() => <GetAppIcon color="primary" />}
            />
          </Box>
          <Box mt={3} mb={3}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Formik
              enableReinitialize
              initialValues={initialComputedData}
              onSubmit={() => {}}
              validate={(values: App.AppReleaseReviewPreview) => {
                setFormData(values);
                validateButtons(values);
              }}
            >
              {() => {
                return (
                  <Box>
                    {checkboxes.map((checkbox, index) => (
                      <Checkbox
                        key={`checkbox-${index}`}
                        label={checkbox.label}
                        id={checkbox.name}
                        name={checkbox.name}
                        required
                      />
                    ))}
                  </Box>
                );
              }}
            </Formik>
            <Box mt={4} display="flex" flexDirection="row" alignItems="center">
              <Link to="/app-center">
                <Button variant="outlined" color="primary" disabled={loading}>
                  Cancel
                </Button>
              </Link>
              <Box ml={3}>
                <Button
                  variant="contained"
                  altColor="light"
                  disabled={loading}
                  onClick={() => setRejectModalVisible(true)}
                >
                  Reject
                </Button>
                <RejectDialog
                  appName={reviewedAppName}
                  open={rejectModalVisible}
                  onCancel={() => setRejectModalVisible(false)}
                  onConfirm={(reason: string) => {
                    setRejectModalVisible(false);
                    submitForm(REJECTED, reason);
                  }}
                />
              </Box>
              {enableSave && (
                <Box ml={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!enableSave || loading}
                    onClick={() => submitForm(PENDING)}
                  >
                    Save
                  </Button>
                </Box>
              )}
              <Box ml={3}>
                <Button
                  variant="contained"
                  altColor="success"
                  disabled={!enableApprove || loading}
                  onClick={() => submitForm(APPROVED)}
                >
                  Approve
                </Button>
              </Box>
              <Box ml={3}>{loading && <CircularProgress color="secondary" size={28} />}</Box>
            </Box>
          </Box>
        </Box>
      </PageLayout>
      <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
    </PageContainer>
  );
};

export default ReviewAppComponent;
