import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import { sizes } from '../../theme';

export const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  right: -${({ theme }) => theme.spacing() * 4}px;
  top: ${({ theme }) => theme.spacing() * 2}px;
  cursor: pointer;
`;

export const FormWrapper = styled(Box)`
  &.scrolled {
    .fixed-input {
      position: fixed;
      width: 100%;
      padding: 0 ${({ theme }) => theme.spacing() * 2}px;
      top: ${({ theme }) => theme.spacing() * 4}px;
      max-width: calc(${sizes.formPageMaxWidth}px - ${({ theme }) => theme.spacing() * 4}px);
      z-index: 2;
      input {
        box-shadow: 0px 0px 11.9291px rgba(39, 170, 225, 0.53);
        background: #fff;
      }
    }
  }
`;
