import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Dashboard/Landing';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  loadProfile: dispatch.profile.loadProfile,
  loadCompany: dispatch.profile.loadCompany,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type LandingState = {};

class LandingContainer extends Component<ConnectedProps, LandingState> {
  componentDidMount() {
    const { loadProfile, loadCompany } = this.props;
    loadProfile();
    loadCompany();
  }

  render() {
    const { account, logOut, personalProfile, companyProfile } = this.props;
    return (
      <Layout
        account={account}
        logOut={logOut}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer);
