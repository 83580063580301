import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Button from '../UI/Button';

export const StyledBackgroundAccent = styled.div`
  position: fixed;
  top: 10%;
  left: 125%;
  width: 100%;
  padding-top: 100%;
  background: linear-gradient(-45deg, rgba(28, 117, 188, 1) 50%, rgba(39, 170, 225, 1) 50%);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  z-index: -1;
`;

export const StyledFormWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing() * 4}px ${({ theme }) => theme.spacing() * 11}px;
  background: #ffffff;
  width: 100%;
  max-width: 507px;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(39, 170, 225, 0.23);
  .login-form,
  .register-form {
    .MuiTextField-root {
      margin-bottom: ${({ theme }) => theme.spacing() * 2}px;
    }
    .MuiFormControlLabel-label {
      text-transform: none;
    }
    button.submit {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const StyledGetHelpButton = styled(Button)`
  width: auto;
  min-width: 0;
  padding-top: 0;
  padding-bottom: 0;
`;

export const StyledSuccessIconWrapper = styled(Box)`
  width: 113px;
  height: 113px;
  border: 2px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: 50%;
  text-align: center;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.light};
    width: 45%;
    height: 45%;
  }
`;
