import { Auth } from 'westside-types';

export interface UsersState {
  users: Auth.AccountOverview[];
}

const initialState: UsersState = {
  users: [],
};

export default initialState;
