import React, { FunctionComponent } from 'react';
import { Auth } from 'westside-types';
import Box from '@material-ui/core/Box';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { StyledLink } from './Menu.styles';
import { SidebarMenuItem } from './common';

const SIDEBAR_MENU_ITEMS: SidebarMenuItem[] = [
  {
    label: 'Application Center',
    link: ['/app-center', '/preview-app'],
  },
  {
    label: 'Resources',
    link: ['/resources'],
  },
  {
    label: "FAQ's",
    link: ['/faq'],
  },
  {
    label: 'Agreements',
    link: ['/agreements'],
  },
  {
    label: 'Users',
    link: ['/users'],
    roles: ['ADMIN'],
  },
];

export interface SidebarMenuElementProps {
  userRole: Auth.Role;
}

const SidebarMenuElement: FunctionComponent<RouteComponentProps & SidebarMenuElementProps> = ({
  userRole,
  location,
}) => {
  return (
    <Box>
      {SIDEBAR_MENU_ITEMS.filter((item: SidebarMenuItem) =>
        item.roles ? item.roles.includes(userRole) : true,
      ).map((item: SidebarMenuItem, index) => {
        const matchedRoute = item.link.filter((link) => {
          return location.pathname.includes(link);
        });

        return (
          <StyledLink key={`menu-item-${index}`} to={item.link[0]} active={matchedRoute.length > 0}>
            {item.label}
          </StyledLink>
        );
      })}
    </Box>
  );
};

export const MainSidebarMenu = withRouter(SidebarMenuElement);
