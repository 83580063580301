import yup, { object, string } from 'yup';
import { validationMessages } from '../constants/messages';

const { field } = validationMessages;

const validationSchema = object().shape({
  description: string().required(field.required).nullable(),
  fleetConsiderationsAndRequirements: string().required(field.required).nullable(),
  releaseNotes: string().required(field.required).nullable(),
});

export type CreateAppSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
