import React, { Component, FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { IconWrapper, StyledButton } from './ContentHeader.styles';

type ContentHeaderProps = {
  editLink?: string;
  editCapabilities?: boolean;
  editText?: string;
  title: string;
  icon?: Component;
  editIcon?: Component;
};

const ContentHeader: FunctionComponent<ContentHeaderProps> = ({
  editCapabilities,
  editLink,
  editText,
  editIcon,
  title,
  icon,
}) => (
  <Box mb={4} display="flex" flexDirection="row">
    <Box flex="1">
      <Typography variant="h1">
        <IconWrapper>{icon}</IconWrapper>
        <span>{title}</span>
      </Typography>
    </Box>
    {editCapabilities && (
      <div>
        <Link to={editLink}>
          <StyledButton color="primary" variant="contained" disableElevation>
            <span>{editText}</span>
            {editIcon}
          </StyledButton>
        </Link>
      </div>
    )}
  </Box>
);

export default ContentHeader;
