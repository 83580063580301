import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Profile/ViewProfile';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
  emailNotificationsActive: state.notifications.emailActive,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  loadProfile: dispatch.profile.loadProfile,
  loadCompany: dispatch.profile.loadCompany,
  loadEmailNotificationsActive: dispatch.notifications.loadEmailNotificationsActive,
  setEmailNotificationsActive: dispatch.notifications.setEmailNotificationsActive,
});

type ProfileViewProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type ProfileViewState = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class ProfileView extends Component<ProfileViewProps, ProfileViewState> {
  componentDidMount() {
    const { loadProfile, loadCompany } = this.props;
    loadProfile();
    loadCompany();
  }

  render() {
    const {
      account,
      logOut,
      personalProfile,
      companyProfile,
      emailNotificationsActive,
      setEmailNotificationsActive,
    } = this.props;
    return (
      <Layout
        account={account}
        logOut={logOut}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        emailNotificationsActive={emailNotificationsActive}
        onEmailNotificationsActiveChange={(active) => setEmailNotificationsActive({ active })}
      />
    );
  }
}
