import React, { FunctionComponent, useState } from 'react';
import { Resources, Profile } from 'westside-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import YouTubePlayer from 'react-youtube';
import { Account } from '../../store/account';
import config from '../../config';
import Header from '../UI/Header';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import Dialog from '../UI/Dialog';
import Pill from '../UI/Pill';
import Button from '../UI/Button';
import formatDuration from '../../helpers/format-duration';
import { PageContainer, PageLayout } from '../UI/Layouts';
import {
  StyledHeaderIcon,
  StyledVideoLightbox,
  StyledVideoHolder,
  StyledDetailsContainer,
  StyledVideoTitle,
} from './Resources.styles';

interface ResourceDetails {
  loading: boolean;
  account: Account;
  companyProfile: Profile.CompanyDto;
  id: string;
  logOut: () => void;
  downloadFile: (id: string) => void;
  details: Resources.Resource | undefined;
}

interface VideoData {
  [key: string]: { title: string; duration: number };
}

const getVideoId = (url: Resources.YouTubeURL) => {
  return url.split('/').pop() || '';
};

const fileSrc = `${config.apiRootUrl}/files`;
const ResourceDetailsComponent: FunctionComponent<ResourceDetails> = ({
  loading,
  logOut,
  downloadFile,
  account,
  companyProfile,
  id,
  details,
}) => {
  const [showVideoLightbox, toggleShowVideoLightbox] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [videoData, setVideoData] = useState<VideoData>({});

  const onYouTubePlayerReady = (event) => {
    const { title, video_id } = event.target.getVideoData();
    const duration = event.target.getDuration();
    setVideoData({ ...videoData, [video_id]: { title, duration } });
  };

  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <PageLayout display="flex" flexDirection="column" sticky>
        <Box display="flex" mt={4} mb={4} flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center" flex="1">
            <Link to="/resources">
              <Pill IconComponent={() => <ArrowBackIcon />} label="Back" />
            </Link>
            <Box ml={2}>{loading && <CircularProgress color="secondary" size={20} />}</Box>
          </Box>
          {account.role === 'ADMIN' && details && (
            <Link to={`/resources/edit/${details.type}/${id}`}>
              <Button variant="contained" color="primary" disableElevation>
                <Box display="inline" mr={1}>
                  Edit
                </Box>
                <EditIcon fontSize="small" />
              </Button>
            </Link>
          )}
        </Box>
        <Box pb={2}>
          <Typography variant="h1">{details && details.title}</Typography>
        </Box>
        <StyledDetailsContainer flex="1">
          {details && (
            <>
              <Box flexDirection="row" display="flex" mt={2}>
                <StyledHeaderIcon src={`${fileSrc}/${details.iconFileId}`} alt={details.title} />
                <Box ml={4}>
                  <Box mb={1}>
                    <Typography variant="h2">Description</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="body1">{details.description}</Typography>
                  </Box>
                  <Pill
                    IconComponent={() => <GetAppIcon color="secondary" />}
                    label="Download"
                    onClick={() => downloadFile(details.appFileId)}
                  />
                </Box>
              </Box>
              {details.videosUrls && (
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  mt={4}
                >
                  {details.videosUrls.map((video) => (
                    <StyledVideoHolder
                      key={video}
                      flexBasis="460px"
                      height="300px"
                      onClick={() => {
                        setSelectedVideo(video);
                        toggleShowVideoLightbox(true);
                      }}
                    >
                      <YouTubePlayer
                        id={getVideoId(video)}
                        videoId={getVideoId(video)}
                        opts={{
                          width: '100%',
                          height: '300',
                        }}
                        onReady={onYouTubePlayerReady}
                      />
                      <Box display="flex" justifyContent="space-between">
                        <StyledVideoTitle variant="caption">
                          {videoData[getVideoId(video)] && videoData[getVideoId(video)].title}
                        </StyledVideoTitle>
                        <Typography variant="caption">
                          {videoData[getVideoId(video)] &&
                            `Duration: ${formatDuration(videoData[getVideoId(video)].duration)}`}
                        </Typography>
                      </Box>
                    </StyledVideoHolder>
                  ))}
                </Box>
              )}
              <Box mt={6} width="50%">
                <Typography variant="h2">Details</Typography>
                <Typography variant="body1">{details.details}</Typography>
              </Box>
            </>
          )}
        </StyledDetailsContainer>
      </PageLayout>
      <Dialog
        open={showVideoLightbox}
        onCancel={() => toggleShowVideoLightbox(false)}
        disableActions
        maxWidth="md"
        isLightbox
      >
        <StyledVideoLightbox>
          <iframe
            title={selectedVideo}
            frameborder="0"
            allowfullscreen
            src={selectedVideo}
          ></iframe>
        </StyledVideoLightbox>
      </Dialog>
      <Footer>
        <FooterLink to={'/'}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default ResourceDetailsComponent;
