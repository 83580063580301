import React, { FunctionComponent, useState } from 'react';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { AppCenterActions } from '../../types/app-center';
import { AppCenterRowContainer, NameWrapper, StyledButton } from './AppCenterRow.styles';
import Dialog from '../UI/Dialog';

interface AppCenterRowProps {
  id: string;
  disabled: boolean;
  actions: string[];
  name?: string;
  disableStyles?: boolean;
  StatusPill?: FunctionComponent;
  isAppOwner?: boolean;
  downloadApk?: () => void;
  onDelete?: () => void;
  onToggleApp?: (state: boolean) => void;
}

const { REVIEW, DELETE, DOWNLOAD, VIEW, TOGGLE, EDIT } = AppCenterActions;

const AppCenterRow: FunctionComponent<AppCenterRowProps> = ({
  name,
  id,
  disabled,
  actions,
  disableStyles,
  StatusPill,
  isAppOwner = true,
  downloadApk,
  onDelete,
  onToggleApp,
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [showAppDisableDialog, setShowAppDisableDialog] = useState<boolean>(false);
  const [showAppEnableDialog, setShowAppEnableDialog] = useState<boolean>(false);
  const handleToggleApp = () => {
    if (disabled) {
      setShowAppEnableDialog(true);
      return;
    }

    setShowAppDisableDialog(true);
  };

  return (
    <AppCenterRowContainer disableStyles={disableStyles}>
      {name && (
        <NameWrapper>
          <Typography variant="body1">{name}</Typography>
        </NameWrapper>
      )}
      {StatusPill && (
        <Box mr={2}>
          <StatusPill />
        </Box>
      )}
      {actions.includes(DELETE) && (
        <>
          <IconButton onClick={() => setDeleteModalVisible(true)}>
            <DeleteIcon color="secondary" />
          </IconButton>
          <Dialog
            open={deleteModalVisible}
            title={`Are you sure you want to delete ${name || 'this app'}?`}
            confirmButtonText="Yes"
            cancelButtonText="No"
            onCancel={() => setDeleteModalVisible(false)}
            onConfirm={() => {
              onDelete && onDelete();
              setDeleteModalVisible(false);
            }}
          >
            <Box mt={3}>
              <Typography variant="body1" align="center">
                All app versions will be deleted. This operation cannot be undone.
              </Typography>
            </Box>
          </Dialog>
        </>
      )}
      {actions.includes(DOWNLOAD) && (
        <IconButton onClick={downloadApk}>
          <GetAppIcon color="secondary" />
        </IconButton>
      )}
      {isAppOwner && actions.includes(EDIT) && (
        <Link to={`/edit-app/${id}`}>
          <StyledButton variant="contained" color="primary" disableElevation>
            <Box display="inline" mr={1}>
              Edit
            </Box>
            <EditIcon fontSize="small" />
          </StyledButton>
        </Link>
      )}
      {actions.includes(VIEW) && (
        <Link to={`/preview-app/${id}`}>
          <StyledButton variant="contained" color="primary" disableElevation>
            View
          </StyledButton>
        </Link>
      )}
      {actions.includes(REVIEW) && (
        <Link to={`/review-app/${id}`}>
          <StyledButton variant="contained" color="primary" disableElevation className="review">
            Review
          </StyledButton>
        </Link>
      )}
      {actions.includes(TOGGLE) && (
        <>
          <StyledButton
            variant="contained"
            altColor={disabled ? 'success' : 'light'}
            disableElevation
            onClick={() => handleToggleApp()}
          >
            {disabled ? 'Set Live' : 'Disable'}
          </StyledButton>
          <Dialog
            open={showAppDisableDialog}
            onCancel={() => setShowAppDisableDialog(false)}
            onConfirm={() => {
              onToggleApp && onToggleApp(false);
              setShowAppDisableDialog(false);
            }}
            cancelButtonText="Cancel"
            confirmButtonText="Disable"
            title="Disable Selected App?"
          >
            <Box mt={3}>
              <Typography variant="body1" align="center">
                Disabling this app will place app back to In-Review status and remove the listing
                from the PS Connect Marketplace.
              </Typography>
            </Box>
          </Dialog>
          <Dialog
            open={showAppEnableDialog}
            onCancel={() => setShowAppEnableDialog(false)}
            onConfirm={() => {
              onToggleApp && onToggleApp(true);
              setShowAppEnableDialog(false);
            }}
            cancelButtonText="Cancel"
            confirmButtonText="Set Live"
            title="Enable Selected App?"
          >
            <Box mt={3}>
              <Typography variant="body1" align="center">
                Enabling this app will place the app back to its current status in the listing for
                the PS Connect Marketplace.
              </Typography>
            </Box>
          </Dialog>
        </>
      )}
    </AppCenterRowContainer>
  );
};
export default AppCenterRow;
