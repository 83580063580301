import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.light : theme.palette.text.primary};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
  display: block;
  padding-top: ${({ theme }) => theme.spacing()}px;
  padding-bottom: ${({ theme }) => theme.spacing()}px;
  padding-left: ${({ theme }) => theme.spacing() * 2}px;
  padding-right: ${({ theme }) => theme.spacing()}px;
  margin-bottom: ${({ theme }) => theme.spacing()}px;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    display: ${({ active }) => (active ? 'block' : 'none')};
    width: 4px;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.light};
    content: '';
  }
`;
