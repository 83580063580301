import styled from 'styled-components';

export const Tile = styled.div`
  box-shadow: 0px 0px 11.9291px rgba(39, 170, 225, 0.23);
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing() * 2}px ${({ theme }) => theme.spacing() * 4}px;
  margin: ${({ theme }) => theme.spacing() * 2}px 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const TileTitle = styled.h2`
  font-size: 1.125rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 0;
  padding: 0;
  line-height: 32px;
`;

export const TileSubtitle = styled.h3`
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  text-transform: capitalize;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin: 0;
  padding: 0;
  line-height: 24px;
`;

export const TileHeading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// TODO: Replace with SVG icon
export const TileIcon = styled.div`
  padding: ${({ theme }) => theme.spacing() * 6}px ${({ theme }) => theme.spacing() * 4}px;
  img {
    width: 77px;
    height: 77px;
  }
`;

export const TileLink = styled.div`
  display: flex;
  align-items: flex-end;
`;
