import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { App, Profile } from 'westside-types';
import { AppCenterActions } from '../../types/app-center';
import { Account } from '../../store/account';
import Header from '../UI/Header';
import AppContextHeader from '../UI/Header/AppContextHeader';
import AppCenterRow from './AppCenterRow';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { MainSidebarMenu } from '../UI/Menu';
import Pill from '../UI/Pill';
import strings from '../../constants/strings';
import { PageContainer, PageLayout, PageHeader } from '../UI/Layouts';
import ExpansionPanel from '../UI/ExpansionPanel';
import { StyledStatusIcon } from './Layout.styles';
import AddButton from '../UI/AddButton';

interface AppCenter {
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
  downloadApk: (apkId: string) => void;
  disableApp: (appId: string) => void;
  enableApp: (appId: string) => void;
  loading: boolean;
  allApps: App.AppCenterAppDto[] | undefined;
  deleteApp: (appId: string) => void;
}

const AppCenterComponent: FunctionComponent<AppCenter> = ({
  logOut,
  disableApp,
  enableApp,
  downloadApk,
  deleteApp,
  allApps,
  account,
  personalProfile,
  companyProfile,
  loading,
}) => {
  const { DRAFT, IN_REVIEW, PENDING, APPROVED, REJECTED } = App.AppComputedState;
  const { REVIEW, DELETE, DOWNLOAD, VIEW, TOGGLE, EDIT } = AppCenterActions;

  const filteredApps: {
    [key: string]: App.AppCenterAppDto[];
  } = {
    [REJECTED]: [],
    [DRAFT]: [],
    [IN_REVIEW]: [],
    [PENDING]: [],
    [APPROVED]: [],
  };
  const adminActions: {
    [key: string]: string[];
  } = {
    [REJECTED]: [VIEW],
    [DRAFT]: [VIEW],
    [PENDING]: [REVIEW, VIEW, DELETE, DOWNLOAD],
    [IN_REVIEW]: [DELETE, DOWNLOAD, VIEW, REVIEW],
    [APPROVED]: [DELETE, DOWNLOAD, VIEW, TOGGLE],
  };

  const devActions: {
    [key: string]: string[];
  } = {
    [REJECTED]: [VIEW],
    [DRAFT]: [EDIT, VIEW, TOGGLE],
    [PENDING]: [EDIT, VIEW, TOGGLE],
    [IN_REVIEW]: [EDIT, VIEW, TOGGLE],
    [APPROVED]: [EDIT, VIEW, TOGGLE],
  };

  // TODO - `info` text to be determined. Placeholders for now
  const sections = [
    {
      title: 'Available Apps',
      context: APPROVED,
    },
    {
      title: 'In Review',
      context: IN_REVIEW,
      info: 'Applications in this status currently are in review.',
    },
    {
      title: 'Ready for Review',
      context: PENDING,
      info:
        'Applications in this status have not been reviewed yet but are available to be reviewed.',
    },
    {
      title: 'Pre-Submission',
      context: DRAFT,
      info: 'Applications in this status have not been submitted and are a draft version.',
    },
    {
      title: 'Rejected',
      context: REJECTED,
      info: 'Applications in this status have been rejected.',
    },
  ];

  const handleToggleApp = (state: boolean, appId: string): void => {
    if (state) {
      enableApp(appId);
      return;
    }

    disableApp(appId);
  };

  if (allApps) {
    allApps.forEach((app) => {
      filteredApps[app.computedState].push(app);
    });
  }

  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <Box flex="1" display="flex" flexDirection="row">
        <Sidebar
          logoType={account.role === 'ADMIN' ? LogoType.LOGO : LogoType.AVATAR}
          logo={
            account.role === 'ADMIN' ? companyProfile?.logoFileId : personalProfile?.avatarFileId
          }
        >
          <MainSidebarMenu userRole={account.role} />
        </Sidebar>
        <Box flex="1" display="flex" justifyContent="center" flexDirection="column">
          <AppContextHeader account={account} avatar={personalProfile?.avatarFileId} />
          <PageLayout className="with-sidebar">
            <PageHeader title="Application Center" loading={loading}>
              <AddButton to="/create-app">Add App</AddButton>
            </PageHeader>
            {allApps && (
              <>
                {account.role === 'ADMIN' ? (
                  <>
                    {sections.map(({ context, title, info }, index) => (
                      <ExpansionPanel
                        key={`panel-${index}`}
                        title={title}
                        expanded={filteredApps[context].length > 0}
                        info={info}
                        StatusIcon={() => <StyledStatusIcon status={context} />}
                      >
                        {filteredApps[context].map((app, index) => (
                          <AppCenterRow
                            key={`${context}-${index}`}
                            id={app.id}
                            name={app.name}
                            onDelete={() => deleteApp(app.id)}
                            disabled={app.disabled}
                            actions={adminActions[context]}
                            downloadApk={() => downloadApk(app.apkFileId)}
                            onToggleApp={(state: boolean) => handleToggleApp(state, app.id)}
                          />
                        ))}
                      </ExpansionPanel>
                    ))}
                  </>
                ) : (
                  <>
                    <Typography variant="h1">All Apps</Typography>
                    {Object.values(filteredApps)
                      .flat()
                      .map((app, index) => (
                        <>
                          <AppCenterRow
                            key={`${index}`}
                            id={app.id}
                            name={app.name}
                            disabled={app.disabled}
                            actions={devActions[app.computedState]}
                            onToggleApp={(state: boolean) => handleToggleApp(state, app.id)}
                            StatusPill={() => (
                              <Pill
                                label={strings[app.computedState]}
                                computedState={app.computedState}
                                contained
                              />
                            )}
                          />
                        </>
                      ))}
                  </>
                )}
              </>
            )}
          </PageLayout>
        </Box>
      </Box>
      <Footer>
        <FooterLink to={'/'}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default AppCenterComponent;
