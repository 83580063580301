import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Profile/EditProfile';
import promiseCallbackHandler from '../../lib/promise-callback';
import { Profile } from 'westside-types';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  profile: state.profile.personalProfile,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  loadProfile: dispatch.profile.loadProfile,
  updateProfile: dispatch.profile.updateProfile,
  showNotification: dispatch.snackbar.open,
});

type ProfileEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type ProfileEditState = {};

// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
export default class ProfileEdit extends Component<ProfileEditProps, ProfileEditState> {
  promiseCallback = promiseCallbackHandler(this);
  state = {
    profile: null,
  };

  componentDidMount() {
    const { loadProfile } = this.props;
    loadProfile();
  }

  updateProfile = async (data: Profile.UpdateProfileDto) => {
    const { updateProfile } = this.props;
    await this.promiseCallback(updateProfile(data), 'Profile updated', '/profile');
  };

  render() {
    const { account, logOut } = this.props;
    const profile = this.getEditProfile();
    return (
      <Layout
        account={account}
        logOut={logOut}
        profile={profile}
        onProfileUpdate={this.updateProfile}
      />
    );
  }

  private getEditProfile(): Profile.UpdateProfileDto | null {
    if (!this.props.profile) {
      return null;
    }
    const { name, email, avatarFileId } = this.props.profile;
    return {
      password: '',
      rePassword: '',
      currentPassword: '',
      name,
      email,
      avatarFileId,
    };
  }
}
