import React, { Component } from 'react';
import { connect } from 'react-redux';
import { App } from 'westside-types';
import promiseCallbackHandler from '../../lib/promise-callback';
import { iRootState, Dispatch } from '../../store';
import Layout from '../../components/App/ReviewApp';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  reviewedApp: state.app.reviewedApp,
});
const mapDispatchToProps = ({ app, account, snackbar }: Dispatch) => ({
  logOut: account.logOut,
  getAppById: app.getAppById,
  removeCurrentlyReviewedApp: app.removeCurrentlyReviewedApp,
  postReleaseReview: app.postReleaseReview,
  getReleaseReview: app.getReleaseReview,
  showNotification: snackbar.open,
  downloadApk: app.downloadApk,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type ReviewAppState = {
  loading: boolean;
  error: string | null;
  appDetails: App.AppDto | undefined;
};

class ReviewAppContainer extends Component<ConnectedProps, ReviewAppState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
    appDetails: undefined,
  };

  componentDidMount() {
    const { removeCurrentlyReviewedApp } = this.props;
    // TODO move to local state instead
    removeCurrentlyReviewedApp();
    this.getReviewApp();
  }

  getReviewApp = async (): Promise<void> => {
    // @ts-ignore
    const { getReleaseReview, getAppById, match } = this.props;
    const { appId } = match.params;

    this.setState({ loading: true });

    this.promiseCallback(getReleaseReview({ appId }));
    const appDetails = await this.promiseCallback(getAppById({ appId }));

    this.setState({ appDetails });
    return;
  };

  submitReviewForm = (reviewData: App.SetAppReleaseReviewData): Promise<void> => {
    // @ts-ignore
    const { postReleaseReview, match } = this.props;
    const { appId } = match.params;

    this.setState({ loading: true });
    return this.promiseCallback(
      postReleaseReview({ appId, reviewData }),
      'Review Saved',
      '/app-center',
    );
  };

  render() {
    const { loading, error, appDetails } = this.state;
    const { account, personalProfile, reviewedApp, logOut, downloadApk } = this.props;

    return (
      <Layout
        loading={loading}
        error={error}
        downloadApk={(fileId) => downloadApk(fileId).then((url) => window.open(url, '_blank'))}
        account={account}
        personalProfile={personalProfile}
        logOut={logOut}
        initialReviewData={reviewedApp}
        // @ts-ignore
        reviewedAppName={appDetails?.name}
        submitReviewForm={this.submitReviewForm}
      />
    );
  }
}
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ReviewAppContainer);
