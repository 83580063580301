import { init, RematchRootState, RematchDispatch } from '@rematch/core';
import createPersistPlugin, { getPersistor } from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import storage from 'redux-persist/es/storage';
import { models, RootModel } from '../models';

const loadingPlugin = createLoadingPlugin({});
const persistPlugin = createPersistPlugin({
  version: 2,
  blacklist: [],
  storage,
});

const store = init({
  models,
  redux: {
    middlewares: [],
    rootReducers: {
      RESET_APP: () => {
        return undefined;
      },
    },
  },
  plugins: [persistPlugin, loadingPlugin],
});

export type Dispatch = RematchDispatch<RootModel>;
export type iRootState = RematchRootState<RootModel>;
export const persistor = getPersistor();
export default store;
