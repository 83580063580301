import React, { Component } from 'react';
import { App } from 'westside-types';
import Layout from '../../components/AppCenter/AppCenterPreview';
import promiseCallbackHandler from '../../lib/promise-callback';
import { iRootState, Dispatch } from '../../store';
import { connect } from 'react-redux';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAppRelease: dispatch.app.getAppReleasePreview,
  logOut: dispatch.account.logOut,
  showNotification: dispatch.snackbar.open,
  downloadApk: dispatch.app.downloadApk,
  deleteApp: dispatch.app.deleteApp,
  disableApp: dispatch.app.disableApp,
  enableApp: dispatch.app.enableApp,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type AppCenterState = {
  loading: boolean;
  error: string | null;
  appReleaseDetails: App.AppDto | undefined;
};

class PreviewAppContainer extends Component<ConnectedProps, AppCenterState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
    appReleaseDetails: undefined,
  };

  componentDidMount() {
    this.getAppRelease();
  }

  getAppRelease = async () => {
    // @ts-ignore
    const { getAppRelease, match } = this.props;
    const { appId } = match.params;
    if (appId) {
      this.setState({ loading: true });
      const appReleaseDetails = await this.promiseCallback(getAppRelease({ appId }));

      this.setState({ appReleaseDetails });
    }
  };

  enableApp = async (appId: string): Promise<void> => {
    const { enableApp } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(enableApp(appId));
    return this.getAppRelease();
  };

  disableApp = async (appId: string): Promise<void> => {
    const { disableApp } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(disableApp(appId));
    return this.getAppRelease();
  };

  render() {
    //@ts-ignore
    const {
      logOut,
      account,
      companyProfile,
      personalProfile,
      match,
      downloadApk,
      deleteApp,
    } = this.props;
    const { appId } = match.params;
    const { appReleaseDetails, loading } = this.state;

    return (
      <Layout
        deleteApp={(appId) => this.promiseCallback(deleteApp(appId), 'App deleted', '/app-center')}
        logOut={logOut}
        account={account}
        appId={appId}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        appReleaseDetails={appReleaseDetails}
        loading={loading}
        disableApp={this.disableApp}
        enableApp={this.enableApp}
        downloadApk={(fileId) => downloadApk(fileId).then((url) => window.open(url, '_blank'))}
      />
    );
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(PreviewAppContainer);
