import initialState, { ProfileState } from '../store/profile';
import { Dispatch } from '../store';
import formatErrorMessage from '../lib/format-error-messages';
import api from '../lib/api';
import { Profile } from 'westside-types';

export default {
  namespace: 'profile',

  state: initialState,

  reducers: {
    setPersonalProfile(state: ProfileState, personalProfile: Profile.ProfileDto) {
      return {
        ...state,
        personalProfile,
      };
    },
    setCompanyProfile(state: ProfileState, companyProfile: Profile.CompanyDto) {
      return {
        ...state,
        companyProfile,
      };
    },
  },

  effects: (dispatch: Dispatch) => ({
    async loadProfile() {
      try {
        const { data } = await api.get('/profile');
        dispatch.profile.setPersonalProfile(data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
    async updateProfile(profile: Profile.UpdateProfileDto) {
      try {
        await api.post('/profile', profile);
        const { data } = await api.get('/profile');
        dispatch.profile.setPersonalProfile(data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
    async loadCompany() {
      try {
        const { data } = await api.get('/company');
        dispatch.profile.setCompanyProfile(data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
    async updateCompany(company: Profile.CompanyDto) {
      try {
        await api.post('/company', company);
        const { data } = await api.get('/company');
        dispatch.profile.setCompanyProfile(data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
  }),
};
