import React, { FunctionComponent, ReactElement } from 'react';
import {
  StyledTypography,
  PersistentNotificationWrapper,
  StyledBox,
} from './PersistentNotification.styles';

type PersistentNotificationProps = {
  text: string;
  actionButton?: ReactElement;
};

const PersistentNotification: FunctionComponent<PersistentNotificationProps> = ({
  text,
  actionButton,
}) => (
  <StyledBox pt={1} pb={1} pl={4} pr={4} width="100%" boxSizing="border-box">
    <PersistentNotificationWrapper>
      <StyledTypography variant="caption">{text}</StyledTypography>
      {actionButton && <div>{actionButton}</div>}
    </PersistentNotificationWrapper>
  </StyledBox>
);

export default PersistentNotification;
