import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const shadowAccentWidth = 9999;
export const StyledStepper = styled(Box)`
  box-sizing: border-box;

  .action-buttons {
    position: relative;
    padding: ${({ theme }) => theme.spacing() * 3}px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -${shadowAccentWidth / 2}px;
      width: 100vw;
      height: ${({ theme }) => theme.spacing()}px;
      box-shadow: 0 -4px 4px rgba(39, 170, 225, 0.23);
    }
  }

  .MuiStepLabel-active{
    color: #1C75BC;
  }
`;

export const StyledStepperContent = styled(Box)`
  padding: ${({ theme }) => theme.spacing() * 5}px ${({ theme }) => theme.spacing()}px;
  overflow-y: auto;
`;

export const StyledStepIcon = styled(Box)`
  background: ${({ theme, active }) => (active ? theme.palette.primary.main : 'transpatent')};
  border: ${({ active, theme }) => (active ? 'none' : `1px solid ${theme.palette.grey['400']}`)};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const StyledStepperButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content:center;
  width: 100vw;
  left: calc(-50vw + 50%);
  background: #FFFFFF;
  box-shadow: 0 -5px 8px -5px rgba(39, 170, 225, 0.23);
`;