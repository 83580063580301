import styled from 'styled-components';
import Button from '../UI/Button';

export const AppCenterRowContainer = styled.div`
  float: left;
  display: flex;
  width: ${({ disableStyles }) => (!disableStyles ? '100%' : 'auto')};
  align-items: center;
  padding: ${({ theme }) => theme.spacing() * 4}px 0;
  border-bottom: ${({ disableStyles }) => (!disableStyles ? '1px solid #f1f1f1' : 'none')};

  h1 {
    flex: 1;
    color: ${({ theme }) => theme.palette.text.primary};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 16px;
    line-height: 32px;
  }
  > *:last-child {
    margin-right: 0;
  }

  .MuiSvgIcon-root.MuiSvgIcon-colorSecondary {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const NameWrapper = styled.div`
  flex: 1;
  display: flex;
  vertical-align: middle;
`;

export const StyledButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing()}px;

  &.review {
    background-color: ${({ theme }) => theme.appReviewPalette['IN_REVIEW']};
  }
`;

export const Badge = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 10px;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing() / 2}px ${({ theme }) => theme.spacing() * 2}px;
  background: #c2cdd2;
  border-radius: 16px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 ${({ theme }) => theme.spacing()}px;
  color: #ffffff;
  font-weight: bold;
`;
