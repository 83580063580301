import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const StyledTypography = styled(Typography)`
  padding: ${({ theme }) => theme.spacing() * 2}px 0;
  font-size: 1.2rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-weight: 600;
`;

export const TileHeading = styled.div`
  margin: ${({ theme }) => theme.spacing() * 3}px 0;
  display: flex;
  flex-direction: column;
`;

export const TilesContainer = styled.div`
  display: flex;
  > * {
    box-sizing: border-box;
    padding-right: ${({ theme }) => theme.spacing() * 2}px;
    &:last-child {
      padding-right: 0;
    }
  }
`;
