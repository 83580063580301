import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Agreements/SigningList';
import { Agreement } from 'westside-types';
import { getReturnUrl, openForResult } from '../../lib/window';
import { v4 as uuidv4 } from 'uuid';
import { SigningAgreementStatus } from '../../store/agreement';
import { Redirect } from 'react-router-dom';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  agreements: state.agreement.signingAgreements,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  loadSigningAgreements: dispatch.agreement.loadSigningAgreements,
  startSigning: dispatch.agreement.startSigning,
  verifySigning: dispatch.agreement.verifySigning,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type LandingState = {};

async function sign(sessionId: string, uri: string): Promise<boolean> {
  try {
    await openForResult(uri, '_blank', sessionId);
    return true;
  } catch (e) {
    return false;
  }
}

@connect(mapStateToProps, mapDispatchToProps)
class SigningContainer extends Component<ConnectedProps, LandingState> {
  componentDidMount() {
    const { loadSigningAgreements } = this.props;
    loadSigningAgreements();
  }

  async handleSignRequest(type: Agreement.AgreementType) {
    const sessionId = uuidv4().toString();
    const returnUrl = getReturnUrl(sessionId);
    const { startSigning, verifySigning } = this.props;
    const url = await startSigning({ type, returnUrl });
    await sign(sessionId, url);
    verifySigning({ type });
  }

  render() {
    const { account, logOut, agreements, personalProfile, companyProfile } = this.props;
    const notSignedCount = agreements.filter(
      ({ status }) => status !== SigningAgreementStatus.SIGNED,
    );
    if (notSignedCount <= 0) {
      return <Redirect to="/" />;
    }
    return (
      <Layout
        account={account}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        logOut={logOut}
        agreements={agreements}
        onSignRequested={(type) => this.handleSignRequest(type)}
      />
    );
  }
}

export default SigningContainer;
