import yup, { bool, number, object, string } from 'yup';
import { validationMessages } from '../constants/messages';

const { field } = validationMessages;

const validationSchema = object().shape({
  name: string().required(field.required),
  apiLevel: string().required(field.required),
  category: string().required(field.required),
  fingerprint: string().required(field.required),
  version: string().required(field.required),
  dataUsage: string().required(field.required),
  price: number().required(field.required),
  ownershipApproved: bool().oneOf([true], field.required).required(),
});

export type CreateAppSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
