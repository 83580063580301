import initialState, { FileInitialState, UploadedFile } from '../store/file';
import { Dispatch } from '../store';
import api from '../lib/api';
import formatErrorMessage from '../lib/format-error-messages';

export default {
  namespace: 'file',

  state: initialState,

  reducers: {
    setUploadProgress(
      state: FileInitialState,
      { session, uploadProgress }: { session: string; uploadProgress: number },
    ) {
      return { ...state, [session]: { ...state[session], uploadProgress } };
    },
    removeUploadSession(state: FileInitialState, session: string) {
      return { ...state, [session]: undefined };
    },
    setUploadedFile(
      state: FileInitialState,
      { session, uploadedFile }: { session: string; uploadedFile: UploadedFile },
    ) {
      return {
        ...state,
        [session]: { ...state[session], uploadedFile },
      };
    },
  },

  effects: (dispatch: Dispatch) => ({
    onUploadProgress({
      session,
      progressEvent,
    }: {
      session: string;
      progressEvent: ProgressEvent;
    }): void {
      const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch.file.setUploadProgress({ session, uploadProgress });
    },
    purgeUploadSession(session: string): void {
      dispatch.file.removeUploadSession(session);
    },
    async uploadFile({
      file,
      url,
      session,
    }: {
      file: File;
      url: string;
      session: string;
    }): Promise<void> {
      try {
        const uploadLinkGenerationData = {
          contentType: file.type,
          originalName: file.name,
        };
        const { data } = await api.post(url, uploadLinkGenerationData);
        const uploadUrl = data.url;
        const fileId = data.fileId;
        await api.put(uploadUrl, file, {
          onUploadProgress: (progressEvent: ProgressEvent) =>
            dispatch.file.onUploadProgress({ session, progressEvent }),
          withCredentials: false,
          headers: {
            'Content-Type': file.type,
          },
        });

        dispatch.file.setUploadedFile({
          session,
          uploadedFile: {
            name: file.name,
            id: fileId,
          },
        });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async downloadFile(fileId: string): Promise<string> {
      try {
        const { data } = await api.get(`/resource/file/${fileId}/download`);
        return data.url;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
  }),
};
