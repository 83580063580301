import React, { Component } from 'react';
import { Faq } from 'westside-types';
import Layout from '../../components/FaqEdit/Layout';
import { Dispatch, iRootState } from '../../store';
import { connect } from 'react-redux';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showNotification: dispatch.snackbar.open,
  logOut: dispatch.account.logOut,
  getFaqs: dispatch.faq.getFaqs,
  setFaqs: dispatch.faq.setFaqs,
});

type FaqListState = {
  faqs: Faq.FaqListDto | null;
};

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

@connect(mapStateToProps, mapDispatchToProps)
export default class FaqListContainer extends Component<ConnectedProps, FaqListState> {
  state = {
    faqs: null,
  };

  componentDidMount() {
    const { showNotification, getFaqs } = this.props;
    getFaqs()
      .then((faqs) => this.setState({ faqs }))
      .catch(() => showNotification("Error while fetching FAQ's"));
  }

  private saveFaqs(faqs: Faq.FaqListDto) {
    const { showNotification, setFaqs } = this.props;
    setFaqs(faqs)
      .then(() => this.props.history.push('/faq'))
      .catch(() => showNotification("Error while saving FAQ's"));
  }

  render() {
    const { faqs } = this.state;
    const { account, personalProfile, logOut } = this.props;
    return (
      <Layout
        faqs={faqs}
        personalProfile={personalProfile}
        onFaqsChange={(faqs) => this.saveFaqs(faqs)}
        account={account}
        logOut={logOut}
      />
    );
  }
}
