import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';

export const ContentBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  border-radius: ${({ theme }) => theme.spacing()}px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 132px;
  height: 132px;
`;

export const StyledTypography = styled(({ nowrap, ...props }) => <Typography {...props} />)`
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'inherit')};
`;
