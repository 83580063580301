import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Input from '../UI/Input';
import Button from '../UI/Button';
import PasswordInput from '../UI/PasswordInput';
import Logo from '../UI/Logo';
import { Auth } from 'westside-types';
import validationSchema from '../../validation/setPassword';
import { StyledBackgroundAccent, StyledFormWrapper, StyledGetHelpButton } from './Login.styles';
import { PageContainer } from '../UI/Layouts';

interface SetPasswordProps {
  loading: boolean;
  error: string | null;
  setPassword: (passwordData: Auth.FinishRegistrationDto) => void;
}

class SetPasswordComponent extends Component<SetPasswordProps> {
  render() {
    const { loading, setPassword } = this.props;
    const initialValues: Auth.FinishRegistrationDto = {
      password: '',
      token: '',
    };

    const onSubmit = async (values: Auth.FinishRegistrationDto): Promise<void> => {
      setPassword(values);
    };

    return (
      <>
        <StyledBackgroundAccent />
        <PageContainer center={true}>
          <StyledFormWrapper>
            <Box textAlign="center" mb={4}>
              <Logo />
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isValid }: any) => (
                <Box display="flex" flexDirection="column" width="100%" className="register-form">
                  <Form>
                    <Input
                      label="Temporary Password"
                      className="rounded"
                      type="text"
                      name="token"
                      required
                    />
                    <PasswordInput
                      label="Password"
                      className="rounded"
                      type="password"
                      name="password"
                      required
                    />
                    <Box mt={4} textAlign="center">
                      <Button
                        isPending={loading}
                        disabled={loading || !isValid}
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        className="submit"
                      >
                        Create
                      </Button>
                    </Box>
                  </Form>
                </Box>
              )}
            </Formik>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              mt={3}
            >
              <Typography variant="caption">Already a Member?</Typography>
              <Link to="/login">
                <StyledGetHelpButton size="small" color="primary">
                  Sign In
                </StyledGetHelpButton>
              </Link>
            </Box>
          </StyledFormWrapper>
        </PageContainer>
      </>
    );
  }
}

export default SetPasswordComponent;
