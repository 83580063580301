import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { StyledLink } from './Menu.styles';
import { SidebarMenuItem } from './common';

const SIDEBAR_MENU_ITEMS: SidebarMenuItem[] = [
  {
    label: 'Profile',
    link: ['/profile'],
  },
  {
    label: 'Notifications',
    link: ['/notifications'],
  },
];

const SidebarMenuElement: FunctionComponent<RouteComponentProps> = ({ location }) => {
  return (
    <Box>
      {SIDEBAR_MENU_ITEMS.map((item: SidebarMenuItem, index) => {
        const matchedRoute = item.link.filter((link) => {
          return location.pathname.includes(link);
        });

        return (
          <StyledLink key={`menu-item-${index}`} to={item.link[0]} active={matchedRoute.length > 0}>
            {item.label}
          </StyledLink>
        );
      })}
    </Box>
  );
};

export const ProfileSidebarMenu = withRouter(SidebarMenuElement);
