import React, { FunctionComponent } from 'react';
import { PageContainer, PageLayout } from '../UI/Layouts';
import { StyledTypography, TypographyContainer } from './Layout.styles';

type NotFound = {};

const NotFoundComponent: FunctionComponent<NotFound> = () => (
  <PageContainer>
    <PageLayout>
      <TypographyContainer>
        <StyledTypography variant="h3">Not Found</StyledTypography>

        <StyledTypography variant="subtitle1">This page does not exist.</StyledTypography>
      </TypographyContainer>
    </PageLayout>
  </PageContainer>
);

export default NotFoundComponent;
