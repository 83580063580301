import styled from 'styled-components';

export const StyledHeader = styled.div`
  height: 49px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.spacing() * 4}px;
  background: #f5f8f9;
  > div {
    margin: 0 auto;
  }

  .action-bar {
    height: 100%;
    button {
      margin-left: ${({ theme }) => theme.spacing() * 2}px;
      border-radius: 40px;
      padding: 0 ${({ theme }) => theme.spacing() * 2}px;
      .MuiTypography-caption {
        margin-left: ${({ theme }) => theme.spacing() * 1}px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
`;
