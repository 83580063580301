import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import Input from '../UI/Input';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../UI/Button';
import Logo from '../UI/Logo';
import { Auth } from 'westside-types';
import validationSchema from '../../validation/register';
import {
  StyledBackgroundAccent,
  StyledFormWrapper,
  StyledSuccessIconWrapper,
} from './Login.styles';
import { PageContainer } from '../UI/Layouts';

interface RegisterProps {
  loading: boolean;
  formSubmitted: boolean;
  error: string | null;
  recaptchaSiteKey: string | undefined;
  register: (user: Auth.RegisterDto) => void;
}

class RegisterComponent extends Component<RegisterProps> {
  render() {
    const { register, formSubmitted, loading, recaptchaSiteKey } = this.props;
    const initialValues: Auth.RegisterDto = {
      name: '',
      email: '',
      phone: '',
      company: '',
      reCaptchaResponse: '',
    };

    const onSubmit = async (values: Auth.RegisterDto): Promise<void> => {
      register(values);
    };

    return (
      <>
        <StyledBackgroundAccent />
        <PageContainer center={true}>
          <StyledFormWrapper>
            {!formSubmitted ? (
              <>
                <Box textAlign="center" mb={4}>
                  <Logo />
                </Box>
                <Box mb={5}>
                  <Typography align="center">
                    To sign up - give us your details, and we’ll get right back to you.
                  </Typography>
                </Box>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ handleSubmit, setFieldValue, isValid }: any) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      className="register-form"
                    >
                      <Form>
                        <Input label="Name" className="rounded" type="text" name="name" required />
                        <Input
                          label="Email"
                          className="rounded"
                          type="text"
                          name="email"
                          required
                        />
                        <Input
                          numberFormat="(###) ###-####"
                          mask="_"
                          onValueChange={(values) => setFieldValue('phone', values.value)}
                          onChange={() => {}}
                          label="Phone"
                          className="rounded"
                          type="text"
                          name="phone"
                          required
                        />
                        <Input
                          label="Company"
                          className="rounded"
                          type="text"
                          name="company"
                          required
                        />
                        <Box>
                          <Typography variant="caption">
                            <i>*Required Fields</i>
                          </Typography>
                        </Box>
                        <Box mt={2} display="flex" alignItems="center" flexDirection="column">
                          {recaptchaSiteKey && (
                            <ReCAPTCHA
                              sitekey={recaptchaSiteKey}
                              onChange={(token) => setFieldValue('reCaptchaResponse', token)}
                            />
                          )}
                        </Box>
                        <Box mt={4} textAlign="center">
                          <Button
                            isPending={loading}
                            disabled={loading || !isValid}
                            type="submit"
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            className="submit"
                          >
                            Submit
                          </Button>
                        </Box>
                      </Form>
                    </Box>
                  )}
                </Formik>
              </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" pt={4} pb={4}>
                <StyledSuccessIconWrapper
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CheckIcon />
                </StyledSuccessIconWrapper>
                <Box mt={4} mb={2}>
                  <Typography variant="h5" color="primary" align="center">
                    Submitted, Thank You!
                  </Typography>
                  <Box mt={1}>
                    <Typography align="center">
                      When your account has been approved, you will receive an email with login
                      information.
                    </Typography>
                  </Box>
                </Box>
                <Link to="/login">
                  <Button type="button" variant="outlined" color="primary">
                    Return
                  </Button>
                </Link>
              </Box>
            )}
          </StyledFormWrapper>
        </PageContainer>
      </>
    );
  }
}

export default RegisterComponent;
