import React, { FunctionComponent } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

type PendingListProps = {
  selectedUsers: string[];
  onSelectedUsersChange: (accountIds: string[]) => void;
  users: Auth.AccountStatus[];
};

const PendingList: FunctionComponent<PendingListProps> = ({
  selectedUsers,
  onSelectedUsersChange,
  users,
}) => {
  const allChecked =
    users.map(({ id }) => id).filter((id) => !selectedUsers.includes(id)).length <= 0;
  const selectAll = (checked: boolean) => {
    if (checked) {
      const allUsers = users.map(({ id }) => id);
      onSelectedUsersChange(allUsers);
    } else {
      onSelectedUsersChange([]);
    }
  };
  const setSelected = (id: string, checked: boolean) => {
    let filteredUsers = selectedUsers.filter((userId) => userId !== id);
    if (checked) {
      filteredUsers = [...filteredUsers, id];
    }
    onSelectedUsersChange(filteredUsers);
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox checked={allChecked} onChange={(_, checked) => selectAll(checked)} />
              Select All
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  checked={selectedUsers.includes(user.id)}
                  onChange={(_, checked) => setSelected(user.id, checked)}
                />
              </TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.company}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.phone}</TableCell>
            </TableRow>
          ))}
          {users.length <= 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography variant="body2">List is empty</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingList;
