import React, { FunctionComponent } from 'react';
import { Account } from '../../store/account';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import Box from '@material-ui/core/Box';
import { Sidebar } from '../UI/Sidebar/Sidebar';
import { ProfileSidebarMenu } from '../UI/Menu';
import ContentHeader from '../UI/Header/ContentHeader';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { ContentBox, StyledAvatar, StyledTypography } from './ViewProfile.styles';
import { StyledButton } from '../UI/Header/ContentHeader.styles';
import EditIcon from '@material-ui/icons/Edit';
import RoleBadge from '../UI/RoleBadge/RoleBadge';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import { Profile } from 'westside-types';
import config from '../../config';

type ViewProfileProps = {
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
  emailNotificationsActive: boolean;
  onEmailNotificationsActiveChange: (active: boolean) => void;
};

const ViewProfile: FunctionComponent<ViewProfileProps> = ({
  logOut,
  account,
  personalProfile,
  companyProfile,
  emailNotificationsActive,
  onEmailNotificationsActiveChange,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <Box flex="1" display="flex" flexDirection="row">
      <Sidebar title="My Account">
        <ProfileSidebarMenu />
      </Sidebar>
      <Box flex="1" display="flex" justifyContent="center">
        <PageLayout className="with-sidebar">
          <Box height="100%" mt={4} mb={4} borderRadius={1}>
            <ContentHeader
              title={'Profile'}
              icon={<AccountBoxIcon fontSize="large" color="primary" />}
            />
            {personalProfile && (
              <ContentBox pt={4} pb={4} pl={4} pr={4} mb={4} display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <StyledAvatar
                    src={`${config.apiRootUrl}/files/${personalProfile.avatarFileId}`}
                  />
                </Box>
                <Box flex={1} display="flex" flexDirection="row" pl={4}>
                  <Box flex={1} pr={4}>
                    <Box mb={4}>
                      <StyledTypography variant="body2" nowrap={true}>
                        Name
                      </StyledTypography>
                      <StyledTypography variant="body1">{personalProfile.name}</StyledTypography>
                    </Box>
                    <Box mb={4}>
                      <StyledTypography variant="body2" nowrap={true}>
                        Email
                      </StyledTypography>
                      <StyledTypography variant="body1">{personalProfile.email}</StyledTypography>
                    </Box>
                  </Box>
                  <Box flex={1} pr={4}>
                    <Box mb={4}>
                      <StyledTypography variant="body2" nowrap={true}>
                        Role
                      </StyledTypography>
                      <StyledTypography variant="body1">
                        <RoleBadge role={account.role} />
                      </StyledTypography>
                    </Box>
                    <Box display="flex" flexDirection="row">
                      <StyledTypography variant="body2" nowrap={false}>
                        Enable Email Notifications
                      </StyledTypography>
                      <Switch
                        checked={emailNotificationsActive}
                        onChange={(_, active) => onEmailNotificationsActiveChange(active)}
                        name="emailNotifications"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Link to="/profile/edit">
                    <StyledButton color="primary" variant="contained" disableElevation>
                      <span>Edit</span>
                      <EditIcon />
                    </StyledButton>
                  </Link>
                </Box>
              </ContentBox>
            )}
            {companyProfile && (
              <ContentBox pt={4} pb={4} pl={4} pr={4} mb={4} display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <StyledAvatar
                    src={
                      companyProfile.logoFileId
                        ? `${config.apiRootUrl}/files/${companyProfile.logoFileId}`
                        : null
                    }
                  />
                </Box>
                <Box flex={1} display="flex" flexDirection="column" pl={4}>
                  <Box mb={4}>
                    <StyledTypography variant="body2" nowrap={true}>
                      Company Name
                    </StyledTypography>
                    <StyledTypography variant="body1">{companyProfile.name}</StyledTypography>
                  </Box>
                  <Box mb={4}>
                    <StyledTypography variant="body2" nowrap={true}>
                      Company Description
                    </StyledTypography>
                    <StyledTypography variant="body1">
                      {companyProfile.description}
                    </StyledTypography>
                  </Box>
                  <Box>
                    <StyledTypography variant="body2" nowrap={true}>
                      Billing Information
                    </StyledTypography>
                    <StyledTypography variant="body2" nowrap={true}>
                      Address:
                    </StyledTypography>
                    <StyledTypography variant="body1">
                      <span>{companyProfile.streetAddress}</span>
                      <span> </span>
                      <span>{companyProfile.city}</span>
                      <span> </span>
                      <span>{companyProfile.state}</span>
                      <span> </span>
                      <span>{companyProfile.zip}</span>
                    </StyledTypography>
                  </Box>
                </Box>
                <Box>
                  {account.role !== 'ADMIN' && (
                    <Link to="/profile/edit-company">
                      <StyledButton color="primary" variant="contained" disableElevation>
                        <span>Edit</span>
                        <EditIcon />
                      </StyledButton>
                    </Link>
                  )}
                </Box>
              </ContentBox>
            )}
          </Box>
        </PageLayout>
      </Box>
    </Box>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default ViewProfile;
