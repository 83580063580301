import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { Profile } from 'westside-types';
import Header from '../UI/Header';
import { PageContainer, PageLayout } from '../UI/Layouts';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import Tiles from './Tiles';
import HorizontalTile from './HorizontalTile';
import Heading from './Heading';
import { Account } from '../../store/account';
import PersistentNotification from './PersistentNotification';
import { Typography } from '@material-ui/core';
import { StyledLink } from './Landing.styles';

interface Landing {
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
}

const LandingComponent: FunctionComponent<Landing> = ({
  logOut,
  account,
  personalProfile,
  companyProfile,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    {personalProfile && personalProfile.signed === false && (
      <PersistentNotification
        text="Developer Agreement & PS Connect NDA Incomplete"
        actionButton={
          <StyledLink to="/sign">
            <Typography variant="caption">Complete</Typography>
          </StyledLink>
        }
      />
    )}
    <PageLayout flexGrow="1">
      <Box height="100%">
        <Heading
          personalProfile={personalProfile}
          companyProfile={companyProfile}
          createAppEnabled={personalProfile?.signed === true}
        />
        <HorizontalTile />
        <Tiles
          agreementsVisible={account.role === 'ADMIN'}
          faqVisible={true}
          resourcesVisible={true}
          usersVisible={account.role === 'ADMIN'}
        />
      </Box>
    </PageLayout>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default LandingComponent;
