import React, { FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {
  LoginContainer,
  RegisterContainer,
  SetPasswordContainer,
  CreateAppContainer,
  ReviewAppContainer,
  AppCenterContainer,
  PreviewAppContainer,
  DashboardContainer,
  ResourcesContainer,
  ResourcesDetailsContainer,
  AddResourceContainer,
  FaqListContainer,
  FaqEditContainer,
  AgreementsListContainer,
  ViewProfileContainer,
  NotificationsContainer,
  EditProfileContainer,
  EditCompanyProfileContainer,
  UsersContainer,
  SigningContainer,
  ForgotPasswordContainer,
} from '../containers';
import NotFoundContainer from '../containers/NotFound/NotFound';

const Index: FunctionComponent = () => (
  <Switch>
    <PublicRoute path="/login" exact component={LoginContainer} />
    <PublicRoute path="/register" exact component={RegisterContainer} />
    <PublicRoute path="/signup/:id" exact component={SetPasswordContainer} />
    <PublicRoute path="/forgot-password" exact component={ForgotPasswordContainer} />
    <PrivateRoute path="/" exact component={DashboardContainer} openForUnsignedUsers />
    <PrivateRoute path="/create-app" exact component={CreateAppContainer} />
    <PrivateRoute path="/edit-app/:appId" exact component={CreateAppContainer} />
    <PrivateRoute path="/preview-app/:appId" exact component={PreviewAppContainer} />
    <PrivateRoute path="/app-center" exact component={AppCenterContainer} />
    <PrivateRoute path="/resources" exact component={ResourcesContainer} />
    <PrivateRoute path="/resources/add/:type" exact component={AddResourceContainer} />
    <PrivateRoute path="/resources/detail/:id" exact component={ResourcesDetailsContainer} />
    <PrivateRoute path="/resources/edit/:type/:id" exact component={AddResourceContainer} />
    <PrivateRoute path="/faq" exact component={FaqListContainer} />
    <PrivateRoute path="/faq/edit" exact component={FaqEditContainer} />
    <PrivateRoute path="/review-app/:appId" exact component={ReviewAppContainer} />
    <PrivateRoute path="/agreements" exact component={AgreementsListContainer} />
    <PrivateRoute path="/profile" exact component={ViewProfileContainer} />
    <PrivateRoute path="/profile/edit" exact component={EditProfileContainer} />
    <PrivateRoute path="/profile/edit-company" exact component={EditCompanyProfileContainer} />
    <PrivateRoute path="/notifications" exact component={NotificationsContainer} />
    <PrivateRoute path="/users" exact component={UsersContainer} />
    <PrivateRoute path="/sign" exact component={SigningContainer} openForUnsignedUsers />
    <PublicRoute component={NotFoundContainer} />
  </Switch>
);

export default Index;
