import React, { FunctionComponent } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

type DialogData = any;
interface DialogElement extends DialogProps {
  open: boolean;
  title?: string;
  dialogData?: DialogData;
  confirmButtonText?: string;
  isLightbox?: boolean;
  cancelButtonText?: string;
  disableActions?: boolean;
  onCancel: (dialogData?: any) => void;
  onConfirm?: (dialogData?: any) => void;
}

const DialogElement: FunctionComponent<DialogElement> = ({
  open,
  title,
  cancelButtonText,
  confirmButtonText,
  dialogData,
  children,
  disableActions,
  isLightbox,
  maxWidth,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth={maxWidth || "sm"} fullWidth open={open}>
      <DialogTitle>
        <IconButton onClick={() => onCancel(dialogData)}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={isLightbox ? 'lightbox' : ''}>
        {title && (
          <Typography variant="h5" align="center">
            {title}
          </Typography>
        )}
        {children}
      </DialogContent>
      {!disableActions && (
        <DialogActions>
          <Button autoFocus onClick={() => onCancel(dialogData)} variant="outlined" color="primary">
            {cancelButtonText || 'Cancel'}
          </Button>
          {onConfirm && (
            <Button variant="contained" onClick={() => onConfirm(dialogData)} color="primary">
              {confirmButtonText || 'Confirm'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogElement;
