import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { StyledTile, StyledDeleteIcon, StyledLink } from './Tile.styles';
import { UserSignedGuard } from '../../../helpers/user-signed-guard';

interface TileProps {
  title: string;
  link?: string;
  IconComponent: FunctionComponent;
  flexBasis?: string;
  flex?: string;
  onDelete?: (e: MouseEvent) => void;
}

const Tile: FunctionComponent<TileProps> = ({
  title,
  link,
  IconComponent,
  flexBasis,
  flex,
  children,
  onDelete,
}) => (
  <Box display="flex" flex={flex || 0} flexBasis={flexBasis}>
    <StyledTile display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {onDelete && <StyledDeleteIcon onClick={onDelete} />}
      {IconComponent && (
        <Box textAlign="center" mb={4}>
          <Box mb={2}>
            <IconComponent />
          </Box>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
      )}
      {link && (
        <UserSignedGuard>
          <StyledLink to={link}>
            <Box display="inline-flex" flexDirection="row">
              <Typography variant="body1">View</Typography>
              <Box ml={1}>
                <ArrowRightAltIcon color="secondary" />
              </Box>
            </Box>
          </StyledLink>
        </UserSignedGuard>
      )}
      {children}
    </StyledTile>
  </Box>
);

export default Tile;
