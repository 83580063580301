import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import {
  StyledExpansionPanel,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledTooltip,
} from './ExpansionPanel.styles';

interface ExpansionPanel {
  title: string;
  info?: string | undefined;
  expanded?: boolean;
  StatusIcon?: FunctionComponent;
}

const ExpansionPanel: FunctionComponent<ExpansionPanel> = ({
  expanded,
  title,
  info,
  StatusIcon,
  children,
}) => {
  return (
    <StyledExpansionPanel defaultExpanded={expanded}>
      <StyledExpansionPanelSummary
        expandIcon={<ExpandMoreIcon fontSize="large" color="primary" />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        {StatusIcon && (
          <Box display="inline" mr={1}>
            <StatusIcon />
          </Box>
        )}
        {title}
        {info && (
          <StyledTooltip title={info}>
            <InfoIcon />
          </StyledTooltip>
        )}
      </StyledExpansionPanelSummary>
      <StyledExpansionPanelDetails>{children}</StyledExpansionPanelDetails>
    </StyledExpansionPanel>
  );
};

export default ExpansionPanel;
