export type FileId = string;
export type FileName = string;

export interface UploadedFile {
  id: FileId;
  name: FileName;
}

export interface FileStore {
  uploadProgress: number;
  uploadedFile: UploadedFile;
}

export interface FileInitialState {
  [key: string]: FileStore;
}

const initialState: FileInitialState | object = {};

export default initialState;
