import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

export const AppCenterHeaderLink = styled(Link)`
  text-decoration: none;
  display: block;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const LinkDescription = styled.span`
  padding: ${({ theme }) => theme.spacing()}px 0;
  margin: 0 auto;
  display: block;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  text-align: center;
`;

export const LinkIconContainer = styled(IconButton)`
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(39, 170, 225, 0.23);
  border: 1px solid rgba(39, 170, 225, 0.26);
  width: 54px;
  height: 54px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.primary.light};
  &:hover {
    background-color: rgba(43, 100, 203, 0.1);
  }
  
`;
