import React, { FunctionComponent } from 'react';
import { Faq, Profile } from 'westside-types';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import AppContextHeader from '../UI/Header/AppContextHeader';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Account } from '../../store/account';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { MainSidebarMenu } from '../UI/Menu';
import FaqEntryComponent from './FaqEntry';
import FaqSearchComponent from './FaqSearch';
import ContentHeader from '../UI/Header/ContentHeader';
import EditIcon from '@material-ui/icons/Edit';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

type FaqListProps = {
  faqs: Faq.FaqDto[] | null;
  allFaqs: Faq.FaqDto[] | null;
  searchQueryChange: (query: string) => void;
  searchQuery: string;
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
};

const FaqListComponent: FunctionComponent<FaqListProps> = ({
  faqs,
  allFaqs,
  logOut,
  account,
  personalProfile,
  companyProfile,
  searchQuery,
  searchQueryChange,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <Box flex="1" display="flex" flexDirection="row">
      <Sidebar
        logoType={account.role === 'ADMIN' ? LogoType.LOGO : LogoType.AVATAR}
        logo={account.role === 'ADMIN' ? companyProfile?.logoFileId : personalProfile?.avatarFileId}
      >
        <MainSidebarMenu userRole={account.role} />
      </Sidebar>
      {faqs && (
        <Box flex="1" display="flex" justifyContent="center" flexDirection="column">
          <AppContextHeader account={account} avatar={personalProfile?.avatarFileId} />
          <PageLayout className="with-sidebar">
            <Box height="100%" mt={4} mb={4}>
              <ContentHeader
                editLink={'/faq/edit'}
                editCapabilities={account.role === 'ADMIN'}
                editText={'Edit'}
                title={'Frequently Asked Questions'}
                editIcon={<EditIcon fontSize="small" />}
                icon={<ContactSupportIcon fontSize="large" color="primary" />}
              />
              <FaqSearchComponent searchQuery={searchQuery} searchQueryChange={searchQueryChange} />
              {faqs.map((faq, i) => (
                <FaqEntryComponent faq={faq} key={i} />
              ))}
              {faqs.length <= 0 && (
                <Typography variant="body1">
                  {allFaqs.length <= 0 ? 'No FAQs added' : 'No results for given criteria.'}
                </Typography>
              )}
            </Box>
          </PageLayout>
        </Box>
      )}
    </Box>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default FaqListComponent;
