import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Resources } from 'westside-types';
import { resourcesFieldMapping, resourcesTitleMapping } from '../../constants/mappings';
import { iRootState, Dispatch } from '../../store';
import promiseCallbackHandler from '../../lib/promise-callback';
import resourceValidationSchema from '../../validation/resource-app-form';
import documentationValidationSchema from '../../validation/resource-document-form';
import Layout from '../../components/Resources/AddResource';

const mapStateToProps = ({ account, profile }: iRootState) => ({
  account,
  personalProfile: profile.personalProfile,
});
const mapDispatchToProps = ({ account, resources, snackbar }: Dispatch) => ({
  logOut: account.logOut,
  addResource: resources.addResource,
  addDocument: resources.addDocument,
  getResourceDetails: resources.getResourceDetails,
  updateResource: resources.updateResource,
  showNotification: snackbar.open,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface ResourcesState {
  loading: boolean;
  error: string | null;
  resourceDetails: Resources.Resource | undefined;
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class LoginContainer extends Component<ConnectedProps, ResourcesState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
    resourceDetails: undefined,
  };

  redirectAfterSuccess = '/resources';

  componentDidMount() {
    //@ts-ignore
    const { id } = this.props.match.params;

    if (id) {
      this.getReourceDetails(id);
    }
  }

  getReourceDetails = async (id: string): Promise<void> => {
    const { getResourceDetails } = this.props;

    this.setState({ loading: true });
    const resourceDetails = await this.promiseCallback(getResourceDetails(id));
    this.setState({ resourceDetails });
  };

  addResource = async (resource: Resources.Resource): Promise<void> => {
    //@ts-ignore
    const { addResource, match } = this.props;
    const { type } = match.params;

    this.setState({ loading: true });
    return await this.promiseCallback(
      addResource({ resource, type }),
      'Resource saved.',
      this.redirectAfterSuccess,
    );
  };

  addDocument = async (document: Resources.Document): Promise<void> => {
    const { addDocument } = this.props;

    this.setState({ loading: true });
    return await this.promiseCallback(
      addDocument({ document }),
      'Document saved.',
      this.redirectAfterSuccess,
    );
  };

  updateResource = async (
    id: string,
    resource: Resources.Resource,
    type: Resources.ResourceType,
  ): Promise<void> => {
    const { updateResource } = this.props;

    this.setState({ loading: true });
    return await this.promiseCallback(
      updateResource({ id, resource, type }),
      'Resource updated.',
      this.redirectAfterSuccess,
    );
  };

  processResource = async (resource: any): Promise<void> => {
    //@ts-ignore
    const { type, id } = this.props.match.params;
    const { DOCUMENTATION } = Resources.ResourceType;

    if (id) {
      return this.updateResource(id, resource, type);
    }

    if (type === DOCUMENTATION) {
      return this.addDocument(resource);
    }

    return this.addResource(resource);
  };

  render() {
    const { loading, resourceDetails } = this.state;
    const { DOCUMENTATION } = Resources.ResourceType;
    // @ts-ignore
    const { logOut, account, personalProfile, match } = this.props;
    const { type, id } = match.params;

    return (
      <Layout
        loading={loading}
        id={id}
        type={type}
        logOut={logOut}
        account={account}
        personalProfile={personalProfile}
        title={resourcesTitleMapping[type]}
        fields={resourcesFieldMapping[type]}
        addResource={this.processResource}
        validationSchema={
          type === DOCUMENTATION ? documentationValidationSchema : resourceValidationSchema
        }
        preselectedData={resourceDetails}
      />
    );
  }
}
// @ts-ignore
export default LoginContainer;
