import React, { FunctionComponent, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { App, Profile } from 'westside-types';
import { UploadedFile } from '../../store/file';
import { Account } from '../../store/account';
import urlToBlob from '../../helpers/url-to-blob';
import { AppDetails, ScreenshotDetails, AppCombinedDetails } from '../../store/app';
import ConnectedFileUploader from '../UI/ConnectedFileUploader';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import AppContextHeader from '../UI/Header/AppContextHeader';
import Stepper from '../UI/Stepper';
import DropZone from '../UI/DropZone';
import InfoForm from './InfoForm';
import DetailsForm from './DetailsForm';
import { Footer, FooterLink } from '../UI/Footer/Footer';

interface CreateApp {
  logOut: () => void;
  uploadScreenshots: (screenshots: File[]) => Promise<void>;
  deleteScreenshot: (deletedScreenshotIndex: number) => Promise<void>;
  uploadApp: (appDetails) => Promise<void>;
  getApkMetadata: (apkFileId) => Promise<App.ApkMetadata>;
  validateBeforeSubmitting: (
    appInfo: App.AppInfo | undefined,
    appDetails: App.AppDetails | undefined,
  ) => boolean;
  uploadedApkDetails: AppDetails;
  uploadedScreenshotDetails: ScreenshotDetails;
  preselectedApp: AppCombinedDetails | undefined;
  account: Account;
  personalProfile: Profile.ProfileDto;
  loading: boolean;
  loadingApkMetadata: boolean;
  error: string | null;
  appId?: string;
}

const CreateAppComponent: FunctionComponent<CreateApp> = ({
  account,
  personalProfile,
  loading,
  loadingApkMetadata,
  logOut,
  uploadScreenshots,
  deleteScreenshot,
  uploadApp,
  getApkMetadata,
  validateBeforeSubmitting,
  preselectedApp,
  uploadedApkDetails,
  uploadedScreenshotDetails,
  appId,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [appInfo, setAppInfo] = useState<App.AppInfo | undefined>(undefined);
  const [appDetails, setAppDetails] = useState<App.AppDetails | undefined>(undefined);
  const [selectedApk, setSelectedApk] = useState<UploadedFile | undefined>(undefined);
  const [selectedAppIcon, setSelectedAppIcon] = useState<UploadedFile | undefined>(undefined);
  const [selectedAppScreenshots, setSelectedAppScreenshots] = useState<File[]>([]);
  const [appInfoError, setAppInfoError] = useState<{} | undefined>(undefined);
  const [appDetailsError, setAppDetailsError] = useState<{} | undefined>(undefined);
  const [disableStepperAction, setDisableStepperAction] = useState(true);

  useEffect(() => {
    let state = false;

    switch (activeStep) {
      case 0:
        if (!selectedApk) {
          state = true;
        }
        break;
      case 2:
        if (appInfoError || appDetailsError || !selectedAppScreenshots.length) {
          state = true;
        }
        break;
      default:
        break;
    }

    setDisableStepperAction(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, appInfoError, appDetailsError, selectedApk, selectedAppScreenshots]);

  useEffect(() => {
    if (preselectedApp) {
      setSelectedApk({
        name: preselectedApp.appInfo.name,
        id: preselectedApp.apkFileId,
      });
      setAppInfo(preselectedApp.appInfo);
      setAppDetails(preselectedApp.appDetails);
      setSelectedAppIcon({
        name: preselectedApp.appInfo.name,
        id: preselectedApp.iconFileId,
      });

      convertScreenshotUrlToBlob(preselectedApp.screenshotFileIds);
    }
  }, [preselectedApp]);

  useEffect(() => {
    if (!preselectedApp) {
      getApkMetadataAndMergeWithForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApk]);

  const convertScreenshotUrlToBlob = async (screenshotFileIds) => {
    const screenshots: File[] = await Promise.all(
      screenshotFileIds.map(
        async (screenshot): Promise<Blob> => {
          return new File(await urlToBlob('/files/' + screenshot), screenshot);
        },
      ),
    );

    setSelectedAppScreenshots(screenshots);
  };

  const getApkMetadataAndMergeWithForm = async (): Promise<void> => {
    if (selectedApk && appInfo) {
      const { apiVersion, fingerprint, name, version, iconFileId } = await getApkMetadata(
        selectedApk.id,
      );

      setAppInfo({
        ...appInfo,
        apiLevel: apiVersion.toString(),
        fingerprint,
        name,
        version,
      });

      setSelectedAppIcon({
        name,
        id: iconFileId,
      });
    }
  };

  const handleApk = (file: UploadedFile) => {
    if (file) {
      setSelectedApk(file);
    }
  };

  const handleScreenshot = (screenshots: File[]): void => {
    if (screenshots.length) {
      setSelectedAppScreenshots([...selectedAppScreenshots, ...screenshots]);
      uploadScreenshots(screenshots);
    }
  };

  const handleDeleteScreenshot = (deletedScreenshotIndex: number, screenshots: File[]): void => {
    if (screenshots) {
      setSelectedAppScreenshots(screenshots);
      deleteScreenshot(deletedScreenshotIndex);
    }
  };

  const submitApp = (draft?: boolean): void => {
    validateBeforeSubmitting(appInfo, appDetails) &&
      uploadApp({
        ...appInfo,
        ...appDetails,
        apkFileId: selectedApk?.id,
        screenshotFileIds: uploadedScreenshotDetails.map((screenshot) => screenshot.id),
        draft: Boolean(draft),
      });
  };

  const saveAsDraftAction = appInfo
    ? [
        {
          title: 'Save As Draft',
          callback: () => submitApp(true),
          disabled: !selectedApk,
        },
      ]
    : undefined;

  const steps = [
    {
      'Add Info': (
        <>
          <Box mt={4}>
            <ConnectedFileUploader
              url={'/app/apk'}
              onChange={handleApk}
              preselectedFile={selectedApk}
              additionalFileIcon={selectedAppIcon}
              title={uploadedApkDetails?.name}
              type=".apk"
              forceLoader={loadingApkMetadata}
            />
          </Box>
          <Box mt={5}>
            <InfoForm
              initialData={appInfo}
              onDataChange={setAppInfo}
              onError={setAppInfoError}
              loading={loading}
              disabled={!selectedApk}
            />
          </Box>
        </>
      ),
    },
    {
      'App Details': (
        <>
          <Box mt={4}>
            <DetailsForm
              initialData={appDetails}
              onDataChange={setAppDetails}
              onError={setAppDetailsError}
              loading={loading}
            />
          </Box>
        </>
      ),
    },
    {
      Finalize: (
        <>
          <Box mt={4}>
            <ConnectedFileUploader
              title="App Icon"
              type="image/*"
              url="/app/image"
              preselectedFile={selectedAppIcon}
              useSelectedFileAsAnIcon
            />
          </Box>
          <Box mt={4}>
            <DropZone
              onDrop={handleScreenshot}
              onDelete={handleDeleteScreenshot}
              loading={loading}
              initialScreenshots={selectedAppScreenshots}
            />
          </Box>
        </>
      ),
    },
  ];

  const cancelAction = appId ? `/preview-app/${appId}` : '/app-center';

  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <AppContextHeader
        account={account}
        avatar={personalProfile?.avatarFileId}
        closeButtonText={preselectedApp ? 'Edit App' : 'Add App'}
        closeButtonLink={cancelAction}
      />
      <PageLayout noBottomPad>
        <Box height="100%">
          <Stepper
            steps={steps}
            disabled={disableStepperAction}
            onFinish={submitApp}
            onChange={setActiveStep}
            loading={loading}
            actions={saveAsDraftAction}
            finishText={'Add App'}
            cancelAction={{
              link: cancelAction,
              label: 'Cancel',
            }}
          ></Stepper>
        </Box>
      </PageLayout>
      <Footer>
        <FooterLink to={null}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default CreateAppComponent;
