import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { sizes } from '../../../theme';

export const StyledPage = styled(({ form, sticky, ...props }) => <Box {...props} />)`
  width: 100%;
  flex: 1;

  > div {
    max-width: ${({ form }) => (form ? sizes.formPageMaxWidth : sizes.pageMaxWidth)}px;
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.spacing() * 8}px ${({ theme }) => theme.spacing() * 8}px;
    ${({ sticky }) =>
      sticky
        ? `
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom:0
        `
        : ''}
    ${({ noBottomPad }) =>
      noBottomPad
        ? `
        padding-bottom: 0;
        `
        : ''}
  }

  &.with-sidebar {
    display: flex;

    > div {
      flex: 1;
      max-width: ${sizes.headerMaxWidth}px;
      margin: 0 auto;
    }
  }
`;

export const StyledImageIcon = styled.img`
  width: 31px;
  height: 31px;
`;
