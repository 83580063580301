import React, { FunctionComponent, useState } from 'react';
import { Box } from '@material-ui/core';
import { Auth } from 'westside-types';
import { StyledButton } from './ApprovalButtons.styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';

type ApprovalDialogProps = {
  disabled: boolean;
  onApprovalChange: (status: Auth.AccountStatus) => void;
  multiple: boolean;
};

const ApprovalButtons: FunctionComponent<ApprovalDialogProps> = ({
  disabled,
  onApprovalChange,
  multiple,
}) => {
  const [
    approvalStatusDialogType,
    setApprovalStatusDialogType,
  ] = useState<Auth.AccountStatus | null>(null);
  return (
    <>
      <Box mr={1}>
        <StyledButton
          variant="contained"
          color="primary"
          disabled={disabled}
          disableElevation
          onClick={() => setApprovalStatusDialogType(Auth.AccountStatus.APPROVED)}
        >
          APPROVE
        </StyledButton>
      </Box>
      <Box ml={1}>
        <StyledButton
          variant="contained"
          color="secondary"
          disabled={disabled}
          disableElevation
          onClick={() => setApprovalStatusDialogType(Auth.AccountStatus.DECLINED)}
        >
          DECLINE
        </StyledButton>
      </Box>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={'sm'}
        fullWidth
        open={approvalStatusDialogType === Auth.AccountStatus.APPROVED}
      >
        <DialogTitle>
          <IconButton onClick={() => setApprovalStatusDialogType(null)}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {multiple && (
            <Typography variant="h5" align="center">
              Are you sure you want to approve these users?
            </Typography>
          )}
          {!multiple && (
            <Typography variant="h5" align="center">
              Are you sure you want to approve this user?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            autoFocus
            onClick={() => setApprovalStatusDialogType(null)}
            variant="outlined"
            color="primary"
            disableElevation
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              onApprovalChange(approvalStatusDialogType);
              setApprovalStatusDialogType(null);
            }}
            disableElevation
          >
            Approve
          </StyledButton>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth={'sm'}
        fullWidth
        open={approvalStatusDialogType === Auth.AccountStatus.DECLINED}
      >
        <DialogTitle>
          <IconButton onClick={() => setApprovalStatusDialogType(null)}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {multiple && (
            <Typography variant="h5" align="center">
              Are you sure you want to decline these users?
            </Typography>
          )}
          {!multiple && (
            <Typography variant="h5" align="center">
              Are you sure you want to decline this user?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            autoFocus
            onClick={() => setApprovalStatusDialogType(null)}
            variant="outlined"
            color="primary"
            disableElevation
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => {
              onApprovalChange(approvalStatusDialogType);
              setApprovalStatusDialogType(null);
            }}
            disableElevation
          >
            Decline
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApprovalButtons;
