import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { StyledImageIcon } from './Page.styles';

export interface PageHeaderProps {
  title: string;
  Icon?: FunctionComponent;
  loading?: boolean;
}

const PageHeader: FunctionComponent<PageHeaderProps> = ({ title, loading, Icon, children }) => (
  <Box display="flex" mt={4} mb={4}>
    <Box display="flex" flexDirection="row" alignItems="center" flex="1">
      <Box mr={2} display="inline-flex">
        {Icon ? <Icon /> : <StyledImageIcon src={require('../../../assets/apps.svg')} />}
      </Box>
      <Typography variant="h1">{title}</Typography>
      {loading && (
        <Box ml={2}>
          <CircularProgress size={26} color="secondary" />
        </Box>
      )}
    </Box>
    {children}
  </Box>
);

export default PageHeader;
