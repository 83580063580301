import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { Profile } from 'westside-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { StyledButton } from '../FaqEdit/FaqEditor.styles';
import { InputWrapper } from './ProfileEditor.styles';
import Input from '../UI/Input';
import PasswordInput from '../UI/PasswordInput';
import ConnectedFileUploader from '../UI/ConnectedFileUploader';

type ProfileEditorProps = {
  initialData: Profile.UpdateProfileDto;
  onDataChange: (data: Profile.UpdateProfileDto) => void;
  cancelLink: string;
};

const ProfileEditor: FunctionComponent<ProfileEditorProps> = ({
  onDataChange,
  initialData,
  cancelLink,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialData}
    validationSchema={Profile.UpdateProfileDtoValidation}
    onSubmit={onDataChange}
    validateOnMount={true}
  >
    {({ setFieldValue, handleSubmit, isValid, values }) => (
      <form onSubmit={handleSubmit}>
        <Box>
          <InputWrapper>
            <Input className="rounded" label="Name" type="text" name="name" required />
            <Input className="rounded" label="Email" type="text" name="email" required />
            <Box mb={2} mt={2}>
              <Typography variant="body1">Change password</Typography>
            </Box>
            <Input
              className="rounded"
              label="Current Password"
              type="password"
              name="currentPassword"
            />
            <PasswordInput className="rounded" label="New Password" type="text" name="password" />
            <Input
              className="rounded"
              label="Re-Type New Password"
              type="password"
              name="rePassword"
            />
            <Box mb={2} mt={2}>
              <Typography variant="body1">Change avatar</Typography>
            </Box>
            <ConnectedFileUploader
              url={'/profile/avatar'}
              name="avatarFileId"
              typeDescription="Your Avatar"
              onChange={(file) => setFieldValue('avatarFileId', file.id)}
              preselectedFile={
                values.avatarFileId
                  ? {
                      name: values.avatarFileId,
                      id: values.avatarFileId,
                    }
                  : undefined
              }
              type=".png"
              useSelectedFileAsAnIcon
            />
          </InputWrapper>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Link to={cancelLink}>
            <StyledButton type="button" variant="outlined" color="primary">
              Cancel
            </StyledButton>
          </Link>
          <StyledButton type="submit" variant="contained" color="primary" disabled={!isValid}>
            Save
          </StyledButton>
        </Box>
      </form>
    )}
  </Formik>
);

export default ProfileEditor;
