const { host } = window.location;
const { NODE_ENV, REACT_APP_API_BASE_URL } = process.env;

const config = {
  env: NODE_ENV,
  host: host,
  appName: 'PS-Connect',
  dateFormat: 'MM DD YYYY',
  apiRootUrl: REACT_APP_API_BASE_URL,
  apiBaseUrl: '/api',
};

export default config;
