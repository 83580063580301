import initialState, { SnackBarInitialState } from '../store/snackbar';
import { Dispatch } from '../store';

export default {
  namespace: 'snackbar',

  state: initialState,

  reducers: {
    toggle(state: SnackBarInitialState, { open, message }) {
      return {
        ...state,
        open,
        message,
      };
    },
  },

  effects: (dispatch: Dispatch) => ({
    open(message: string): void {
      dispatch.snackbar.toggle({ open: true, message });
    },
    close(): void {
      dispatch.snackbar.toggle({ open: false, message: '' });
    },
  }),
};
