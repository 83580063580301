import React, { FunctionComponent } from 'react';
import { Profile } from 'westside-types';
import Header from '../UI/Header';
import AppContextHeader from '../UI/Header/AppContextHeader';
import { Box } from '@material-ui/core';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { MainSidebarMenu } from '../UI/Menu';
import { PageContainer, PageLayout } from '../UI/Layouts';
import ContentHeader from '../UI/Header/ContentHeader';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { Account } from '../../store/account';
import { SigningAgreement, SigningAgreementStatus } from '../../store/agreement';
import { Agreement } from 'westside-types';
import { agreementTypeTitleMapping } from '../../constants/mappings';
import SigningButton from './SigningButton';
import { StyledTypography } from './SigningList.styles';
import { UserSignedGuard } from '../../helpers/user-signed-guard';

declare type SigningListProps = {
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
  agreements: SigningAgreement[];
  onSignRequested: (type: Agreement.AgreementType) => void;
};

const SigningList: FunctionComponent<SigningListProps> = ({
  account,
  personalProfile,
  companyProfile,
  logOut,
  agreements,
  onSignRequested,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <Box flex="1" display="flex" flexDirection="row">
      <UserSignedGuard>
        <Sidebar
          logoType={account.role === 'ADMIN' ? LogoType.LOGO : LogoType.AVATAR}
          logo={
            account.role === 'ADMIN' ? companyProfile?.logoFileId : personalProfile?.avatarFileId
          }
        >
          <MainSidebarMenu userRole={account.role} />
        </Sidebar>
      </UserSignedGuard>
      <Box flex="1" display="flex" justifyContent="center" flexDirection="column">
        <UserSignedGuard>
          <AppContextHeader account={account} avatar={personalProfile?.avatarFileId} />
        </UserSignedGuard>
        <PageLayout className="with-sidebar">
          <Box height="100%" mt={4} mb={4}>
            <ContentHeader
              title="Agreements"
              icon={<BookmarksIcon fontSize="large" color="primary" />}
            />
            {agreements &&
              agreements.map((agreement) => (
                <Box mb={4} display="flex" flexDirection="row" alignItems="center">
                  <Box mr={2}>
                    <SigningButton
                      onSigningRequested={() => onSignRequested(agreement.type)}
                      status={agreement.status}
                    />
                  </Box>
                  <Box flex={1}>
                    <StyledTypography variant="body1">
                      {agreementTypeTitleMapping[agreement.type]}
                    </StyledTypography>
                    {agreement.status === SigningAgreementStatus.NOT_SIGNED && (
                      <StyledTypography variant="caption">
                        Click button on the left to start signing process.
                      </StyledTypography>
                    )}
                    {agreement.status === SigningAgreementStatus.SIGNED && (
                      <StyledTypography variant="caption">Agreement signed.</StyledTypography>
                    )}
                    {agreement.status === SigningAgreementStatus.IN_PROGRESS && (
                      <StyledTypography variant="caption">
                        Signing in progress. Go back to docusign tab and finish process.
                      </StyledTypography>
                    )}
                    {agreement.status === SigningAgreementStatus.PROCESSING && (
                      <StyledTypography variant="caption">Processing...</StyledTypography>
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        </PageLayout>
      </Box>
    </Box>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default SigningList;
