import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { StyledHeader } from './Header.styles';
import { Account } from '../../../store/account';
import RoleBadge from '../RoleBadge/RoleBadge';
import { UserSignedGuard } from '../../../helpers/user-signed-guard';

interface Header {
  logOut: () => void;
  account: Account;
}

const HeaderElement: FunctionComponent<Header> = ({ logOut, account }) => {
  return (
    <StyledHeader>
      <Box display="flex" flexDirection="row" alignItems="center" height="100%">
        <Link to="/">
          <img src={require('../../../assets/logo-h.svg')} alt="logo" />
        </Link>
        <Box flexGrow="1"></Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          className="action-bar"
        >
          <RoleBadge role={account.role} />
          <UserSignedGuard>
            <Link to={'/profile'}>
              <IconButton>
                <AccountBoxIcon color="primary" />
                <Typography variant="caption" color="primary">
                  My Account
                </Typography>
              </IconButton>
            </Link>
          </UserSignedGuard>
          <IconButton onClick={logOut}>
            <ExitToAppIcon color="primary" />
            <Typography variant="caption" color="primary">
              Log Out
            </Typography>
          </IconButton>
        </Box>
      </Box>
    </StyledHeader>
  );
};

export default HeaderElement;
