import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const PageContainer = styled(({ sticky, noBottomPad, ...props }) => <Box {...props} />)`
  min-height: 100vh;
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'stretch')};
  align-items: ${({ center }) => (center ? 'center' : 'stretch')};
  flex-direction: column;

  ${({ sticky }) =>
    sticky
      ? `
  height: 100vh; 
  overflow: hidden;
  `
      : ''}
`;
