import styled from 'styled-components';
import AddButton from '../UI/AddButton';

export const HeadingContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing() * 4}px 0;

  &::after {
    content: '';
    clear: both;
    display: block;
  }
`;

export const StyledAddButton = styled(AddButton)`
  float: right;
`;

export const AccountWrapper = styled.div`
  float: left;
`;
