import React, { Component } from 'react';
import { connect } from 'react-redux';
import { iRootState, Dispatch } from '../../store';
import { Auth } from 'westside-types';
import promiseCallbackHandler from '../../lib/promise-callback';
import Layout from '../../components/Auth/SetPassword';

const mapStateToProps = (state: iRootState) => ({});
const mapDispatchToProps = ({ account, snackbar }: Dispatch) => ({
  setPassword: account.setPassword,
  showNotification: snackbar.open,
  logOut: account.logOut,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface RegisterState {
  loading: boolean;
  error: string | null;
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class SetPasswordContainer extends Component<ConnectedProps, RegisterState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    formSubmitted: false,
    error: null,
  };

  componentDidMount() {
    const { logOut } = this.props;
    logOut();
  }

  setPassword = async (credentials: Auth.FinishRegistrationDto) => {
    // @ts-ignore
    const { setPassword, match } = this.props;
    const { id } = match.params;
    this.setState({ loading: true });
    await this.promiseCallback(
      setPassword({ id, credentials }),
      'Your new password has been set',
      '/login',
    );
  };

  render() {
    const { loading, error } = this.state;

    return <Layout loading={loading} error={error} setPassword={this.setPassword} />;
  }
}
// @ts-ignore
export default SetPasswordContainer;
