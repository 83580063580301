import initialState, { NotificationsState } from '../store/notifications';
import { Dispatch } from '../store';
import formatErrorMessage from '../lib/format-error-messages';
import { Notifications } from 'westside-types';
import api from '../lib/api';

export default {
  namespace: 'notifications',

  state: initialState,

  reducers: {
    appendNotifications(
      state: NotificationsState,
      notifications: Notifications.NotificationPreview[],
    ) {
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            (notification) => !notifications.map(({ id }) => id).includes(notification.id),
          ),
          ...notifications,
        ],
        hasMore: notifications.length > 0,
      };
    },

    setEmailActive(state: NotificationsState, emailActive: boolean) {
      return {
        ...state,
        emailActive,
      };
    },

    clean(state: NotificationsState): NotificationsState {
      return {
        ...state,
        notifications: [],
      };
    },
  },

  effects: (dispatch: Dispatch) => ({
    async loadMore(offset: number): void {
      try {
        const { data } = await api.get('/notification', {
          params: {
            offset,
          },
        });
        dispatch.notifications.appendNotifications(data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },

    async loadEmailNotificationsActive() {
      try {
        const { data } = await api.get('/notification/email-active-state');
        const { active } = data;
        dispatch.notifications.setEmailActive(active);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },

    async setEmailNotificationsActive({ active }: { active: boolean }) {
      try {
        await api.post('/notification/email-active-state', { active });
        dispatch.notifications.setEmailActive(active);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
  }),
};
