import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import config from '../../../config';
import { SidebarContainer, SidebarHeader, StyledAvatar } from './Sidebar.styles';

export enum LogoType {
  AVATAR = 'AVATAR',
  LOGO = 'LOGO',
}

export declare type SidebarProps = {
  title?: string;
  logoType?: LogoType;
  logo?: string;
};

export const Sidebar: FunctionComponent<SidebarProps> = ({ children, title, logoType, logo }) => (
  <SidebarContainer>
    <SidebarHeader>
      {logoType === LogoType.LOGO && (
        <img
          src={
            logo ? `${config.apiRootUrl}/files/${logo}` : require('../../../assets/logo-icon.svg')
          }
          alt="Platform Science"
        />
      )}
      {logoType === LogoType.AVATAR && (
        <StyledAvatar
          src={logo ? `${config.apiRootUrl}/files/${logo}` : null}
          alt="Platform Science"
        />
      )}
      <Typography variant="subtitle1">{title || 'Platform Science'}</Typography>
    </SidebarHeader>
    <Box mt={4} pr={2} pl={4}>
      {children}
    </Box>
  </SidebarContainer>
);
