import { Resources, Agreement } from 'westside-types';
import { ResourceField } from '../types/resources';

// Resources
const { PS_APP, SAMPLE_APP, DOCUMENTATION, SIMULATOR, TUTORIAL } = Resources.ResourceType;

export const resourcesTitleMapping: { [key: string]: string } = {
  [PS_APP]: 'Add Platform Science App',
  [SAMPLE_APP]: 'Add Samples App',
  [DOCUMENTATION]: 'Add Documentation',
  [SIMULATOR]: 'Add Simulators',
  [TUTORIAL]: 'Add tutorials',
};

export const resourcesImageIconFileTypes: string[] = ['.png', '.jpg', '.jpeg'];
export const resourcesFieldMapping: {
  [key: string]: ResourceField[];
} = {
  [PS_APP]: [
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      fixed: true,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      textarea: true,
    },
    {
      name: 'details',
      title: 'Details',
      type: 'text',
    },
    {
      name: 'videosUrls',
      title: 'Video URL',
      type: 'text',
      multiple: true,
      optional: true,
      amount: 2,
    },
    {
      name: 'iconFileId',
      title: 'Add File Icon',
      type: 'file',
      accept: resourcesImageIconFileTypes.join(','),
      typeDescription: 'icon',
    },
    {
      name: 'appFileId',
      title: 'Add File',
      type: 'file',
      accept: '.apk',
      typeDescription: '.APK',
    },
  ],
  [SAMPLE_APP]: [
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      fixed: true,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      textarea: true,
    },
    {
      name: 'details',
      title: 'Details',
      type: 'text',
    },
    {
      name: 'videosUrls',
      title: 'Video URL',
      type: 'text',
      multiple: true,
      optional: true,
      amount: 2,
    },
    {
      name: 'iconFileId',
      title: 'Add File Icon',
      type: 'file',
      accept: resourcesImageIconFileTypes.join(','),
      typeDescription: 'icon',
    },
    {
      name: 'appFileId',
      title: 'Add File',
      type: 'file',
      accept: '.apk',
      typeDescription: '.APK',
    },
  ],
  [SIMULATOR]: [
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      fixed: true,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      textarea: true,
    },
    {
      name: 'details',
      title: 'Details',
      type: 'text',
    },
    {
      name: 'videosUrls',
      title: 'Video URL',
      type: 'text',
      multiple: true,
      optional: true,
      amount: 2,
    },
    {
      name: 'iconFileId',
      title: 'Add File Icon',
      type: 'file',
      accept: resourcesImageIconFileTypes.join(','),
      typeDescription: 'icon',
    },
    {
      name: 'appFileId',
      title: 'Add File',
      type: 'file',
    },
  ],
  [DOCUMENTATION]: [
    {
      name: 'documents',
      type: 'document',
    },
  ],
  [TUTORIAL]: [
    {
      name: 'title',
      title: 'Title',
      type: 'text',
      fixed: true,
    },
    {
      name: 'description',
      title: 'Description',
      type: 'text',
      textarea: true,
    },
    {
      name: 'details',
      title: 'Details',
      type: 'text',
    },
    {
      name: 'videosUrls',
      title: 'Video URL',
      type: 'text',
      multiple: true,
      optional: true,
      amount: 2,
    },
    {
      name: 'iconFileId',
      title: 'Add File Icon',
      type: 'file',
      accept: resourcesImageIconFileTypes.join(','),
      typeDescription: 'icon',
    },
    {
      name: 'appFileId',
      title: 'Add File',
      type: 'file',
      accept: '.pdf',
      typeDescription: '.PDF',
    },
  ],
};

export const agreementTypeTitleMapping = {
  [Agreement.AgreementType.DEVELOPER]: 'Developer Agreement',
  [Agreement.AgreementType.NDA]: 'Non-Disclosure Agreement',
};
