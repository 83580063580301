import React, { FunctionComponent } from 'react';
import {
  AccountContainer,
  AccountPersonalDetails,
  Company,
  Name,
  StyledAvatar,
} from './AccountView.styles';
import config from '../../config';

interface AccountViewProps {
  avatar: string;
  name?: string;
  companyName?: string;
}

const AccountView: FunctionComponent<AccountViewProps> = ({ name, avatar, companyName }) => (
  <AccountContainer>
    <StyledAvatar src={avatar ? `${config.apiRootUrl}/files/${avatar}` : null} />
    <AccountPersonalDetails>
      <Name>{name}</Name>
      {companyName && <Company>{companyName}</Company>}
    </AccountPersonalDetails>
  </AccountContainer>
);

export default AccountView;
