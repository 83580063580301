import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const StyledImageIcon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 16px;
`;

export const StyledVersionHistoryRow = styled(Box)`
  border-bottom: 1px solid #f1f1f1;
  padding: ${({ theme }) => theme.spacing() * 3}px 0;
`;

export const StyledScreenshot = styled.img`
  width: ${({ theme }) => theme.screenshot.width};
`;

export const StyledTypography = styled(Typography)`
  &.text-center {
    text-align: center;
  }

  &.rejected {
    font-weight: 600;
    color: #cf383c;
  }
`;

export const StyledDetailsContainer = styled(Box)`
  overflow-y: auto;
`;
