import yup, { string, object } from 'yup';
import { validationMessages } from '../constants/messages';

const { field } = validationMessages;

const validationSchema = object().shape({
  email: string().email(field.email).required(field.required),
});

export type ForgotPasswordSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
