import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import store, { persistor } from './store/index';
import Routes from './routes/index';
import ConnectedSnackBar from './components/UI/ConnectedSnackBar';
import { IconGradient } from './helpers/icon-gradient';

// persistor.purge(); // Debug to clear persist

const App: FunctionComponent = () => (
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <IconGradient />
            <Router>
              <Routes />
            </Router>
            <ConnectedSnackBar />
          </MuiThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </PersistGate>
  </Provider>
);

export default App;
