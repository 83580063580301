import React, { FunctionComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '../UI/Button';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Input from '../UI/Input';
import validationSchema from '../../validation/reject-app-form';
import { StyledDialogContent } from './RejectDialog.styles';

export declare type RejectDialogProps = {
  onCancel: () => void;
  onConfirm: (reason: string) => void;
  open: boolean;
  appName?: string;
};

export const RejectDialog: FunctionComponent<RejectDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  appName,
}) => (
  <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth open={open}>
    {open && (
      <Formik
        initialValues={{ reason: '' }}
        validationSchema={validationSchema}
        onSubmit={({ reason }) => onConfirm(reason)}
        validateOnMount={true}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <DialogTitle>
              <IconButton onClick={() => onCancel()}>
                <CloseIcon color="primary" />
              </IconButton>
            </DialogTitle>
            <StyledDialogContent>
              <Typography variant="h5" align="center">
                {`Reject ${appName || 'app'}?`}
              </Typography>
              <Box mt={4}>
                <Input
                  className="rounded"
                  label={`Reason`}
                  type="text"
                  name="reason"
                  required
                  multiline
                  rows={6}
                />
              </Box>
            </StyledDialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => onCancel()} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button type="submit" disabled={!props.isValid} variant="contained" color="primary">
                Confirm
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    )}
  </Dialog>
);
