import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing()}px ${({ theme }) => theme.spacing() * 2}px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
  margin: ${({ theme }) => theme.spacing()}px;
  border-radius: ${({ theme }) => theme.spacing() / 2}px;

  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;
