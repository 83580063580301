import React, { FunctionComponent, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Resources, Profile } from 'westside-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ResourceField } from '../../types/resources';
import { Account } from '../../store/account';
import { useScroll } from '../../helpers/use-scroll';
import { OnError } from '../UI/Form';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header, { AppContextHeader } from '../UI/Header';
import Button from '../UI/Button';
import { UploadField, TextField, DocumentField } from './ResourceFields';
import { FormWrapper } from './AddResource.styles';
import { Footer, FooterLink} from '../UI/Footer/Footer';

interface AddResourceProps {
  logOut: () => void;
  addResource: (resource: any) => Promise<void>;
  account: Account;
  personalProfile: Profile.ProfileDto;
  id: string;
  type: Resources.ResourceType;
  validationSchema: object;
  loading: boolean;
  title: string;
  fields: ResourceField[];
  preselectedData?: Resources.Resource;
  amount?: number;
}

const amountOfFields = (amount) => {
  if(amount){
    const fieldArr:  string[] = [];

    for(let i = 0; i < amount; i++){
      fieldArr.push('');
    }

    return fieldArr;
  } else{
    return ['']
  }
}

const remapInitialData = (fields: ResourceField[], type: Resources.ResourceType): object => {
  if (type === Resources.ResourceType.DOCUMENTATION) {
    return {
      documents: [
        {
          fileId: '',
          title: '',
        },
      ],
    };
  }

  const mappedFields = {};

  fields.map((field) => (mappedFields[field.name] = field.multiple ? amountOfFields(field.amount) : ''));

  return mappedFields;
};

const STICKY_SCROLL_THRESHOLD = 160;

const AddResourceComponent: FunctionComponent<AddResourceProps> = ({
  account,
  personalProfile,
  loading,
  fields,
  type,
  validationSchema,
  preselectedData,
  id,
  logOut,
  addResource,
  title,
}) => {
  useEffect(() => {
    if (preselectedData) {
      const { title, description, details, videosUrls, iconFileId, appFileId } = preselectedData;
      const formData = {
        title,
        description,
        details,
        videosUrls,
        iconFileId,
        appFileId,
      };
      setInitialValues(formData);
      setFormData(formData);
      setSaveState(true);
    }
  }, [preselectedData]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  const [initialValues, setInitialValues] = useState<object>(remapInitialData(fields, type));
  const [enableSave, setSaveState] = useState<boolean>(false);
  const [formData, setFormData] = useState<typeof initialValues | undefined>(undefined);
  const { scrollY } = useScroll();

  const onValidationError = (errors: object[] | undefined): void => {
    return setSaveState(Boolean(!errors && formData));
  };

  const submitForm = () => {
    addResource(formData);
  };

  const fieldOptions = {
    text: ({ name, field, values }) => (
      <TextField
        name={name}
        label={field.title}
        loading={loading}
        multiple={field.multiple}
        textarea={field.textarea}
        options={field.multiple ? values[name] : undefined}
        optional={field.optional}
        fixed={field.fixed}
      />
    ),
    file: ({ name, field, values, setFieldValue }) => (
      <UploadField
        url={'/resource/file'}
        name={name}
        onChange={(fieldName, id) => setFieldValue(fieldName, id)}
        multiple={field.multiple}
        options={values[name]}
        accept={field.accept}
        typeDescription={field.typeDescription}
        amount={field.amount}
      />
    ),
    document: ({ name, values, setFieldValue }) => (
      <DocumentField
        name={name}
        url={'/resource/file'}
        loading={loading}
        documents={values.documents}
        onChange={(fieldName, id) => setFieldValue(fieldName, id)}
      />
    ),
  };

  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <AppContextHeader
        avatar={personalProfile?.avatarFileId}
        account={account}
        closeButtonText={id ? `Edit ${preselectedData?.title || ''}` : title}
        closeButtonLink="/resources"
      />
      <PageLayout form>
        <FormWrapper
          className={scrollY >= STICKY_SCROLL_THRESHOLD ? 'scrolled' : ''}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={6}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}
            validationSchema={validationSchema}
            validate={(values) => setFormData(values)}
          >
            {({ values, setFieldValue }) => (
              <Box width="100%">
                <Form>
                  {Object.keys(values).map((name) => {
                    const field = fields.filter((field) => field.name === name)[0];
                    return fieldOptions[field.type]({ name, field, values, setFieldValue });
                  })}
                </Form>
                <OnError callback={onValidationError} />
              </Box>
            )}
          </Formik>
          <Box mt={4} display="flex" flexDirection="row" alignItems="center">
            <Link to="/resources">
              <Button variant="outlined" color="primary" disabled={loading}>
                Cancel
              </Button>
            </Link>
            <Box ml={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={!enableSave || loading}
                onClick={() => submitForm()}
              >
                Save
              </Button>
            </Box>
            <Box ml={3}>{loading && <CircularProgress color="secondary" size={28} />}</Box>
          </Box>
        </FormWrapper>
      </PageLayout>
      <Footer>
        <FooterLink to={null}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default AddResourceComponent;
