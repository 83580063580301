import yup, { array, object, string } from 'yup';
import { validationMessages } from '../constants/messages';

const { field } = validationMessages;

const validationSchema = object().shape({
  documents: array()
    .of(
      object().shape({
        fileId: string().required(field.required),
        title: string().required(field.required),
      }),
    )
    .required(),
});

export type ResourceDocumentForm = yup.InferType<typeof validationSchema>;
export default validationSchema;
