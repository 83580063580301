import { Dispatch } from '../store';
import formatErrorMessage from '../lib/format-error-messages';
import initialState, { AgreementState, SigningAgreementStatus } from '../store/agreement';
import api from '../lib/api';
import { Agreement } from 'westside-types';
import { v4 as uuidv4 } from 'uuid';

export default {
  namespace: 'agreement',
  state: initialState,
  reducers: {
    setAgreements(state: AgreementState, agreements: Agreement.AgreementsListEntry[]) {
      return {
        ...state,
        agreements,
      };
    },
    setSigningAgreements(state: AgreementState, agreements: Agreement.SigningAgreement[]) {
      const signingAgreements = agreements
        ? agreements.map(({ signed, type }) => ({
            type,
            status: signed ? SigningAgreementStatus.SIGNED : SigningAgreementStatus.NOT_SIGNED,
            id: uuidv4().toString(),
          }))
        : [];
      return {
        ...state,
        signingAgreements,
      };
    },

    setStatus(
      state: AgreementState,
      type: Agreement.AgreementType,
      status: SigningAgreementStatus,
    ) {
      const { signingAgreements } = state;
      const otherSigningAgreements = signingAgreements.filter(
        (agreement) => agreement.type !== type,
      );
      const signingAgreementsWithType = signingAgreements.filter(
        (agreement) => agreement.type === type,
      );
      return {
        ...state,
        signingAgreements: [
          ...otherSigningAgreements,
          ...signingAgreementsWithType.map((agreement) => ({
            id: agreement.id,
            status,
            type,
          })),
        ].sort((a, b) => a.type.localeCompare(b.type)),
      };
    },
  },
  effects: (dispatch: Dispatch) => ({
    async loadConfigAgreements(): Promise<void> {
      try {
        const { data } = await api.get('/agreement/config');
        if (data) {
          dispatch.agreement.setAgreements(data);
        }
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async setAgreementTypeConfig({
      id,
      type,
    }: {
      id: string;
      type: Agreement.AgreementType;
    }): Promise<void> {
      try {
        await api.post('/agreement/config', { id, type });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async loadSigningAgreements(): Promise<void> {
      try {
        const { data } = await api.get('/agreement');
        const { agreements } = data;
        dispatch.agreement.setSigningAgreements(agreements);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async startSigning({
      type,
      returnUrl,
    }: {
      type: Agreement.AgreementType;
      returnUrl: string;
    }): Promise<string> {
      try {
        dispatch.agreement.setStatus(type, SigningAgreementStatus.PROCESSING);
        const { data } = await api.post(`/agreement/${type}`, { returnUrl });
        const { url } = data;
        dispatch.agreement.setStatus(type, SigningAgreementStatus.IN_PROGRESS);
        return url;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async verifySigning({ type }: { type: Agreement.AgreementType }): Promise<void> {
      try {
        dispatch.agreement.setStatus(type, SigningAgreementStatus.PROCESSING);
        const { data } = await api.post(`/agreement/${type}/verify`);
        const { signed } = data;
        dispatch.agreement.setStatus(
          type,
          signed ? SigningAgreementStatus.SIGNED : SigningAgreementStatus.NOT_SIGNED,
        );
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
  }),
};
