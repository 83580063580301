export interface SnackBarInitialState {
  open: boolean;
  message: string;
}

const initialState: SnackBarInitialState = {
  open: false,
  message: '',
};

export default initialState;
