import styled from 'styled-components';
import { sizes } from '../../../theme';

export const StyledHeader = styled.div`
  height: 49px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.spacing() * 8}px 0 ${({ theme }) => theme.spacing() * 6}px;
  border: 1px solid rgba(0, 0, 0, 0.04);

  > div {
    margin: 0 auto;
    max-width: ${sizes.headerMaxWidth}px;
  }

  button {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    .MuiButton-label {
      text-transform: uppercase;
    }
  }
  .MuiAvatar-root {
    margin-left: ${({ theme }) => theme.spacing()}px;
  }
`;
