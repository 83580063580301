import { Notifications } from 'westside-types';

export interface NotificationsState {
  notifications: Notifications.NotificationPreview[];
  hasMore: boolean;
  emailActive: boolean;
}

const initialState: NotificationsState = {
  notifications: [],
  hasMore: true,
  emailActive: true,
};

export default initialState;
