import React, { Component } from 'react';
import { Resources } from 'westside-types';
import { connect } from 'react-redux';
import { iRootState, Dispatch } from '../../store';
import promiseCallbackHandler from '../../lib/promise-callback';
import Layout from '../../components/Resources/ResourceDetails';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  companyProfile: state.profile.companyProfile,
});
const mapDispatchToProps = ({ account, resources, file }: Dispatch) => ({
  logOut: account.logOut,
  getResourceDetails: resources.getResourceDetails,
  downloadFile: file.downloadFile,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface ResourcesState {
  loading: boolean;
  error: string | null;
  details: Resources.Resource | undefined;
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class LoginContainer extends Component<ConnectedProps, ResourcesState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
    details: undefined,
  };

  componentDidMount() {
    // @ts-ignore
    const { id } = this.props.match.params;
    this.getResourceDetails(id);
  }

  getResourceDetails = async (id: string): Promise<void> => {
    const { getResourceDetails } = this.props;
    this.setState({ loading: true });
    const details = await this.promiseCallback(getResourceDetails(id));
    this.setState({ details });
  };

  downloadFile = async (id: string): Promise<void> => {
    const { downloadFile } = this.props;
    this.setState({ loading: true });
    const url = await this.promiseCallback(downloadFile(id));
    window.open(url);
  };

  render() {
    const { loading, details } = this.state;
    // @ts-ignore
    const { logOut, account, companyProfile, match } = this.props;
    const { id } = match.params;

    return (
      <Layout
        loading={loading}
        logOut={logOut}
        account={account}
        companyProfile={companyProfile}
        details={details}
        id={id}
        downloadFile={this.downloadFile}
      />
    );
  }
}
// @ts-ignore
export default LoginContainer;
