import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing()}px 0;
  align-items: center;
`;

export const AccountPersonalDetails = styled.div`
  padding-left: ${({ theme }) => theme.spacing()}px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 55px;
  height: 55px;
`;

export const Name = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 0;
  padding: 0;
`;

export const Company = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 300;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 0;
  padding: 0;
`;
