import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  color: #778899;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background: #f5f8f9;
  align-items: center;
  padding: ${({ theme }) => theme.spacing() * 2}px ${({ theme }) => theme.spacing() * 4}px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  line-height: 8px;
  font-size: 10px;
  text-transform: capitalize;
`;

export const BrandContainer = styled.div`
  flex: 1;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const Links = styled.div``;

export const FooterLinkContainer = styled.span`
  display: inline-block;
  padding: 0 ${({ theme }) => theme.spacing()}px;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: ${({ separator }) => (separator ? 'block' : 'none')};
    height: 100%;
    width: 1px;
    background-color: #778899;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.light};
  text-decoration: none;
`;
