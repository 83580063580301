import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Button from '../../UI/Button';

export const StyledDropZone = styled(Box)`
  box-shadow: 0px 0px 16px rgba(39, 170, 225, 0.23);
  padding: ${({ theme }) => theme.spacing() * 3}px;

  .container {
    border: 1px dashed ${({ theme }) => theme.palette.primary.light};
    padding: ${({ theme }) => theme.spacing() * 3}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f8f8f8;
    outline: none;
    height: 120px;
    &:hover {
        cursor pointer;
        background: #ffffff;
    }
  }
`;

export const StyledButton = styled(Button)`
  .MuiButton-label {
    text-transform: uppercase;
  }
`;

export const StyledThumbnails = styled(Box)`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  > div .screenshot {
    width: ${({ theme }) => theme.screenshot.width};
    height: 83px;
    background-color: #f8f8f8;
    background-size: 100% auto;
  }
`;

export const StyledScreenshotLightbox = styled(Box)`
  img {
    width: 100%;
  }
`;
