import React, { Component } from 'react';
import { Faq } from 'westside-types';
import Layout from '../../components/FaqList/Layout';
import { Dispatch, iRootState } from '../../store';
import { connect } from 'react-redux';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showNotification: dispatch.snackbar.open,
  logOut: dispatch.account.logOut,
  getFaqs: dispatch.faq.getFaqs,
});

type FaqListState = {
  faqs: Faq.FaqDto[] | null;
  allFaqs: Faq.FaqDto[] | null;
  searchQuery: string;
};

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

@connect(mapStateToProps, mapDispatchToProps)
export default class FaqListContainer extends Component<ConnectedProps, FaqListState> {
  state = {
    faqs: null,
    allFaqs: null,
    searchQuery: '',
  };

  componentDidMount() {
    const { showNotification, getFaqs } = this.props;
    const { searchQuery } = this.state;
    getFaqs()
      .then(({ faqs }) => {
        this.setState({
          faqs: FaqListContainer.filterFaqs(faqs, searchQuery),
          allFaqs: faqs,
        });
      })
      .catch(() => showNotification('Error while fetching faqs'));
  }

  private static filterFaqs(faqs: Faq.FaqDto[], searchQuery: string): Faq.FaqDto[] {
    const searchQueryNormalized = searchQuery.toLowerCase().trim();
    return faqs.filter(
      (faq) =>
        faq.question.toLowerCase().includes(searchQueryNormalized) ||
        faq.answer.toLowerCase().includes(searchQueryNormalized),
    );
  }

  private handleSearchQueryChange(searchQuery: string) {
    const { allFaqs } = this.state;
    this.setState({
      faqs: FaqListContainer.filterFaqs(allFaqs, searchQuery),
      searchQuery,
    });
  }

  render() {
    const { faqs, allFaqs, searchQuery } = this.state;
    const { account, personalProfile, companyProfile, logOut } = this.props;
    return (
      <Layout
        faqs={faqs}
        allFaqs={allFaqs}
        account={account}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        logOut={logOut}
        searchQuery={searchQuery}
        searchQueryChange={(searchQuery) => this.handleSearchQueryChange(searchQuery)}
      />
    );
  }
}
