import yup, { object, string } from 'yup';
import { validationMessages } from '../constants/messages';

const { field } = validationMessages;

const validationSchema = object().shape({
  reason: string().required(field.required),
});

export type RejectAppSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
