import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const SigningButtonWrapper = styled.div`
  display: inline-block;

  .MuiIconButton-root {
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};

    .MuiSvgIcon-root.MuiSvgIcon-colorSecondary {
      color: ${({ theme }) => theme.palette.secondary.light};
    }
  }

  .signed {
    display: inline-block;
    padding: ${({ theme }) => (theme.spacing() * 3) / 2}px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  vertical-align: middle;
`;
