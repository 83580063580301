import account from './account'; // eslint-disable-line
import app from './app'; // eslint-disable-line
import apk from './apk'; // eslint-disable-line
import snackbar from './snackbar'; // eslint-disable-line
import file from './file'; // eslint-disable-line
import faq from './faq'; // eslint-disable-line
import resources from './resources'; // eslint-disable-line
import notifications from './notifications'; // eslint-disable-line
import profile from './profile'; // eslint-disable-line
import users from './users'; // eslint-disable-line
import agreement from './agreement'; // eslint-disable-line

export interface RootModel {
  account: typeof account;
  app: typeof app;
  apk: typeof apk;
  snackbar: typeof snackbar;
  file: typeof file;
  faq: typeof faq;
  resources: typeof resources;
  notifications: typeof notifications;
  profile: typeof profile;
  users: typeof users;
  agreement: typeof agreement;
}

export const models: RootModel = {
  account,
  app,
  apk,
  snackbar,
  file,
  faq,
  resources,
  notifications,
  profile,
  users,
  agreement,
};
