import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Notifications/List';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  notifications: state.notifications.notifications,
  hasMore: state.notifications.hasMore,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  loadMore: dispatch.notifications.loadMore,
  clean: dispatch.notifications.clean,
});

type ProfileViewProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type ProfileViewState = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class ProfileView extends Component<ProfileViewProps, ProfileViewState> {
  componentDidMount() {
    const { loadMore } = this.props;
    loadMore();
  }

  componentWillUnmount() {
    const { clean } = this.props;
    clean();
  }

  render() {
    const { account, logOut, loadMore, notifications, hasMore } = this.props;
    return (
      <Layout
        account={account}
        logOut={logOut}
        notifications={notifications}
        hasMore={hasMore}
        onLoadMore={() => loadMore(notifications.length)}
      />
    );
  }
}
