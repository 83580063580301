import { App } from 'westside-types';
import moment from 'moment';

export type FileId = string;
export type FileName = string;

export interface CreateAppFile {
  id: FileId;
  name: FileName;
}

export type AppDetails = CreateAppFile | undefined;
export type ScreenshotDetails = CreateAppFile[];
export type AppIconDetails = CreateAppFile | undefined;

export interface AppCombinedDetails {
  appInfo: App.AppInfo;
  appDetails: App.AppDetails;
  apkFileId: string;
  iconFileId: string;
  screenshotFileIds: string[];
}

export interface AppInitialState {
  allApps: App.AppCenterAppDto[];
  reviewedApp: App.AppReleaseReviewPreview | undefined;
  selectedApp: App.AppDto | undefined;
  appDetails: AppDetails;
  screenshotDetails: ScreenshotDetails;
  appIconDetails: AppIconDetails;
  uploadProgress: number;
  updatedAt: number;
}

const initialState: AppInitialState = {
  allApps: [],
  appDetails: undefined,
  reviewedApp: undefined,
  selectedApp: undefined,
  screenshotDetails: [],
  appIconDetails: undefined,
  uploadProgress: 0,
  updatedAt: moment().unix(),
};

export default initialState;
