import React, { FunctionComponent, useState, useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { StyledStepper, StyledStepperContent, StyledStepIcon, StyledStepperButtons } from './Stepper.styles';

interface Step {
  [key: string]: any;
}

interface Stepper {
  steps: Step[];
  onChange?: (step: number) => void;
  onFinish?: () => void;
  finishText?: string;
  loading?: boolean;
  disabled?: boolean;
  actions?:
    | {
        title: string;
        disabled: boolean;
        callback: (data: any) => void;
      }[]
    | undefined;
  cancelAction?: {
    label: string;
    link: string;
  };
}

const StepperElement: FunctionComponent<Stepper> = ({
  steps,
  onFinish,
  onChange,
  disabled,
  loading,
  actions,
  cancelAction,
  finishText,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    onChange && onChange(activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === steps.length - 1) {
        onFinish && onFinish();
        return prevActiveStep;
      }

      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex: number) => {
    return Object.values(steps[stepIndex])[0];
  };

  const isLastStep = activeStep === steps.length - 1;

  const StepIcon = ({ step, active }: { step: number; active: boolean | undefined }) => {
    return (
      <StyledStepIcon
        active={active}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" color={active ? 'inherit' : 'textPrimary'}>
          {step + 1}
        </Typography>
      </StyledStepIcon>
    );
  };

  return (
    <StyledStepper display="flex" flexDirection="column" height="100%">
      <StyledStepperContent flexGrow="1">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step: Step, index) => (
            <Step key={`step-${index}`}>
              <StepLabel
                StepIconComponent={({ active, completed }: StepIconProps) => (
                  <StepIcon step={index} active={active || completed} />
                )}
              >
                {Object.keys(step)[0]}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {getStepContent(activeStep)}
      </StyledStepperContent>
      <StyledStepperButtons className="action-buttons">
        {activeStep === 0 && cancelAction && (
          <Link to={cancelAction.link}>
            <Button variant="outlined" color="primary" disabled={loading}>
              {cancelAction.label}
            </Button>
          </Link>
        )}
        {activeStep > 0 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        <Box ml={2}>
          <Button
            disabled={activeStep === steps.length || disabled || loading}
            variant="contained"
            color="primary"
            onClick={handleNext}
            isPending={loading}
          >
            {isLastStep ? finishText ? finishText: 'Finish' : 'Next'}
          </Button>
        </Box>
        {actions &&
          actions.map((action, index) => (
            <Box ml={2} key={`action-button-${index}`}>
              <Button
                disabled={loading || action.disabled}
                variant="contained"
                color="primary"
                onClick={action.callback}
              >
                {action.title}
              </Button>
            </Box>
          ))}
      </StyledStepperButtons>
    </StyledStepper>
  );
};

export default StepperElement;
