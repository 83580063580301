import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Input from '../UI/Input';
import { OnError } from '../UI/Form';
import validationSchema from '../../validation/create-app-details-form';
import { StyledInfoForm } from './Form.styles';

export interface DetailsData {
  description: string;
  fleetConsiderationsAndRequirements: string;
  releaseNotes: string;
}

export interface DetailsDataProps {
  onDataChange: (data: DetailsData) => void;
  onError: (error) => void;
  loading: boolean;
  initialData?: DetailsData;
}

const Details: FunctionComponent<DetailsDataProps> = ({
  onDataChange,
  onError,
  initialData,
  loading,
}) => {
  const initialValues: DetailsData = {
    description: '',
    fleetConsiderationsAndRequirements: '',
    releaseNotes: '',
  };

  const onValidationError = (errors) => {
    if (onError) {
      onError(errors);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialData || initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validate={(values: DetailsData) => {
        onDataChange(values);
      }}
    >
      <Box>
        <StyledInfoForm display="flex" flexDirection="column">
          <Input
            className="rounded"
            label="Description"
            type="text"
            name="description"
            required
            multiline
            rows={6}
            disabled={loading}
          />
          <Input
            className="rounded"
            label="Fleet Considerations &amp; Requirements"
            type="text"
            name="fleetConsiderationsAndRequirements"
            required
            multiline
            rows={6}
            disabled={loading}
          />
          <Input
            className="rounded"
            label="Release Notes"
            type="text"
            name="releaseNotes"
            required
            multiline
            rows={6}
            disabled={loading}
          />
        </StyledInfoForm>
        <OnError callback={onValidationError} />
      </Box>
    </Formik>
  );
};

export default Details;
