import React, { Component } from 'react';
import { App } from 'westside-types';
import Layout from '../../components/AppCenter/Layout';
import promiseCallbackHandler from '../../lib/promise-callback';
import { iRootState, Dispatch } from '../../store';
import { connect } from 'react-redux';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  getApps: dispatch.app.getApps,
  disableApp: dispatch.app.disableApp,
  enableApp: dispatch.app.enableApp,
  downloadApk: dispatch.app.downloadApk,
  deleteApp: dispatch.app.deleteApp,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type AppCenterState = {
  loading: boolean;
  allApps: App.AppCenterAppDto[] | undefined;
};

class AppCenterContainer extends Component<ConnectedProps, AppCenterState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: true,
    allApps: undefined,
  };

  componentDidMount() {
    this.getApps();
  }

  getApps = async (): Promise<void> => {
    const { getApps } = this.props;
    this.setState({ loading: true });
    const allApps = await this.promiseCallback(getApps());
    return this.setState({ allApps });
  };

  enableApp = async (appId: string): Promise<void> => {
    const { enableApp } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(enableApp(appId));
    return this.getApps();
  };

  disableApp = async (appId: string): Promise<void> => {
    const { disableApp } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(disableApp(appId));
    return this.getApps();
  };

  deleteApp = async (appId: string): Promise<void> => {
    const { deleteApp } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(deleteApp(appId));
    return this.getApps();
  };

  render() {
    const { logOut, account, downloadApk, personalProfile, companyProfile } = this.props;

    const { loading, allApps } = this.state;

    return (
      <Layout
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        allApps={allApps}
        account={account}
        loading={loading}
        logOut={logOut}
        downloadApk={(fileId) => downloadApk(fileId).then((url) => window.open(url, '_blank'))}
        deleteApp={this.deleteApp}
        disableApp={this.disableApp}
        enableApp={this.enableApp}
      />
    );
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AppCenterContainer);
