import formatErrorMessage from '../lib/format-error-messages';
import { App } from 'westside-types';
import { Dispatch } from '../store';
import api from '../lib/api';

export default {
  namespace: 'apk',
  state: null,
  reducers: {
    empty(state) {
      return state;
    },
  },
  effects: (dispatch: Dispatch) => ({
    async getApkMetadata({ apkFileId }: { apkFileId: string }): Promise<App.ApkMetadata> {
      try {
        const response = await api.get(`/app/apk/${apkFileId}/metadata`);
        return response.data;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
  }),
};
