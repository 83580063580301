import styled from 'styled-components';
import { Field } from 'formik';

export const StyledCheckbox = styled(Field)`
  margin-bottom: 0;

  &:not(.Mui-checked) {
    svg path {
      stroke: #fff;
      stroke-width: 1px;
      fill: #000;
    }
  }
`;
