import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const TypographyContainer = styled.div`
  padding: 120px 0;
  text-align: center;
`;

export const StyledTypography = styled(Typography)`
  padding: 5px 0;
`;
