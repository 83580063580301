import { Agreement } from 'westside-types';

export enum SigningAgreementStatus {
  SIGNED = 'SIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_SIGNED = 'NOT_SIGNED',
  PROCESSING = 'PROCESSING',
}

export interface SigningAgreement {
  status: SigningAgreementStatus;
  type: Agreement.AgreementType;
}

export interface AgreementState {
  agreements: Agreement.AgreementsListEntry[];
  signingAgreements: SigningAgreement[];
}

const initialState: AgreementState = {
  agreements: [],
  signingAgreements: [],
};
export default initialState;
