import React, { FunctionComponent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { useField } from 'formik';
import { StyledCheckbox } from './Checkbox.styles';

interface CheckboxElement {
  label: string;
  id: string;
  name: string;
  [x: string]: any;
}

const CheckboxElement = ({ field, form: { touched, errors }, label, ...props }) => {
  const isError = Boolean(touched[field.name] && errors[field.name]);

  return (
    <FormGroup row>
      <FormControl error={isError}>
        <FormControlLabel
          control={<Checkbox {...field} {...props} color="primary" />}
          label={label}
        />
        {isError && <FormHelperText>{errors[field.name]}</FormHelperText>}
      </FormControl>
    </FormGroup>
  );
};

const FormikCheckbox: FunctionComponent<CheckboxElement> = (props) => {
  const [field] = useField(props.name);
  return <StyledCheckbox component={CheckboxElement} {...props} {...field} checked={field.value} />;
};

export default FormikCheckbox;
