import { App } from 'westside-types';
import { createMuiTheme } from '@material-ui/core';
import { PaletteColorOptions } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }

  interface Palette {
    tertiary?: PaletteColor;
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    screenshot: {
      width: React.CSSProperties['width'];
    };
    appReviewPalette: {
      [key: string]: string;
    };
    pillPalette: {
      [key: string]: string;
    };
  }
  interface ThemeOptions {
    screenshot?: {
      width: React.CSSProperties['width'];
    };
    appReviewPalette?: {
      [key: string]: string;
    };
    pillPalette?: {
      [key: string]: string;
    };
  }
}

export const { DRAFT, IN_REVIEW, PENDING, APPROVED, REJECTED } = App.AppComputedState;
const spacing = 8;

export const colors = {
  primary: '#1C75BC',
  light: '#27AAE1',
  dark: '#3D4B67',
  fontColorPrimary: '#3D4B67',
  fontColorSecondary: '#778899',
  fontColorTertiaryLight: '#979797',
  secondary: '#27AAE1',
};

export const sizes = {
  formPageMaxWidth: 540,
  pageMaxWidth: 960,
  headerMaxWidth: 960,
};

export default createMuiTheme({
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontWeight: 300,
      fontSize: '1.5rem',
      color: colors.fontColorPrimary,
      lineHeight: '32px',
      fontStyle: 'normal',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 300,
      fontSize: '1.5rem',
      color: '#3D4B67',
      lineHeight: 2,
      fontStyle: 'normal',
    },
    h5: {
      fontSize: '18px',
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 300,
    },
    subtitle2: {
      color: colors.fontColorPrimary,
      fontSize: '1.125rem',
      textTransform: 'uppercase',
      fontWeight: 300,
    },
    body1: {
      color: colors.fontColorPrimary,
    },
    body2: {
      color: colors.fontColorPrimary,
      fontWeight: 600,
      lineHeight: 2,
    },
    caption: {
      color: colors.fontColorPrimary,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      light: colors.light,
      dark: colors.dark,
    },
    secondary: {
      main: colors.secondary,
      light: '#ffffff',
      dark: '#F5F8F9',
    },
    tertiary: {
      main: colors.fontColorSecondary,
      light: colors.fontColorTertiaryLight,
    },
    warning: {
      main: '#ee8686',
      light: '#e27979',
    },
    success: {
      main: '#34A853',
    },
    text: {
      primary: colors.fontColorPrimary,
      secondary: colors.fontColorSecondary,
    },
  },
  overrides: {
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'none',
      },
    },
    MuiTab: {
      root: {
        marginRight: spacing * 4,
        // eslint-disable-next-line
        ['@media (min-width:600px)']: {
          minWidth: 'auto',
        },
        paddingLeft: 0,
        paddingRight: 0,
        '&.Mui-selected .MuiTab-wrapper': {
          color: colors.light,
        },
      },
      wrapper: {
        color: colors.primary,
        fontWeight: 600,
        fontSize: '12px',
      },
    },
    MuiTabs: {
      flexContainer: {
        borderBottom: '3px solid #F2F2F2',
      },
      indicator: {
        height: '3px',
      },
    },
    MuiStepIcon: {
      root: {
        width: '40px',
        height: '40px',
      },
      text: {
        fontSize: '10px',
      },
    },
    MuiStepConnector: {
      root: {
        '& .MuiStepConnector-line': {
          borderColor: colors.primary,
        },
      },
      alternativeLabel: {
        top: '20px',
      },
    },
    MuiAvatar: {
      root: {
        width: '40px',
        height: '40px',
      },
    },
    MuiIconButton: {
      root: {
        color: colors.light,
      },
      label: {
        alignItems: 'center',
      },
    },
    MuiTable: {
      root: {
        marginLeft: -spacing * 2,
        marginRight: -spacing * 2,
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 'normal',
      },
      head: {
        paddingTop: spacing,
        paddingBottom: spacing,
        color: '#A3A3A3',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
        fontWeight: 600,
      },
      contained: {
        boxShadow: 'none',
      },
      disableElevation: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          '@media (hover: none)': {
            boxShadow: 'none',
          },
        },
        '&$focusVisible': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&$disabled': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.primary,
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
        },
      },
      containedSecondary: {
        backgroundColor: colors.light,
        '&:hover': {
          backgroundColor: colors.light,
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '&$focused $notchedOutline': {
          borderColor: colors.light,
        },
        '&.rounded': {
          borderRadius: 4,
        },
      },
      input: {
        padding: '18px',
        background: 'none',
      },
      notchedOutline: {
        borderColor: '#cbd4e5',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 16,
        color: '#778899',
      },
    },
    MuiFormHelperText: {
      root: {
        '&$contained': {
          marginLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: colors.primary,
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        color: colors.fontColorPrimary,
        textTransform: 'uppercase',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: 'white',
      },
      colorSecondary: {
        color: colors.primary,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#092432',
        borderRadius: '4px',
        justifyContent: 'center',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: 0,
        textAlign: 'right',
      },
    },
    MuiDialogActions: {
      root: {
        paddingTop: spacing * 4,
        paddingBottom: spacing * 4,
        justifyContent: 'center',
      },
    },
    MuiDialogContent: {
      root: {
        '&&:first-child': {
          paddingTop: spacing * 6,
        },
        paddingLeft: spacing * 4,
        paddingRight: spacing * 4,
      },
    },
    MuiBackdrop: {
      root: {
        background:
          'radial-gradient(77.36% 80.69% at 50% 34.8%, rgba(11, 34, 62, 0.4592) 0%, rgba(6, 20, 36, 0.82) 100%)',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        fontFamily: 'Open Sans',
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'middle',
      },
      fontSizeSmall: {
        fontSize: '1rem',
      },
      colorPrimary: {
        fill: 'url(#icon-gradient)',
      },
      colorSecondary: {
        color: colors.primary,
      },
    },
  },
  spacing,
  screenshot: {
    width: '143px',
  },
  appReviewPalette: {
    [REJECTED]: '#EE8686',
    [DRAFT]: '#27AAE1',
    [PENDING]: '#2B8FDE',
    [IN_REVIEW]: '#15588D',
    [APPROVED]: '#34A853',
  },
  pillPalette: {
    default: '#6B90AE',
    success: '#34A853',
  },
});
