import React, { FunctionComponent } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { StyledPage } from './Page.styles';

type PageLayoutProps = {
  form?: boolean;
  sticky?: boolean;
  noBottomPad?: boolean;
};

const PageElement: FunctionComponent<PageLayoutProps & BoxProps> = ({ children, ...props }) => {
  return (
    <StyledPage {...props}>
      <Box>{children}</Box>
    </StyledPage>
  );
};

export default PageElement;
