import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Input from '../UI/Input';
import PasswordInput from '../UI/PasswordInput';
import Checkbox from '../UI/Checkbox';
import Button from '../UI/Button';
import Logo from '../UI/Logo';
import { Auth } from 'westside-types';
import { Account } from '../../store/account';
import validationSchema from '../../validation/login';
import { StyledBackgroundAccent, StyledFormWrapper, StyledGetHelpButton } from './Login.styles';
import { PageContainer } from '../UI/Layouts';

interface LoginProps {
  loading: boolean;
  error: string | null;
  account: Account;
  logIn: (data: Auth.CredentialsDto) => void;
}

class LoginComponent extends Component<LoginProps> {
  render() {
    const { logIn, account, loading } = this.props;
    const initialValues = { username: account.login, password: '' };

    return (
      <>
        <StyledBackgroundAccent />
        <PageContainer center={true}>
          <StyledFormWrapper>
            <Box textAlign="center" mb={4}>
              <Logo />
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => logIn(values)}
            >
              {({ handleSubmit }: any) => (
                <Box display="flex" flexDirection="column" width="100%" className="login-form">
                  <Form>
                    <Input label="Email" className="rounded" type="text" name="username" />
                    <PasswordInput
                      label="Password"
                      className="rounded"
                      type="password"
                      name="password"
                    />
                    <Checkbox label="Remember Me" id="rememberMe" name="rememberMe" />
                    <Box mt={4} textAlign="center">
                      <Button
                        isPending={loading}
                        disabled={loading}
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        className="submit"
                      >
                        Log in
                      </Button>
                    </Box>
                  </Form>
                </Box>
              )}
            </Formik>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              mt={1}
            >
              <Typography variant="caption">Forgot Password?</Typography>
              <Link to="/forgot-password">
                <StyledGetHelpButton size="small" color="primary">
                  Get Help
                </StyledGetHelpButton>
              </Link>
            </Box>
            <Box textAlign="center" mt={2}>
              <Link to="/register">
                <Button size="small" color="primary">
                  Register New Account
                </Button>
              </Link>
            </Box>
          </StyledFormWrapper>
        </PageContainer>
      </>
    );
  }
}

export default LoginComponent;
