import { App } from 'westside-types';
import { ResourceType, ResourceActions } from '../types/resources';

export const { DRAFT, IN_REVIEW, PENDING, APPROVED, REJECTED } = App.AppComputedState;
export const { PS_APP, SAMPLE_APP, DOCUMENTATION, SIMULATOR, TUTORIAL } = ResourceType;
export const { ADD } = ResourceActions;

export default {
  [DRAFT]: 'Draft',
  [IN_REVIEW]: 'In Review',
  [PENDING]: 'Pending',
  [APPROVED]: 'Available',
  [REJECTED]: 'Rejected',
  [PS_APP]: 'Platform science apps',
  [SAMPLE_APP]: 'Sample apps',
  [DOCUMENTATION]: 'Documentation',
  [SIMULATOR]: 'Simulators',
  [TUTORIAL]: 'Tutorials',
  [ADD]: 'Add',
};
