// TODO ideally we should create upload sessions for each file
import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { uuid } from 'uuidv4';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import config from '../../../config';
import { iRootState, Dispatch } from '../../../store';
import { UploadedFile } from '../../../store/file';
import formatErrorMessage from '../../../lib/format-error-messages';
import truncate from '../../../helpers/truncate-string';
import Button from '../Button';
import {
  StyledFileUploader,
  StyledDropZone,
  StyledLinearProgress,
  StyledLoader,
} from './ConnectedFileUploader.styles';

const mapStateToProps = (state: iRootState) => ({
  fileStore: state.file,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  purgeUploadSession: dispatch.file.purgeUploadSession,
  uploadFile: dispatch.file.uploadFile,
  openSnackBar: dispatch.snackbar.open,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
interface FileUploader {
  forceLoader?: boolean;
  type?: string;
  name?: string;
  title?: string;
  subtitle?: string;
  preselectedFile?: UploadedFile | undefined;
  additionalFileIcon?: UploadedFile | undefined;
  disableFileIcon?: boolean;
  typeDescription?: string;
  url: string;
  useSelectedFileAsAnIcon?: boolean;
  onChange?: (file: UploadedFile) => void;
}

const FileUploader: FunctionComponent<FileUploader & ConnectedProps> = ({
  forceLoader,
  type,
  title,
  name,
  subtitle,
  preselectedFile,
  additionalFileIcon,
  disableFileIcon,
  useSelectedFileAsAnIcon,
  fileStore,
  url,
  uploadFile,
  purgeUploadSession,
  openSnackBar,
  typeDescription,
  onChange,
}) => {
  const [selectedFile, setSelectedFile] = useState<UploadedFile | File>(
    preselectedFile || { id: '', name: '' },
  );
  const [originalFileName, setOriginalFileName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadSession, setUploadSession] = useState<string>('');

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      uploadSelectedFile(acceptedFiles[0]);
    } else {
      openSnackBar('Please provide file with proper type');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: type,
    onDrop,
  });

  useEffect(() => {
    setUploadSession(uuid());

    return () => {
      purgeUploadSession(uploadSession);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preselectedFile && preselectedFile.name !== selectedFile.name) {
      setSelectedFile(preselectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedFile]);

  useEffect(() => {
    if (fileStore[uploadSession]) {
      const { uploadedFile }: { uploadedFile: UploadedFile } = fileStore[uploadSession];

      onChange &&
        onChange({
          name: uploadedFile.name,
          id: uploadedFile.id,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const resetSession = () => {
    purgeUploadSession(uploadSession);
    setUploadSession(uuid());
  };

  const uploadSelectedFile = async (file: File) => {
    try {
      setIsLoading(true);
      await uploadFile({ file, url, session: uploadSession });
      setSelectedFile(file);
      setOriginalFileName(file.name);
    } catch (error) {
      const errorObject = formatErrorMessage(error);
      resetSession();
      openSnackBar(errorObject.message);
    }

    setIsLoading(false);
  };

  const onFileSelection = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files?.length) {
      const file = target.files[0];
      uploadSelectedFile(file);
    }
  };

  const isFileSelected = Boolean(selectedFile.name);

  const getIconSrc = () => {
    const fileIcon = useSelectedFileAsAnIcon ? selectedFile : additionalFileIcon;

    if (fileIcon) {
      return fileIcon.id
        ? `${config.apiRootUrl}/files/${fileIcon.id}`
        : URL.createObjectURL(fileIcon);
    }
    return require('../../../assets/pdf.svg');
  };

  const Icon = () =>
    !disableFileIcon ? (
      <>
        {isFileSelected ? (
          <Box mr={3}>
            <img alt="app-icon" src={getIconSrc()} />
          </Box>
        ) : (
          <StyledDropZone
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mr={3}
          >
            <AddIcon />
          </StyledDropZone>
        )}
      </>
    ) : null;

  return (
    <StyledFileUploader {...getRootProps()} isDragActive={isDragActive}>
      <input key={uploadSession} {...getInputProps({ type: type })} />
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center" flex="1">
          {!forceLoader ? (
            !isLoading ? (
              <>
                <Icon />
                {Boolean(originalFileName || selectedFile.name || title || subtitle) && (
                  <Box mr={2}>
                    {selectedFile.name && (
                      <Typography noWrap variant="h6">
                        {truncate(originalFileName || selectedFile.name, 24)}
                      </Typography>
                    )}
                    {!selectedFile.name && title && (
                      <Typography noWrap variant="h6">
                        {truncate(title, 24)}
                      </Typography>
                    )}
                    {subtitle && <Typography variant="caption">{subtitle}</Typography>}
                  </Box>
                )}
                {!selectedFile.name && !title && (
                  <Typography variant="caption">Upload {typeDescription || '.APK'}</Typography>
                )}
              </>
            ) : (
              <Box pr={3} width="50%">
                <Typography variant="caption">Uploading...</Typography>
                <StyledLinearProgress
                  variant="determinate"
                  value={fileStore[uploadSession] ? fileStore[uploadSession].uploadProgress : 0}
                />
              </Box>
            )
          ) : (
            <>
              <StyledLoader color="secondary" size={24} />
              <Typography variant="caption">
                Processing {typeDescription || '.APK'} data...
              </Typography>
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <input
            key={uploadSession}
            id={name || 'contained-button-file'}
            name={name || 'contained-button-file'}
            accept={type}
            multiple
            type="file"
            onChange={onFileSelection}
          />
          {onChange && (
            <>
              {!isFileSelected && <Typography variant="caption">Drag &amp; Drop OR</Typography>}
              <Box display="inline-block" ml={2}>
                <label htmlFor={name || 'contained-button-file'}>
                  <Button
                    variant={isFileSelected ? 'text' : 'contained'}
                    color="primary"
                    component="span"
                    disabled={isLoading}
                  >
                    {isFileSelected ? 'Replace' : 'Choose File'}
                  </Button>
                </label>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </StyledFileUploader>
  );
};

//@ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
