import { App } from 'westside-types';
import formatErrorMessage from '../lib/format-error-messages';
import initialState, { AppInitialState, ScreenshotDetails, AppCombinedDetails } from '../store/app';
import { Dispatch } from '../store';
import api from '../lib/api';

export default {
  namespace: 'app',
  state: initialState,
  reducers: {
    setCurrentlyReviewedApp(state: AppInitialState, reviewedApp: App.AppReleaseReviewPreview) {
      return {
        ...state,
        reviewedApp,
      };
    },
    removeCurrentlyReviewedApp(state: AppInitialState) {
      return {
        ...state,
        reviewedApp: undefined,
      };
    },
  },
  effects: (dispatch: Dispatch) => ({
    async getApps(): Promise<App.AppCenterAppDto[]> {
      try {
        const response = await api.get(`/app`);
        return response.data;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async getReleaseReview({ appId }: { appId: string }): Promise<void> {
      try {
        const response = await api.get(`/app/${appId}/review`);
        dispatch.app.setCurrentlyReviewedApp(response.data);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async getAppById({ appId }: { appId: string }): Promise<App.AppDto> {
      try {
        const response = await api.get(`/app/${appId}`);
        return response.data;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async postReleaseReview({
      appId,
      reviewData,
    }: {
      appId: string;
      reviewData: App.SetAppReleaseReviewData;
    }): Promise<void> {
      try {
        await api.post(`/app/${appId}/review`, reviewData);
        dispatch.app.removeCurrentlyReviewedApp();
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async getAppReleasePreview({
      appId,
      releaseId,
    }: {
      appId: string;
      releaseId: string;
    }): Promise<App.AppDto> {
      try {
        const response = await api.get(`/app/${appId}`, { params: { releaseId } });
        return response.data;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async getAppRelease({ appId }: { appId: string }): Promise<AppCombinedDetails> {
      try {
        const appReleaseResponse = await api.get(`/app/${appId}/release`);
        const apkMetadataResponse = await dispatch.apk.getApkMetadata({
          apkFileId: appReleaseResponse.data.apkFileId,
        });
        const [apkDetails, appDetails] = [apkMetadataResponse, appReleaseResponse.data];

        return {
          appInfo: {
            name: apkDetails.name,
            draft: appDetails.draft,
            category: appDetails.category,
            apiLevel: apkDetails.apiVersion,
            price: appDetails.price,
            version: apkDetails.version,
            fingerprint: apkDetails.fingerprint,
            iconFileId: apkDetails.iconFileId,
            dataUsage: appDetails.dataUsage,
            ownershipApproved: appDetails.ownershipApproved,
          },
          appDetails: {
            description: appDetails.description,
            fleetConsiderationsAndRequirements: appDetails.fleetConsiderationsAndRequirements,
            releaseNotes: appDetails.releaseNotes,
          },
          apkFileId: appDetails.apkFileId,
          iconFileId: apkDetails.iconFileId,
          screenshotFileIds: appDetails.screenshotFileIds,
        };
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async disableApp(appId: string): Promise<void> {
      try {
        await api.post(`/app/${appId}/disable`);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async enableApp(appId: string): Promise<void> {
      try {
        await api.post(`/app/${appId}/enable`);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async uploadScreenshots(screenshots: File[]): Promise<ScreenshotDetails> {
      try {
        const screenshotsCollection: ScreenshotDetails = [];

        await screenshots.reduce(async (promise, screenshot) => {
          await promise;
          const uploadLinkGenerationData = {
            contentType: screenshot.type,
            originalName: screenshot.name,
          };
          const { data } = await api.post('/app/image', uploadLinkGenerationData);
          const uploadUrl = data.url;
          await api.put(uploadUrl, screenshot, {
            withCredentials: false,
            headers: {
              'Content-Type': screenshot.type,
            },
          });

          screenshotsCollection.push({
            name: screenshot.name,
            id: data.fileId,
          });
        }, Promise.resolve());

        return screenshotsCollection;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async deleteScreenshot(deletedScreenshotId: string): Promise<void> {
      try {
        await api.delete(`/app/image/${deletedScreenshotId}`);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async submitApp(appDetails): Promise<void> {
      try {
        const {
          apkFileId,
          category,
          price,
          dataUsage,
          description,
          fleetConsiderationsAndRequirements,
          releaseNotes,
          screenshotFileIds,
          ownershipApproved,
          draft,
        } = appDetails;
        await api.post('/app', {
          apkFileId,
          category,
          price,
          dataUsage,
          description,
          fleetConsiderationsAndRequirements,
          releaseNotes,
          screenshotFileIds,
          ownershipApproved,
          draft,
        });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async submitDraft({ appId, appDetails }: { appId: string; appDetails: any }): Promise<void> {
      try {
        const {
          apkFileId,
          category,
          price,
          dataUsage,
          description,
          fleetConsiderationsAndRequirements,
          releaseNotes,
          screenshotFileIds,
          ownershipApproved,
        } = appDetails;
        await api.post(`/app/${appId}/release`, {
          apkFileId,
          category,
          price,
          dataUsage,
          description,
          fleetConsiderationsAndRequirements,
          releaseNotes,
          screenshotFileIds,
          ownershipApproved,
          draft: true,
        });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async submitRelease({ appId, appDetails }: { appId: string; appDetails: any }): Promise<void> {
      try {
        const {
          apkFileId,
          category,
          price,
          dataUsage,
          description,
          fleetConsiderationsAndRequirements,
          releaseNotes,
          screenshotFileIds,
          ownershipApproved,
        } = appDetails;
        await api.post(`/app/${appId}/release`, {
          apkFileId,
          category,
          price,
          dataUsage,
          description,
          fleetConsiderationsAndRequirements,
          releaseNotes,
          screenshotFileIds,
          ownershipApproved,
          draft: false,
        });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async downloadApk(apkId: string): Promise<string> {
      try {
        const { data } = await api.get(`/app/apk/${apkId}/download`);
        const { url } = data;
        return url;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },

    async deleteApp(appId: string): Promise<void> {
      try {
        await api.delete(`/app/${appId}`);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
  }),
};
