import React, { FunctionComponent } from 'react';
import { Resources } from 'westside-types';
import { resourcesImageIconFileTypes } from '../../constants/mappings';
import Box from '@material-ui/core/Box';
import { FieldArray } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import Input from '../UI/Input';
import ConnectedFileUploader from '../UI/ConnectedFileUploader';
import Pill from '../UI/Pill';
import { StyledDeleteIcon } from './AddResource.styles';

const handleFileIcon = (acceptType) => {
  const types = acceptType.split(',');

  if (!types.length) {
    return false;
  }

  for (let index = 0; index < types.length; index++) {
    if (resourcesImageIconFileTypes.includes(types[index]) || types[index] === '.apk') {
      return true;
    }
  }
};

// Text Field
export type TextFieldProps = {
  loading: boolean;
  label: string;
  name: string;
  options?: string[];
  textarea?: boolean;
  multiple?: boolean;
  optional?: boolean;
  fixed?: boolean;
};
export const TextField: FunctionComponent<TextFieldProps> = ({
  loading,
  label,
  name,
  textarea,
  options,
  multiple,
  optional,
  fixed,
}) => {
  const rows = textarea ? 6 : 1;

  return multiple ? (
    <FieldArray
      name={name}
      render={(arrayHelpers) =>
        options?.map((option, index) => (
          <Box key={`text-${name}-${index}`} mb={4} position="relative">
            <Input
              className="rounded"
              label={`${label} ${index + 1}`}
              type="text"
              name={`${name}.${index}`}
              disabled={loading}
              multiline={textarea}
              rows={rows}
              required={!optional}
            />
            {index > 0 && (
              <StyledDeleteIcon color="primary" onClick={() => arrayHelpers.remove(index)} />
            )}
            {index === options.length - 1 && (
              <Pill
                label="Add Another"
                IconComponent={() => <AddIcon color="primary" />}
                onClick={() => arrayHelpers.insert(index + 1, '')}
              />
            )}
          </Box>
        ))
      }
    />
  ) : (
    <Box mb={4} className={fixed ? 'fixed-input' : ''}>
      <Input
        className="rounded"
        label={label}
        type="text"
        name={name}
        disabled={loading}
        multiline={textarea}
        rows={rows}
        required={!optional}
      />
    </Box>
  );
};

// File uploader
export type UploadFieldProps = {
  url: string;
  name: string;
  onChange: (name: string, id: string) => void;
  multiple?: boolean;
  typeDescription?: string;
  accept?: string;
  options?: string | string[];
  amount?: number;
};
export const UploadField: FunctionComponent<UploadFieldProps> = ({
  url,
  name,
  multiple,
  options,
  accept,
  typeDescription,
  onChange,
}) => {
  return multiple ? (
    <FieldArray
      name={name}
      render={(arrayHelpers) =>
        typeof options === 'object' &&
        options?.map((option, index) => {
          const computedName = `${name}.${index}`;
          return (
            <Box key={`file-${name}-${index}`} mb={2} position="relative">
              {/* @ts-ignore */}
              <ConnectedFileUploader
                disableFileIcon={accept ? !handleFileIcon(accept) : true}
                useSelectedFileAsAnIcon={accept ? handleFileIcon(accept) : false}
                type={accept}
                preselectedFile={
                  option
                    ? {
                        name: option,
                        id: option,
                      }
                    : undefined
                }
                name={computedName}
                url={url}
                onChange={(file) => onChange(computedName, file.id)}
                typeDescription={typeDescription}
              />
              {index > 0 && (
                <StyledDeleteIcon color="primary" onClick={() => arrayHelpers.remove(index)} />
              )}
              {index === options.length - 1 && (
                <Pill
                  label="Add Another"
                  IconComponent={() => <AddIcon color="primary" />}
                  onClick={() => arrayHelpers.insert(index, '')}
                />
              )}
            </Box>
          );
        })
      }
    />
  ) : (
    <Box mb={2}>
      {/* @ts-ignore */}
      <ConnectedFileUploader
        disableFileIcon={accept ? !handleFileIcon(accept) : true}
        useSelectedFileAsAnIcon={accept ? handleFileIcon(accept) : false}
        preselectedFile={
          typeof options === 'string' && options
            ? {
                name: options,
                id: options,
              }
            : undefined
        }
        type={accept}
        name={name}
        url={url}
        onChange={(file) => onChange(name, file.id)}
        typeDescription={typeDescription}
      />
    </Box>
  );
};

// Document
export type DocumentFieldProps = {
  url: string;
  name: string;
  loading: boolean;
  documents: Resources.Document[];
  onChange: (name: string, id: string) => void;
};
export const DocumentField: FunctionComponent<DocumentFieldProps> = ({
  url,
  name,
  documents,
  loading,
  onChange,
}) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) =>
        documents.map((document, index) => {
          const computedFileName = `documents[${index}].fileId`;

          return (
            <Box key={`file-${name}-${index}`} mb={4} position="relative">
              {/* @ts-ignore */}
              <ConnectedFileUploader
                type=".pdf"
                name={computedFileName}
                url={url}
                onChange={(file) => onChange(computedFileName, file.id)}
                typeDescription=".PDF"
              />
              <Box mt={2}>
                <Input
                  className="rounded"
                  label={'Title'}
                  type="text"
                  name={`documents[${index}].title`}
                  disabled={loading}
                  required
                />
              </Box>
              {index > 0 && (
                <StyledDeleteIcon color="primary" onClick={() => arrayHelpers.remove(index)} />
              )}
              {index === documents.length - 1 && (
                <Pill
                  label="Add Another Document"
                  IconComponent={() => <AddIcon color="primary" />}
                  onClick={() => arrayHelpers.insert(index + 1, { fileId: '', title: '' })}
                />
              )}
            </Box>
          );
        })
      }
    />
  );
};
