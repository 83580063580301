import React, { Component } from 'react';
import { Auth } from 'westside-types';
import { connect } from 'react-redux';
import Layout from '../../components/Users/UsersPage';
import { Dispatch, iRootState } from '../../store';
import pluralize from '../../helpers/plularize-string';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  users: state.users.users,
});
const mapDispatchToProps = ({ account, users, snackbar }: Dispatch) => ({
  logOut: account.logOut,
  fetchUsers: users.fetchUsers,
  openSnackbar: snackbar.open,
  setAccountsStatus: users.setAccountsStatus,
  setActive: users.setActive,
  setSigned: users.setSigned,
  requestInviteResend: users.requestInviteResend,
});

type NotFoundProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type NotFoundState = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class NotFoundContainer extends Component<NotFoundProps, NotFoundState> {
  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  private async handleApprovalChange(
    accountIds: string[],
    status: Auth.AccountStatus,
  ): Promise<void> {
    const { setAccountsStatus, fetchUsers, openSnackbar } = this.props;
    await setAccountsStatus({ status, accountIds });
    const pluralizedUser = pluralize(accountIds.length, 'User');
    if (status === Auth.AccountStatus.APPROVED) {
      openSnackbar(`${pluralizedUser} approved`);
    } else if (status === Auth.AccountStatus.DECLINED) {
      openSnackbar(`${pluralizedUser} declined`);
    }
    await fetchUsers();
  }

  private async handleActiveChange(accountId: string, active: boolean): Promise<void> {
    const { setActive, fetchUsers, openSnackbar } = this.props;
    await setActive({ accountId, active });
    openSnackbar('Active status updated');
    await fetchUsers();
  }

  private async handleSignedChange(accountId: string, signed: boolean): Promise<void> {
    const { setSigned, fetchUsers, openSnackbar } = this.props;
    await setSigned({ accountId, signed });
    openSnackbar('Signed status updated');
    await fetchUsers();
  }

  private async handleInviteResendRequested(accountId: string): Promise<void> {
    const { requestInviteResend, openSnackbar } = this.props;
    await requestInviteResend(accountId);
    openSnackbar('Invite resent');
  }

  render() {
    const { account, logOut, users } = this.props;
    if (!users) {
      return null;
    }
    return (
      <Layout
        logOut={logOut}
        account={account}
        users={users}
        onApprovalChange={(accountIds, status) => this.handleApprovalChange(accountIds, status)}
        onInviteResendRequested={(id) => this.handleInviteResendRequested(id)}
        onActiveChange={(id, active) => this.handleActiveChange(id, active)}
        onSignedChange={(id, signed) => this.handleSignedChange(id, signed)}
      />
    );
  }
}
