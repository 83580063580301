import { Profile } from 'westside-types';

export interface ProfileState {
  personalProfile: Profile.ProfileDto | object;
  companyProfile: Profile.CompanyDto | object;
}

const initialState: ProfileState = {
  personalProfile: {},
  companyProfile: {},
};

export default initialState;
