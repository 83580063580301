import React, { FunctionComponent } from 'react';
import { AppCenterHeaderLink, LinkDescription, LinkIconContainer } from './AddButton.styles';
import AddIcon from '@material-ui/icons/Add';
import { LinkProps } from 'react-router-dom';

const AddButton: FunctionComponent<LinkProps> = (props) => (
  <AppCenterHeaderLink {...props}>
    <LinkIconContainer>
      <AddIcon fontSize="large" />
    </LinkIconContainer>
    <LinkDescription>{props.children}</LinkDescription>
  </AppCenterHeaderLink>
);

export default AddButton;
