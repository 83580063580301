import yup, { object, string, array } from 'yup';
import { Resources } from 'westside-types';
import { validationMessages } from '../constants/messages';

const { field } = validationMessages;

const validationSchema = object().shape({
  title: string().required(field.required),
  description: string().required(field.required),
  details: string().required(field.required),
  iconFileId: string().required(field.required),
  appFileId: string().required(field.required),
  videosUrls: array().of(Resources.YouTubeURL),
});

export type ResourceAppForm = yup.InferType<typeof validationSchema>;
export default validationSchema;
