import React, { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { getIn } from 'formik';
import { StyledInput } from './Input.styles';

interface InputElement {
  name: string;
  [x: string]: any;
}

const InputElement = ({ field, form: { touched, errors }, numberFormat, ...props }) => {
  const fieldError =
    (touched[field.name] || getIn(touched, field.name)) &&
    (errors[field.name] || getIn(errors, field.name));

  if (numberFormat) {
    return (
      <NumberFormat
        customInput={TextField}
        {...field}
        {...props}
        error={Boolean(fieldError)}
        helperText={fieldError}
        format={numberFormat}
      />
    );
  } else {
    return <TextField {...field} {...props} error={Boolean(fieldError)} helperText={fieldError} />;
  }
};

const FormikInput: FunctionComponent<InputElement> = (props) => (
  <StyledInput component={InputElement} {...props} variant="outlined" fullWidth />
);

export default FormikInput;
