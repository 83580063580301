import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import config from '../../../config';
import Button from '../Button';
import { Account } from '../../../store/account';
import { StyledHeader } from './AppContextHeader.styles';

interface AppContextHeader {
  account?: Account;
  avatar?: string;
  closeButtonText?: string;
  closeButtonLink?: string;
}

const AppContextHeader: FunctionComponent<AppContextHeader> = ({
  account,
  closeButtonLink,
  closeButtonText,
  avatar,
}) => {
  return (
    <StyledHeader>
      <Box display="flex" flexDirection="row" alignItems="center" height="100%">
        <Box display="flex" flexDirection="row" alignItems="center" flexGrow="1">
          {closeButtonLink && closeButtonText && (
            <Link to={closeButtonLink}>
              <Button size="large" startIcon={<CloseIcon />}>
                {closeButtonText}
              </Button>
            </Link>
          )}
        </Box>
        {account && (
          <Link to={'/profile'}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="caption">{account.name}</Typography>
              <Avatar src={avatar ? `${config.apiRootUrl}/files/${avatar}` : null} />
            </Box>
          </Link>
        )}
      </Box>
    </StyledHeader>
  );
};

export default AppContextHeader;
