import styled from 'styled-components';

export const RoleBadgeElement = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  border-radius: 40px;
  font-size: 0.7125rem;
  line-height: 1.75;
  height: 20px;
  font-weight: 800;
  margin-right: ${({ theme }) => theme.spacing() * 2}px;
  padding: 0 ${({ theme }) => theme.spacing() * 2}px;
  text-transform: uppercase;
  &.developer-button {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &.admin-button {
    border: 2px solid #16a9ca;
    color: #16a9ca;
  }
`;
