import React, { Component } from 'react';
import { connect } from 'react-redux';
import { App } from 'westside-types';
import { ScreenshotDetails, CreateAppFile } from '../../store/app';
import promiseCallbackHandler from '../../lib/promise-callback';
import { iRootState, Dispatch } from '../../store';
import { AppCombinedDetails } from '../../store/app';
import createAppInfoSchema from '../../validation/create-app-info-form';
import createAppDetailsSchema from '../../validation/create-app-details-form';

import Layout from '../../components/App/CreateApp';

const mapStateToProps = ({ app, account, profile }: iRootState) => ({
  personalProfile: profile.personalProfile,
  account,
  uploadedApkDetails: app.appDetails,
});

const mapDispatchToProps = ({ app, apk, snackbar, account }: Dispatch) => ({
  logOut: account.logOut,
  getAppRelease: app.getAppRelease,
  getApkMetadata: apk.getApkMetadata,
  uploadScreenshots: app.uploadScreenshots,
  deleteScreenshot: app.deleteScreenshot,
  submitApp: app.submitApp,
  submitDraft: app.submitDraft,
  submitRelease: app.submitRelease,
  showNotification: snackbar.open,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type CreateAppState = {
  loading: boolean;
  loadingApkMetadata: boolean;
  error: string | null;
  appReleaseDetails: AppCombinedDetails | undefined;
  uploadedScreenshots: ScreenshotDetails;
};

// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
// @ts-ignore
class CreateAppContainer extends Component<ConnectedProps, CreateAppState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
    appReleaseDetails: undefined,
    uploadedScreenshots: [],
  };

  async componentDidMount() {
    // @ts-ignore
    const { getAppRelease, match } = this.props;

    const { appId } = match.params;
    if (appId) {
      this.setState({ loading: true });
      const appReleaseDetails: AppCombinedDetails = await this.promiseCallback(
        getAppRelease({ appId }),
      );

      this.setState({
        appReleaseDetails,
        uploadedScreenshots: appReleaseDetails?.screenshotFileIds.map((screenshotId) => {
          return {
            id: screenshotId,
            name: screenshotId,
          };
        }),
      });
    }
  }

  uploadScreenshots = async (screenshots: File[]): Promise<void> => {
    const { uploadScreenshots } = this.props;
    this.setState({
      loading: true,
      loadingApkMetadata: false,
    });
    const uploadedScreenshots = await this.promiseCallback(uploadScreenshots(screenshots));

    // append to state
    this.setState({
      uploadedScreenshots: [...this.state.uploadedScreenshots, ...uploadedScreenshots],
    });
  };

  deleteScreenshot = async (deletedScreenshotIndex: number): Promise<void> => {
    const { deleteScreenshot } = this.props;
    const { uploadedScreenshots } = this.state;

    this.setState({ loading: true });
    const screenshotId = uploadedScreenshots[deletedScreenshotIndex].id;
    await this.promiseCallback(deleteScreenshot(screenshotId), 'Screenshot has been deleted');

    // remove from state
    this.setState({
      uploadedScreenshots: this.state.uploadedScreenshots.filter(
        (screenshot: CreateAppFile) => screenshot.id !== screenshotId,
      ),
    });
  };

  // TODO add typing + figure out if we can somehow combine submit methods
  submitApp = (appDetails): Promise<void> => {
    // @ts-ignore
    const { submitApp, submitDraft, submitRelease, match } = this.props;
    const { appId } = match.params;
    const redirect = '/app-center';
    this.setState({ loading: true });

    if (appId && appDetails.draft) {
      return this.promiseCallback(
        submitDraft({ appId, appDetails }),
        'Your application has been saved as a draft.',
        redirect,
      );
    } else if (appId && !appDetails.draft) {
      return this.promiseCallback(
        submitRelease({ appId, appDetails }),
        'A new release of your application has been submitted successfully!',
        redirect,
      );
    }

    return this.promiseCallback(
      submitApp(appDetails),
      'Your application has been submitted successfully!',
      redirect,
    );
  };

  getApkMetadata = async (apkFileId): Promise<App.ApkMetadata> => {
    const { getApkMetadata } = this.props;
    this.setState({ loadingApkMetadata: true });

    const apkMetadata = await this.promiseCallback(
      getApkMetadata({ apkFileId }),
      'Your form was pre-populated with apk details.',
    );

    this.setState({ loadingApkMetadata: false });
    return apkMetadata;
  };

  validateBeforeSubmitting = (
    appInfo: App.AppInfo | undefined,
    appDetails: App.AppDetails | undefined,
  ) => {
    const { showNotification } = this.props;
    const isFormValid =
      createAppInfoSchema.isValidSync(appInfo) && createAppDetailsSchema.isValidSync(appDetails);

    if (isFormValid) {
      return true;
    }

    showNotification(`Some required fields are missing - please fill them in`);
    return false;
  };

  render() {
    const {
      loading,
      loadingApkMetadata,
      error,
      appReleaseDetails,
      uploadedScreenshots,
    } = this.state;
    const {
      account,
      personalProfile,
      uploadedApkDetails,
      logOut,
      // @ts-ignore
      match,
    } = this.props;
    const { appId } = match.params;

    return (
      <Layout
        loading={loading}
        loadingApkMetadata={loadingApkMetadata}
        error={error}
        account={account}
        personalProfile={personalProfile}
        logOut={logOut}
        uploadScreenshots={this.uploadScreenshots}
        deleteScreenshot={this.deleteScreenshot}
        uploadApp={this.submitApp}
        getApkMetadata={this.getApkMetadata}
        validateBeforeSubmitting={this.validateBeforeSubmitting}
        uploadedApkDetails={uploadedApkDetails}
        uploadedScreenshotDetails={uploadedScreenshots}
        preselectedApp={appReleaseDetails}
        appId={appId}
      />
    );
  }
}

export default CreateAppContainer;
