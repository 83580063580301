export function parseQueryParams(queryString: string): { [key: string]: string[] } {
  const results: { [key: string]: string[] } = {};
  const vars = queryString.substr(1).split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    let existingValue: string[] = [];
    if (results[key]) {
      existingValue = results[key];
    }
    results[key] = [...existingValue, value];
  }
  return results;
}
