import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const StyledInfoForm = styled(Box)`
  .MuiTextField-root {
    margin-bottom: ${({ theme }) => theme.spacing() * 3}px;
    &:last-child {
      margin-top: 0;
    }
  }
  .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
  .MuiTypography-body1 {
    font-size: 14px;
    text-transform: uppercase;
  }
`;
