import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  &.MuiButton-root.MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
  }

  &.MuiButton-root.MuiButton-containedSecondary {
    background-color: #cf383c;
    color: #fff;
  }

  &.MuiButton-root .MuiButton-label {
    text-transform: uppercase;
  }

  &.MuiButton-root:disabled {
    background-color: #cdd3d9;
  }
`;
