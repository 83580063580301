export interface ErrorMessageSimple {
  message: string;
  [key: string]: string;
}

export interface ErrorMessageComplex {
  message: string;
  errors?: {
    [key: string]: Array<string>;
  };
}

export default (error: ErrorMessageSimple | ErrorMessageComplex): Error => {
  /*
    For an error response like:
    {
      "message": "422 Unprocessable Entity",
      "errors": {
        "email": [
          "The email must be a valid email address."
        ]
      }
    }
   */
  if (error?.errors) {
    let errors = '';

    for (let [key, value] of Object.entries(error.errors)) {
      errors += `${key} - ${value.join(', ')}`;
    }
    return Error(errors);
  }

  /*
    For an error response like:
    {
      "error": {
        "message": "403 Forbidden",
        "status_code": 403
      }
    }
   */
  if (error?.message) {
    return Error(error.message);
  }

  if (error instanceof Error) {
    return error;
  }

  return new Error('An unknown error occured');
};
