import formatErrorMessage from '../lib/format-error-messages';
import { Faq } from 'westside-types';
import { Dispatch } from '../store';
import api from '../lib/api';

export default {
  namespace: 'faq',
  state: null,
  reducers: {
    nothing(state) {
      return state;
    },
  },
  effects: (dispatch: Dispatch) => ({
    async getFaqs(): Promise<Faq.FaqListDto> {
      try {
        const { data } = await api.get('/faq');
        return data;
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
    async setFaqs(data: Faq.FaqListDto) {
      try {
        await api.post('/faq', data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
  }),
};
