import styled from 'styled-components';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Tooltip from '@material-ui/core/Tooltip';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export const StyledExpansionPanel = styled(ExpansionPanel)`
  box-shadow: none;
  &::before {
    display: none;
  }
  &.Mui-expanded {
    margin: 0;
  }
`;

export const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0;

  &.Mui-expanded {
    min-height: 0;
  }

  .MuiExpansionPanelSummary-content {
    line-height: 32px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    margin: ${({ theme }) => theme.spacing() * 2}px 0;
  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: ${({ theme }) => theme.spacing() * 2}px 0;
  }
`;

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
`;

export const StyledTooltip = styled(Tooltip)`
  margin: 0 ${({ theme }) => theme.spacing()}px;
  color: #778899;
`;
