import initialState, { UsersState } from '../store/users';
import { Dispatch } from '../store';
import formatErrorMessage from '../lib/format-error-messages';
import { Auth } from 'westside-types';
import api from '../lib/api';

export default {
  namespace: 'users',

  state: initialState,

  reducers: {
    setUsers(state: UsersState, users: Auth.AccountOverview[]) {
      return {
        ...state,
        users,
      };
    },
  },

  effects: (dispatch: Dispatch) => ({
    async fetchUsers(): Promise<void> {
      try {
        const params = new URLSearchParams();
        params.append('status', Auth.AccountStatus.PENDING);
        params.append('status', Auth.AccountStatus.APPROVED);
        const { data } = await api.get('/account/list', {
          params,
        });
        dispatch.users.setUsers(data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },

    async setAccountsStatus(data: Auth.UpdateAccountStatus) {
      try {
        await api.post('/account/status', data);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },

    async setActive({ accountId, active }: { accountId: string; active: boolean }) {
      try {
        await api.post(`/account/${accountId}/active`, { active });
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },

    async setSigned({ accountId, signed }: { accountId: string; signed: boolean }) {
      try {
        await api.post(`/account/${accountId}/signed`, { signed });
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },

    async requestInviteResend(accountId: string): Promise<void> {
      try {
        await api.post(`/account/${accountId}/resend-invite`);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
  }),
};
