import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Profile/EditCompanyProfile';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  profile: state.profile.companyProfile,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  loadCompany: dispatch.profile.loadCompany,
  updateCompany: dispatch.profile.updateCompany,
});

type ProfileEditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type ProfileEditState = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class CompanyProfileEdit extends Component<ProfileEditProps, ProfileEditState> {
  state = {
    profile: null,
  };

  componentDidMount() {
    const { loadCompany } = this.props;
    loadCompany();
  }

  render() {
    const { account, logOut, profile, updateCompany } = this.props;
    return (
      <Layout
        account={account}
        logOut={logOut}
        profile={profile}
        onProfileUpdate={(data) => {
          updateCompany(data);
          this.props.history.push('/profile');
        }}
      />
    );
  }
}
