import React, { FunctionComponent, useState } from 'react';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { App, Profile } from 'westside-types';
import config from '../../config';
import strings from '../../constants/strings';
import { AppCenterActions } from '../../types/app-center';
import { Account } from '../../store/account';
import Header from '../UI/Header';
import Pill from '../UI/Pill';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { MainSidebarMenu } from '../UI/Menu';
import { PageContainer, PageLayout } from '../UI/Layouts';
import AppCenterPreviewHeader from './AppCenterPreviewHeader';
import AppActions from './AppCenterRow';
import {
  StyledImageIcon,
  StyledVersionHistoryRow,
  StyledScreenshot,
  StyledTypography,
  StyledDetailsContainer,
} from './AppCenterPreview.styles';

interface AppCenter {
  loading: boolean;
  account: Account;
  companyProfile: Profile.CompanyDto;
  personalProfile: Profile.ProfileDto;
  appId: string;
  appReleaseDetails: App.AppDto | undefined;
  logOut: () => void;
  downloadApk: (apkFileId: string) => void;
  deleteApp: (appId: string) => void;
  disableApp: (appId: string) => void;
  enableApp: (appId: string) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const AppPreviewComponent: FunctionComponent<AppCenter> = ({
  account,
  companyProfile,
  personalProfile,
  appId,
  loading,
  appReleaseDetails,
  logOut,
  downloadApk,
  deleteApp,
  enableApp,
  disableApp,
}) => {
  const imageBaseSrc = `${config.apiRootUrl}/files`;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { DRAFT, IN_REVIEW, PENDING, APPROVED, REJECTED } = App.AppComputedState;
  const { REVIEW, DELETE, DOWNLOAD, TOGGLE, EDIT } = AppCenterActions;
  const actions: {
    [key: string]: string[];
  } = {
    [REJECTED]: [],
    [DRAFT]: [EDIT],
    [PENDING]: [REVIEW, EDIT, DELETE, DOWNLOAD],
    [IN_REVIEW]: [DELETE, DOWNLOAD, EDIT, TOGGLE],
    [APPROVED]: [DELETE, DOWNLOAD, EDIT, TOGGLE],
  };

  const handleToggleApp = (state: boolean, appId: string): void => {
    if (state) {
      enableApp(appId);
      return;
    }

    disableApp(appId);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
        {value === index && <Box mt={4}>{children}</Box>}
      </div>
    );
  };

  return (
    <PageContainer sticky>
      <Header logOut={logOut} account={account} />
      <Box flex="1" display="flex" flexDirection="row" overflow="hidden">
        <Sidebar
          logoType={account.role === 'ADMIN' ? LogoType.LOGO : LogoType.AVATAR}
          logo={
            account.role === 'ADMIN' ? companyProfile?.logoFileId : personalProfile?.avatarFileId
          }
        >
          <MainSidebarMenu userRole={account.role} />
        </Sidebar>
        <Box flex="1" display="flex" justifyContent="center">
          <PageLayout className="with-sidebar" sticky>
            <AppCenterPreviewHeader loading={loading} />
            {appReleaseDetails && (
              <>
                <Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box mr={2}>
                      <StyledImageIcon
                        src={`${imageBaseSrc}/${appReleaseDetails.iconFileId}`}
                        alt="app-icon"
                      />
                    </Box>
                    <StyledTypography>{appReleaseDetails.name}</StyledTypography>
                    <Box flex="1" />
                    <Pill
                      label={strings[appReleaseDetails.computedState]}
                      computedState={appReleaseDetails.computedState}
                      contained
                    />
                    <AppActions
                      id={appId}
                      disabled={appReleaseDetails.disabled}
                      actions={actions[APPROVED]}
                      downloadApk={() => downloadApk(appReleaseDetails.apkFileId)}
                      onDelete={() => deleteApp(appId)}
                      onToggleApp={(state: boolean) => handleToggleApp(state, appId)}
                      disableStyles
                      isAppOwner={Boolean(appReleaseDetails?.ownerId === account.id)}
                    />
                  </Box>
                </Box>
                <Box mt={3} display="flex" flexDirection="row" justifyContent="space-between">
                  <Box>
                    <StyledTypography variant="body2">Name</StyledTypography>
                    <StyledTypography>{appReleaseDetails.name}</StyledTypography>
                  </Box>
                  <Box>
                    <StyledTypography variant="body2">Version</StyledTypography>
                    <StyledTypography>{appReleaseDetails.version}</StyledTypography>
                  </Box>
                  {!!appReleaseDetails.category && (
                    <Box>
                      <StyledTypography variant="body2">Category</StyledTypography>
                      <StyledTypography>{appReleaseDetails.category}</StyledTypography>
                    </Box>
                  )}
                  {appReleaseDetails.price !== null && (
                    <Box>
                      <StyledTypography variant="body2">Price</StyledTypography>
                      <StyledTypography>
                        ${parseFloat(String(appReleaseDetails.price)).toFixed(2)}
                      </StyledTypography>
                    </Box>
                  )}
                  {appReleaseDetails.price !== null && (
                    <Box>
                      <StyledTypography variant="body2" className="text-center">
                        Type
                      </StyledTypography>
                      <Box>
                        <Pill small label={appReleaseDetails.price ? 'Paid' : 'Free'} contained />
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <StyledTypography variant="body2">API Level</StyledTypography>
                    <StyledTypography>{appReleaseDetails.apiLevel}</StyledTypography>
                  </Box>
                </Box>
                <StyledDetailsContainer mt={4} flex="1">
                  <AppBar position="static" elevation={0}>
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Overview" />
                      <Tab label="Version History" />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={selectedTab} index={0}>
                    <StyledTypography variant="body2">
                      Platform Science Marketplace URL
                    </StyledTypography>
                    <a href="http://myawesomeurl.com">
                      <StyledTypography color="secondary" variant="caption">
                        http://myawesomeurl.com
                      </StyledTypography>
                    </a>
                    {!!appReleaseDetails.description && (
                      <Box mt={4}>
                        <StyledTypography variant="body2">Description</StyledTypography>
                        <StyledTypography variant="caption">
                          {appReleaseDetails.description}
                        </StyledTypography>
                      </Box>
                    )}
                    <Box mt={4} display="flex" flexDirection="row" justifyContent="space-between">
                      <Box>
                        <StyledTypography variant="body2">Production Fingerprint</StyledTypography>
                        <StyledTypography variant="caption">
                          {appReleaseDetails.fingerprint}
                        </StyledTypography>
                      </Box>
                      <Box>
                        <StyledTypography variant="body2">Website URL</StyledTypography>
                        <a href="http://myawesomeurl.com">
                          <StyledTypography color="secondary" variant="caption">
                            http://myawesomeurl.com
                          </StyledTypography>
                        </a>
                      </Box>
                      {!!appReleaseDetails.dataUsage && (
                        <Box>
                          <StyledTypography variant="body2">Data Usage</StyledTypography>
                          <StyledTypography variant="caption">
                            {appReleaseDetails.dataUsage}
                          </StyledTypography>
                        </Box>
                      )}
                    </Box>
                    {!!appReleaseDetails.fleetConsiderationsAndRequirements && (
                      <Box mt={4}>
                        <StyledTypography variant="body2">
                          Fleet Consideration &amp; Requirements
                        </StyledTypography>
                        <StyledTypography variant="caption">
                          {appReleaseDetails.fleetConsiderationsAndRequirements}
                        </StyledTypography>
                      </Box>
                    )}
                    {!!appReleaseDetails.releaseNotes && (
                      <Box mt={4}>
                        <StyledTypography variant="body2">Release Notes</StyledTypography>
                        <StyledTypography variant="caption">
                          {appReleaseDetails.releaseNotes}
                        </StyledTypography>
                      </Box>
                    )}
                    {appReleaseDetails.screenshotFileIds &&
                      appReleaseDetails.screenshotFileIds.length > 0 && (
                        <Box mt={4}>
                          <StyledTypography variant="body2">Screenshots</StyledTypography>
                          <StyledTypography variant="caption">
                            {appReleaseDetails.screenshotFileIds.map((screenshotId) => (
                              <Box mr={2} display="inline">
                                <StyledScreenshot
                                  src={`${imageBaseSrc}/${screenshotId}`}
                                  alt="app-screenshot"
                                />
                              </Box>
                            ))}
                          </StyledTypography>
                        </Box>
                      )}
                  </TabPanel>
                  <TabPanel value={selectedTab} index={1}>
                    <Box display="flex" flexDirection="row" mb={2}>
                      <Box flex="1">
                        <StyledTypography variant="body2">App Versions</StyledTypography>
                      </Box>
                      <Box flex="1">
                        <StyledTypography variant="body2">Release Notes</StyledTypography>
                      </Box>
                    </Box>

                    {appReleaseDetails.releases.map((release) => (
                      <StyledVersionHistoryRow display="flex" flexDirection="row">
                        <Box flex="1">
                          <StyledTypography variant="subtitle1">
                            {appReleaseDetails.name} {release.version}
                          </StyledTypography>
                        </Box>
                        <Box flex="1">
                          {release.state === 'REJECTED' && (
                            <Box mb={2}>
                              <StyledTypography className="rejected" variant="caption">
                                App Rejected
                              </StyledTypography>
                            </Box>
                          )}
                          <Box>
                            <StyledTypography variant="caption">
                              {release.state === 'REJECTED'
                                ? release.rejectReason
                                : release.releaseNotes}
                            </StyledTypography>
                          </Box>
                        </Box>
                      </StyledVersionHistoryRow>
                    ))}
                  </TabPanel>
                </StyledDetailsContainer>
              </>
            )}
          </PageLayout>
        </Box>
      </Box>
      <Footer>
        <FooterLink to={'/'}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default AppPreviewComponent;
