import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasActiveAuthToken } from '../lib/jwt';
import { isUserSigned } from '../helpers/user-signed-guard';

const PrivateRoute = ({ account, openForUnsignedUsers, ...rest }: any) => {
  if (!hasActiveAuthToken() || !account.login) {
    return <Redirect to="/login" />;
  }
  if (!openForUnsignedUsers && !isUserSigned()) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
};

const mapStateToProps = ({ account }) => ({ account });
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
