import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

export const StyledTile = styled(Box)`
  position: relative;
  box-shadow: 0px 0px 16px rgba(39, 170, 225, 0.23);
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing() * 2}px;
  height: 249px;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .MuiTypography-subtitle2 {
    letter-spacing: 0.25em;
    font-weight: 600;
    line-height: 22px;
  }
  &:hover {
    background-color: rgba(43, 100, 203, 0.1);
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  top: ${({ theme }) => theme.spacing()}px;
  right: ${({ theme }) => theme.spacing()}px;
`;

export const StyledLink = styled(Link)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing() * 2}px;
  .MuiTypography-root,
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
