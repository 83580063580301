import React, { FunctionComponent } from 'react';
import { Account } from '../../store/account';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import Box from '@material-ui/core/Box';
import { Sidebar } from '../UI/Sidebar/Sidebar';
import { ProfileSidebarMenu } from '../UI/Menu';
import ContentHeader from '../UI/Header/ContentHeader';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { IconContainer, NotificationDot, StyledButton, StyledTypography } from './List.styles';
import { Notification } from 'westside-types';
import Duration from '../../helpers/duration';
import { Typography } from '@material-ui/core';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';

type NotificationsListProps = {
  account: Account;
  logOut: () => void;
  hasMore: boolean;
  onLoadMore: (e: MouseEvent) => void;
  notifications: Notification.NotificationPreview[];
};

function getColor(acknowledged: boolean, type: Notification.NotificationType): string | null {
  if (acknowledged) {
    return null;
  }
  return {
    [Notification.NotificationType.WARNING]: 'red',
    [Notification.NotificationType.INFO]: 'blue',
  }[type];
}

const NotificationsList: FunctionComponent<NotificationsListProps> = ({
  account,
  logOut,
  notifications,
  hasMore,
  onLoadMore,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <Box flex="1" display="flex" flexDirection="row">
      <Sidebar title="My Account">
        <ProfileSidebarMenu />
      </Sidebar>
      <Box flex="1" display="flex" justifyContent="center">
        <PageLayout className="with-sidebar">
          <Box height="100%" mt={4} mb={4} borderRadius={1}>
            <ContentHeader
              title="Notifications"
              icon={<FeedbackIcon fontSize="large" color="primary" />}
            />
            {notifications.map(({ title, description, acknowledged, type, time }) => (
              <Box pt={2} pb={2} display="flex" flexDirection="row">
                <NotificationDot className={getColor(acknowledged, type)} />
                <Box flex={1}>
                  <StyledTypography className={getColor(acknowledged, type)} variant="h6">
                    {title}
                  </StyledTypography>
                  <StyledTypography className={getColor(acknowledged, type)} variant="body1">
                    {description}
                  </StyledTypography>
                </Box>
                <Box>
                  <StyledTypography className={getColor(acknowledged, type)} variant="body2">
                    <Duration date={time} />
                  </StyledTypography>
                </Box>
              </Box>
            ))}
            {notifications.length <= 0 && (
              <Box mt={12} mb={12} display="flex" alignItems="center" flexDirection="column">
                <IconContainer mb={1}>
                  <SpeakerNotesOffIcon color="primary" fontSize="large" />
                </IconContainer>
                <Typography variant="h5" color="primary">
                  No Notifications!
                </Typography>
              </Box>
            )}
            {hasMore && (
              <Box mt={2} display="flex" justifyContent="center">
                <StyledButton
                  type="button"
                  disableElevation
                  color="primary"
                  variant="outlined"
                  onClick={onLoadMore}
                >
                  Load More
                </StyledButton>
              </Box>
            )}
          </Box>
        </PageLayout>
      </Box>
    </Box>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default NotificationsList;
