import React, { Component } from 'react';
import { connect } from 'react-redux';
import { iRootState, Dispatch } from '../../store';
import { Auth } from 'westside-types';
import promiseCallbackHandler from '../../lib/promise-callback';
import Layout from '../../components/Auth/ForgotPassword';

const mapStateToProps = (state: iRootState) => ({});
const mapDispatchToProps = ({ account, snackbar }: Dispatch) => ({
  resetPassword: account.resetPassword,
  showNotification: snackbar.open,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface ForgotPassword {
  loading: boolean;
  error: string | null;
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class ForgotPasswordContainer extends Component<ConnectedProps, ForgotPassword> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    formSubmitted: false,
    error: null,
  };

  resetPassword = async (email: Auth.ForgotPasswordDto) => {
    const { resetPassword } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(
      resetPassword({ email }),
      'Email with reset password has been sent',
      '/login',
    );
  };

  render() {
    const { loading, error } = this.state;

    return <Layout loading={loading} error={error} resetPassword={this.resetPassword} />;
  }
}
// @ts-ignore
export default ForgotPasswordContainer;
