import React, { FunctionComponent } from 'react';
import store, { iRootState } from '../store';

export const isUserSigned = () => {
  const state: iRootState = store.getState();
  const isUserSigned = state.profile.personalProfile?.signed;
  const isUserAdmin = state.profile.personalProfile?.role === 'ADMIN';
  return isUserSigned || isUserAdmin;
};

export const UserSignedGuard: FunctionComponent = ({ children }) =>
  isUserSigned() ? <>{children}</> : <div style={{ pointerEvents: 'none' }}>{children}</div>;
