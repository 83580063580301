import React, { FunctionComponent, useState } from 'react';
import { Resources, Profile } from 'westside-types';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AppsIcon from '@material-ui/icons/Apps';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import LaunchIcon from '@material-ui/icons/Launch';
import Description from '@material-ui/icons/Description';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import DevicesOther from '@material-ui/icons/DevicesOther';
import { Account } from '../../store/account';
import { ResourceType, ResourceActions, ResourceSection } from '../../types/resources';
import strings from '../../constants/strings';
import config from '../../config';
import Header from '../UI/Header';
import Button from '../UI/Button';
import Tile from '../UI/Tile';
import Dialog from '../UI/Dialog';
import AppContextHeader from '../UI/Header/AppContextHeader';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { MainSidebarMenu } from '../UI/Menu';
import { PageContainer, PageLayout, PageHeader } from '../UI/Layouts';
import {
  StyledIcon,
  StyledTileWrapper,
  StyledDeleteIcon,
  StyledPDFIcons,
  StyledDescriptionWrapper,
} from './Resources.styles';
import resourcesDescriptions from '../../constants/resources-descriptions';

interface Resources {
  loading: boolean;
  account: Account;
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
  resources: Resources.ResourceListEntry[];
  documents: Resources.Document[];
  logOut: () => void;
  deleteResource: (id: string) => void;
  deleteDocument: (id: string) => void;
  downloadFile: (id: string) => void;
  openPdf: (id: string) => void;
}

const ResourcesComponent: FunctionComponent<Resources> = ({
  loading,
  logOut,
  deleteResource,
  deleteDocument,
  downloadFile,
  openPdf,
  account,
  personalProfile,
  companyProfile,
  resources,
  documents,
}) => {
  const [showResourceDeleteDialog, setShowResourceDeleteDialog] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<{ id: string; type: ResourceType }>('');
  const { PS_APP, SAMPLE_APP, DOCUMENTATION, SIMULATOR, TUTORIAL } = ResourceType;
  const { ADD } = ResourceActions;
  const filteredResources: {
    [key: string]: { id: string; title: string; iconFileId: string }[];
  } = {
    [PS_APP]: [],
    [SAMPLE_APP]: [],
    [DOCUMENTATION]: [],
    [SIMULATOR]: [],
    [TUTORIAL]: [],
  };
  const actions = (type: ResourceType) => {
    return account.role === 'ADMIN'
      ? [
          {
            label: ADD,
            link: `/resources/add/${type}`,
          },
        ]
      : [];
  };

  const sections: ResourceSection[] = [
    {
      type: PS_APP,
      actions: actions(PS_APP),
    },
    {
      type: SAMPLE_APP,
      actions: actions(SAMPLE_APP),
      Icon: ({ color }: { color: string }) => <AppsIcon color={color} />,
    },
    {
      type: SIMULATOR,
      actions: actions(SIMULATOR),
      Icon: ({ color }: { color: string }) => <DevicesOther color={color} />,
    },
    {
      type: TUTORIAL,
      actions: actions(TUTORIAL),
      Icon: ({ color }: { color: string }) => <LocalLibrary color={color} />,
    },
    {
      type: DOCUMENTATION,
      actions: actions(DOCUMENTATION),
      Icon: ({ color }: { color: string }) => <Description color={color} />,
    },
  ];

  const handleDeleteIconClick = (id: string, type: ResourceType): void => {
    setSelectedResource({ id, type });
    setShowResourceDeleteDialog(true);
  };

  if (resources) {
    resources.forEach((resource) => {
      filteredResources[resource.type].push(resource);
    });
  }

  if (documents) {
    documents.forEach((document) => {
      filteredResources[DOCUMENTATION].push(document);
    });
  }

  const DeleteIcon = (id: string, resourceType: ResourceType) => {
    return (
      account.role === 'ADMIN' && (
        <StyledDeleteIcon
          color="secondary"
          onClick={() => handleDeleteIconClick(id, resourceType)}
        />
      )
    );
  };

  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <Box flex="1" display="flex" flexDirection="row">
        <Sidebar
          logoType={account.role === 'ADMIN' ? LogoType.LOGO : LogoType.AVATAR}
          logo={
            account.role === 'ADMIN' ? companyProfile?.logoFileId : personalProfile?.avatarFileId
          }
        >
          <MainSidebarMenu userRole={account.role} />
        </Sidebar>
        <Box flex="1" display="flex" justifyContent="center" flexDirection="column">
          <AppContextHeader account={account} avatar={personalProfile?.avatarFileId} />
          <PageLayout className="with-sidebar">
            <PageHeader
              title="Resources"
              Icon={() => <BookmarksIcon fontSize="large" color="primary" />}
              loading={loading}
            />
            <Box>
              {sections.map(({ type, actions, Icon }, index) => {
                return (
                  <Box key={`r-section-${index}`} mb={8}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      {Icon ? (
                        <Icon color="primary" />
                      ) : (
                        <StyledIcon src={require('../../assets/logo-s.svg')} alt="ps-apps" />
                      )}
                      <Box ml={1}>
                        <Typography variant="subtitle2">{strings[type]}</Typography>
                      </Box>
                      <Box flex="1" />
                      {actions.map((action) => (
                        <Link to={action.link}>
                          <Button variant="contained" color="primary">
                            {strings[action.label]}
                          </Button>
                        </Link>
                      ))}
                    </Box>
                    {resourcesDescriptions[type] && (
                      <StyledDescriptionWrapper mt={1}>
                        <Typography variant="body2">
                          {resourcesDescriptions[type].subTitle}
                        </Typography>
                        <Typography variant="caption">
                          {resourcesDescriptions[type].description}
                        </Typography>
                      </StyledDescriptionWrapper>
                    )}
                    <StyledTileWrapper mt={4} display="flex" flexDirection="row" flexWrap="wrap">
                      {filteredResources[type].length > 0 &&
                        filteredResources[type].map(({ title, id, fileId, iconFileId }) => {
                          return type === DOCUMENTATION ? (
                            <Tile
                              title={title}
                              flexBasis="250px"
                              IconComponent={() => (
                                <PictureAsPdf color="secondary" fontSize="large" />
                              )}
                            >
                              {DeleteIcon(fileId, type)}
                              <StyledPDFIcons display="inline-flex" flexDirection="row">
                                <IconButton onClick={() => downloadFile(fileId)}>
                                  <GetAppIcon color="secondary" />
                                </IconButton>
                                <IconButton onClick={() => openPdf(fileId)}>
                                  <LaunchIcon color="secondary" />
                                </IconButton>
                              </StyledPDFIcons>
                            </Tile>
                          ) : (
                            <Tile
                              title={title}
                              link={`/resources/detail/${id}`}
                              flexBasis="250px"
                              IconComponent={() => (
                                <StyledIcon src={`${config.apiRootUrl}/files/${iconFileId}`} />
                              )}
                            >
                              {DeleteIcon(id, type)}
                            </Tile>
                          );
                        })}
                    </StyledTileWrapper>
                  </Box>
                );
              })}
            </Box>
          </PageLayout>
        </Box>
      </Box>
      <Dialog
        open={showResourceDeleteDialog}
        onCancel={() => setShowResourceDeleteDialog(false)}
        onConfirm={() => {
          const { id, type } = selectedResource;
          type === DOCUMENTATION ? deleteDocument(id) : deleteResource(id);
          deleteResource(id);
          setShowResourceDeleteDialog(false);
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        title="Are you sure you want to delete this resource?"
      />
      <Footer>
        <FooterLink to={'/'}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default ResourcesComponent;
