import React, { FunctionComponent } from 'react';
import { SigningAgreementStatus } from '../../store/agreement';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import { SigningButtonWrapper, StyledCircularProgress } from './SigningButton.styles';
import DoneIcon from '@material-ui/icons/Done';

type SigningButtonProps = {
  onSigningRequested: () => void;
  status: SigningAgreementStatus;
};

const SigningButton: FunctionComponent<SigningButtonProps> = ({ onSigningRequested, status }) => (
  <SigningButtonWrapper>
    {status === SigningAgreementStatus.NOT_SIGNED && (
      <IconButton onClick={() => onSigningRequested()}>
        <CreateIcon color="secondary" />
      </IconButton>
    )}
    {(status === SigningAgreementStatus.IN_PROGRESS ||
      status === SigningAgreementStatus.PROCESSING) && (
      <StyledCircularProgress color="secondary" size={48} />
    )}
    {status === SigningAgreementStatus.SIGNED && (
      <div className="signed">
        <DoneIcon />
      </div>
    )}
  </SigningButtonWrapper>
);

export default SigningButton;
