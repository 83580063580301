import React, { FunctionComponent } from 'react';
import { Account } from '../../store/account';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import Box from '@material-ui/core/Box';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import AppContextHeader from '../UI/Header/AppContextHeader';
import { Profile } from 'westside-types';
import CompanyProfileEditor from './CompanyProfileEditor';

type EditCompanyProfileProps = {
  logOut: () => void;
  account: Account;
  profile: Profile.CompanyDto | null;
  onProfileUpdate: (data: Profile.CompanyDto) => void;
};

const EditCompanyProfile: FunctionComponent<EditCompanyProfileProps> = ({
  logOut,
  account,
  profile,
  onProfileUpdate,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <AppContextHeader
      avatar={profile?.logoFileId}
      account={account}
      closeButtonText={'Edit Company Information'}
      closeButtonLink="/profile"
    />
    <PageLayout>
      <Box height="100%" mt={4} mb={4} borderRadius={1}>
        {profile && (
          <CompanyProfileEditor
            initialData={profile}
            onDataChange={onProfileUpdate}
            cancelLink="/profile"
          />
        )}
      </Box>
    </PageLayout>
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default EditCompanyProfile;
