import styled from 'styled-components';
import { Box, Button, Typography } from '@material-ui/core';

const blue = ({ theme }) => theme.palette.primary.light;
const red = () => '#cf383c';

export const NotificationDot = styled.div`
  line-height: 1.3;
  margin-right: ${({ theme }) => theme.spacing() * 2}px;

  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: ${({ highlighted, theme }) =>
      highlighted ? theme.palette.primary.main : theme.palette.tertiary.light};
    display: inline-block;
    vertical-align: middle;
  }

  &.blue::before {
    background: ${({ theme }) => blue({ theme })};
  }

  &.red::before {
    background: ${() => red()};
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 40px;
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-h6 {
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.tertiary.main};

    &.blue {
      color: ${({ theme }) => blue({ theme })};
    }

    &.red {
      color: ${() => red()};
    }
  }

  &.MuiTypography-body1 {
    font-weight: normal;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.tertiary.light};
  }

  &.MuiTypography-body2 {
    font-size: 10px;

    &.red,
    &.blue {
      color: ${({ theme }) => theme.palette.tertiary.light};
    }
  }
`;

export const IconContainer = styled(Box)`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};

  .MuiSvgIcon-root {
    width: 45px;
    height: 45px;
  }
`;
