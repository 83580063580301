import { Auth } from 'westside-types';

export interface Account {
  id: string;
  token: string;
  login: string;
  name: string;
  role: Auth.Role;
}

const initialState: Account = {
  id: '',
  login: '',
  token: '',
  name: '',
  role: 'USER',
};

export default initialState;
