import React, { FunctionComponent } from 'react';
import { BrandContainer, FooterContainer, FooterLinkContainer, StyledLink } from './Footer.styles';

export const Footer: FunctionComponent = ({ children }) => (
  <FooterContainer>
    <BrandContainer>
      <img src={require('../../../assets/logo-s.svg')} alt="Platform Science" />
    </BrandContainer>
    <FooterLinkContainer separator={false}>
      Copyright &copy; 2020 Platform Science All Rights Reserved
    </FooterLinkContainer>
    {children}
  </FooterContainer>
);

declare type FooterLinkProps = {
  to: string | null;
};
export const FooterLink: FunctionComponent<FooterLinkProps> = ({ to, children }) => (
  <FooterLinkContainer separator={true}>
    <StyledLink to={to}>{children}</StyledLink>
  </FooterLinkContainer>
);
