import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasActiveAuthToken } from '../lib/jwt';

const PublicRoute = ({ path, account, ...rest }: any) => {
  if (hasActiveAuthToken() && account.login && path === '/login') {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
};

const mapStateToProps = ({ account }) => ({ account });
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
