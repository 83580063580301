import React, { FunctionComponent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { iRootState } from '../../../store';

interface SnackBarProps {
  open: boolean;
  message: string;
  closeSnackBar: () => void;
}

const mapStateToProps = (state: iRootState) => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
});
const mapDispatchToProps = (dispatch) => ({
  closeSnackBar: dispatch.snackbar.close,
});

const SnackBar: FunctionComponent<SnackBarProps> = ({ message, open, closeSnackBar }) => {
  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      message={message}
      onClose={closeSnackBar}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
