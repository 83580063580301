import React, { FunctionComponent } from 'react';
import { TilesContainer } from './Tiles.styles';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Tile from '../UI/Tile';
import { AgreementsIcon } from '../UI/Icon/Agreements';
import { UsersIcon } from '../UI/Icon/Users';

export interface TilesProps {
  resourcesVisible: boolean;
  agreementsVisible: boolean;
  faqVisible: boolean;
  usersVisible: boolean;
}

function getVisibleCount(props: TilesProps) {
  return Object.keys(props)
    .map((key) => props[key])
    .filter((a) => a === true).length;
}

function getFlexBasisValue(props: TilesProps) {
  const visibleCount = getVisibleCount(props);
  return `${100 / visibleCount}%`;
}

const Tiles: FunctionComponent<TilesProps> = (props) => (
  <TilesContainer>
    {props.resourcesVisible && (
      <Tile
        title="Resources"
        link="/resources"
        flexBasis={getFlexBasisValue(props)}
        IconComponent={() => <BookmarksIcon fontSize="large" color="primary" />}
      />
    )}
    {props.agreementsVisible && (
      <Tile
        title="Agreements"
        link="/agreements"
        flexBasis={getFlexBasisValue(props)}
        IconComponent={() => <AgreementsIcon fontSize="large" color="primary" />}
      />
    )}
    {props.faqVisible && (
      <Tile
        title="FAQ's"
        link="/faq"
        flexBasis={getFlexBasisValue(props)}
        IconComponent={() => <ContactSupportIcon fontSize="large" color="primary" />}
      />
    )}
    {props.usersVisible && (
      <Tile
        title="Users"
        link="/users"
        flexBasis={getFlexBasisValue(props)}
        IconComponent={() => <UsersIcon fontSize="large" color="primary" />}
      />
    )}
  </TilesContainer>
);

export default Tiles;
