import React, { Component } from 'react';
import { connect } from 'react-redux';
import { iRootState, Dispatch } from '../../store';
import { Auth } from 'westside-types';
import promiseCallbackHandler from '../../lib/promise-callback';
import Layout from '../../components/Auth/Login';

const mapStateToProps = (state: iRootState) => ({ account: state.account });
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logIn: dispatch.account.logIn,
  showNotification: dispatch.snackbar.open,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface LoginState {
  loading: boolean;
  error: string | null;
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class LoginContainer extends Component<ConnectedProps, LoginState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
  };

  logIn = async (credentials: Auth.CredentialsDto) => {
    const { logIn } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(logIn(credentials));
  };

  render() {
    const { loading, error } = this.state;
    const { account } = this.props;

    return <Layout loading={loading} error={error} logIn={this.logIn} account={account} />;
  }
}
// @ts-ignore
export default LoginContainer;
