import React, { FunctionComponent, MouseEvent } from 'react';
import { CircularProgress } from '@material-ui/core';
import { StyledButton } from './Button.styles';

interface ButtonElement {
  isPending?: boolean;
  onClick?: (param: MouseEvent) => void;
  [x: string]: any;
}

const ButtonElement: FunctionComponent<ButtonElement> = ({ isPending, children, ...props }) => {
  return (
    <StyledButton {...props} disableElevation>
      {isPending ? <CircularProgress size={24} /> : children}
    </StyledButton>
  );
};

export default ButtonElement;
