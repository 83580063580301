import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  background-color: ${({ theme, altColor }) => {
    switch (altColor) {
      case 'light':
        return theme.palette.primary.light;
      case 'success':
        return theme.palette.success.main;
    }
  }};
  color: ${({ theme, altColor }) => {
    switch (altColor) {
      case 'light':
        return theme.palette.secondary.light;
      case 'success':
        return theme.palette.secondary.light;
    }
  }};

  &:hover {
    background-color: ${({ theme, altColor }) => {
      switch (altColor) {
        case 'light':
          return theme.palette.primary.light;
        case 'success':
          return theme.palette.success.main;
      }
    }};
    filter: brightness(1.1);
  }
`;
