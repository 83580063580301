import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Pill from '../UI/Pill';

interface AppCenterPreviewHeaderProps {
  loading?: boolean;
}

const AppCenterPreviewHeader: FunctionComponent<AppCenterPreviewHeaderProps> = ({
  loading,
}) => (
  <Box display="flex" mt={4} mb={4} flexDirection="row" alignItems="center">
    <Box display="flex" flexDirection="row" alignItems="center" flex="1">
      <Link to="/app-center">
        <Pill IconComponent={() => <ArrowBackIcon />} label="Back" />
      </Link>
      <Box ml={2}>{loading && <CircularProgress color="secondary" size={20} />}</Box>
    </Box>
  </Box>
);

export default AppCenterPreviewHeader;
