import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';

export const StyledIcon = styled.img`
  width: 26px;
  height: auto;
`;

export const StyledHeaderIcon = styled.img`
  width: 200px;
  height: 200px;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  top: ${({ theme }) => theme.spacing() * 2}px;
  right: ${({ theme }) => theme.spacing() * 2}px;
  cursor: pointer;
`;

export const StyledTileWrapper = styled(Box)`
  > * {
    box-sizing: border-box;
    padding-right: ${({ theme }) => theme.spacing() * 2}px;
    flex: 1/3;
    flex-basis: 254px;
    &:last-child {
      padding-right: 0;
    }
  }
`;

export const StyledVideoLightbox = styled(Box)`
  iframe {
    width: 100%;
    height: 100%;
    min-height: 470px;
  }
`;

export const StyledVideoHolder = styled(Box)`
  iframe {
    pointer-events: none;
  }
`;

export const StyledPDFIcons = styled(Box)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing() * 2}px;
`;

export const StyledDetailsContainer = styled(Box)`
  overflow-y: auto;
`;

export const StyledDescriptionWrapper = styled(Box)`
  max-width: 450px;
`;

export const StyledVideoTitle = styled(Typography)`
  font-weight: 600;
`;
