import React, { FunctionComponent } from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {
  Tile,
  TileHeading,
  TileIcon,
  TileLink,
  TileSubtitle,
  TileTitle,
} from './HorizontalTile.styles';
import { StyledLink } from './Common.styles';
import { UserSignedGuard } from '../../helpers/user-signed-guard';

const HorizontalTile: FunctionComponent = () => (
  <Tile>
    <TileIcon>
      <img src={require('../../assets/apps.svg')} alt={'App Center'} />
    </TileIcon>
    <TileHeading>
      <TileTitle>Application Center</TileTitle>
      <TileSubtitle>Control and manage all your apps</TileSubtitle>
    </TileHeading>
    <TileLink>
      <UserSignedGuard>
        <StyledLink to={'/app-center'}>
          View
          <ArrowRightAltIcon />
        </StyledLink>
      </UserSignedGuard>
    </TileLink>
  </Tile>
);

export default HorizontalTile;
