import React from 'react';
import { connect } from 'formik';

const OnSubmitValidationError = (props): null => {
  const { callback, formik } = props;

  const effect = () => {
    if (!formik?.isValid) {
      return callback(formik?.errors);
    }

    return callback(undefined);
  };

  React.useEffect(effect, [formik?.isValid]);

  return null;
};

export default connect(OnSubmitValidationError);
