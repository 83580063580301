import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { StyledPill } from './Pill.styles';

interface PillProps {
  label: string;
  contained?: boolean;
  small?: boolean;
  success?: boolean;
  IconComponent?: FunctionComponent;
  onClick?: (e: MouseEvent) => void;
  computedState?: string;
}

const Pill: FunctionComponent<PillProps> = ({
  IconComponent,
  label,
  contained,
  success,
  small,
  computedState,
  onClick,
}) => (
  <StyledPill
    disableFocusRipple
    disableRipple
    contained={contained}
    small={small}
    success={success}
    onClick={onClick}
    computedState={computedState}
  >
    {IconComponent && (
      <Box mr={1}>
        <IconComponent />
      </Box>
    )}
    <Typography variant="caption" color="primary">
      {label}
    </Typography>
  </StyledPill>
);

export default Pill;
