import React, { FunctionComponent } from 'react';
import { Auth } from 'westside-types';
import { RoleBadgeElement } from './RoleBadge.styles';

type RoleBadgeProps = {
  role: Auth.Role;
};

const roleDisplayNameMapping = {
  USER: 'DEVELOPER',
  ADMIN: 'ADMIN',
};

const roleClassMapping = {
  USER: 'developer-button',
  ADMIN: 'admin-button',
};

const RoleBadge: FunctionComponent<RoleBadgeProps> = ({ role }) => (
  <>
    {!!roleDisplayNameMapping[role] && (
      <RoleBadgeElement className={roleClassMapping[role]} size="small">
        {roleDisplayNameMapping[role]}
      </RoleBadgeElement>
    )}
  </>
);

export default RoleBadge;
