import React, { Component } from 'react';
import { Resources } from 'westside-types';
import { connect } from 'react-redux';
import { iRootState, Dispatch } from '../../store';
import promiseCallbackHandler from '../../lib/promise-callback';
import config from '../../config';
import Layout from '../../components/Resources/Landing';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
});
const mapDispatchToProps = ({ account, resources, file }: Dispatch) => ({
  logOut: account.logOut,
  getAllResources: resources.getAllResources,
  getDocuments: resources.getDocuments,
  deleteResource: resources.deleteResource,
  deleteDocument: resources.deleteDocument,
  downloadFile: file.downloadFile,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface ResourcesState {
  loading: boolean;
  error: string | null;
  resources: Resources.ResourceListEntry[];
  documents: Resources.Document[];
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class LoginContainer extends Component<ConnectedProps, ResourcesState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    error: null,
    resources: [],
    documents: [],
  };

  componentDidMount() {
    this.getAllResources();
  }

  getAllResources = async () => {
    const { getAllResources, getDocuments } = this.props;
    this.setState({ loading: true });
    const resources = await this.promiseCallback(getAllResources());
    const documents = await this.promiseCallback(getDocuments());
    this.setState({ documents });
    this.setState({ resources });
  };

  deleteResource = async (id: string) => {
    const { deleteResource } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(deleteResource(id));
    this.getAllResources();
  };

  deleteDocument = async (id: string) => {
    const { deleteDocument } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(deleteDocument(id));
    this.getAllResources();
  };

  downloadFile = async (id: string) => {
    const { downloadFile } = this.props;
    this.setState({ loading: true });
    const url = await this.promiseCallback(downloadFile(id));
    window.open(url);
  };

  openPdf = async (id: string): Promise<void> => {
    window.open(`${config.apiRootUrl}/files/${id}`);
  };

  render() {
    const { loading, resources, documents } = this.state;
    const { logOut, account, personalProfile, companyProfile } = this.props;

    return (
      <Layout
        loading={loading}
        logOut={logOut}
        account={account}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        resources={resources}
        documents={documents}
        downloadFile={this.downloadFile}
        openPdf={this.openPdf}
        deleteResource={this.deleteResource}
        deleteDocument={this.deleteDocument}
      />
    );
  }
}
// @ts-ignore
export default LoginContainer;
