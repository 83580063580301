import React, { FunctionComponent, useState } from 'react';
import { Account } from '../../store/account';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import { UsersIcon } from '../UI/Icon/Users';
import { LogoType, Sidebar } from '../UI/Sidebar/Sidebar';
import { MainSidebarMenu } from '../UI/Menu';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import { Auth } from 'westside-types';
import UsersList from './UsersList';
import PendingList from './PendingList';
import Typography from '@material-ui/core/Typography';
import { PageHeader } from './UsersPage.styles';
import ApprovalButtons from './ApprovalButtons';

type UsersProps = {
  account: Account;
  logOut: () => void;
  onSignedChange: (accountId: string, signed: boolean) => void;
  onActiveChange: (accountId: string, active: boolean) => void;
  onInviteResendRequested: (accountId: string) => void;
  onApprovalChange: (accountIds: string[], status: Auth.AccountStatus) => void;
  users: Auth.AccountStatus[];
};

const UsersPage: FunctionComponent<UsersProps> = ({
  logOut,
  account,
  onSignedChange,
  onActiveChange,
  onApprovalChange,
  onInviteResendRequested,
  users,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const approvedUsers = users.filter(({ status }) => status === Auth.AccountStatus.APPROVED);
  const pendingUsers = users.filter(({ status }) => status === Auth.AccountStatus.PENDING);
  return (
    <PageContainer>
      <Header logOut={logOut} account={account} />
      <Box flex="1" display="flex" flexDirection="row">
        <Sidebar title="Platform Science" logoType={LogoType.LOGO}>
          <MainSidebarMenu userRole={account.role} />
        </Sidebar>
        <PageLayout className="with-sidebar">
          <Box height="100%" mt={4} mb={4} borderRadius={1}>
            <PageHeader mb={4} display="flex" flexDirection="row">
              <Box flex="1">
                <Typography variant="h1">
                  <UsersIcon fontSize="large" color="primary" />
                  <span>Users</span>
                </Typography>
              </Box>
              {selectedTab === 1 && (
                <ApprovalButtons
                  multiple={selectedUsers.length > 1}
                  disabled={selectedUsers.length <= 0}
                  onApprovalChange={(status) => {
                    onApprovalChange(selectedUsers, status);
                    setSelectedUsers([]);
                  }}
                />
              )}
            </PageHeader>
            <AppBar position="static" elevation={0}>
              <Tabs value={selectedTab} onChange={(_, tab) => setSelectedTab(tab)}>
                <Tab label="Users" />
                <Tab label="Pending" />
              </Tabs>
            </AppBar>
            {selectedTab === 0 && (
              <UsersList
                users={approvedUsers}
                onSignedChange={onSignedChange}
                onActiveChange={onActiveChange}
                onInviteResendRequested={onInviteResendRequested}
              />
            )}
            {selectedTab === 1 && (
              <PendingList
                users={pendingUsers}
                onSelectedUsersChange={setSelectedUsers}
                selectedUsers={selectedUsers}
              />
            )}
          </Box>
        </PageLayout>
      </Box>
      <Footer>
        <FooterLink to={null}>Privacy Policy</FooterLink>
      </Footer>
    </PageContainer>
  );
};

export default UsersPage;
