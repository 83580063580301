import { parseQueryParams } from './url';

export function getReturnUrl(sessionId: string): string {
  const { location } = window;
  const basePath = location.href.substring(0, location.href.length - location.pathname.length);
  return `${basePath}/return.html?sessionId=${sessionId}`;
}

export function openForResult(
  url: string,
  target: string,
  sessionId: string,
  poolingInterval: number = 100,
): Promise<{ [key: string]: string[] }> {
  return new Promise<{ [key: string]: string[] }>((resolve, reject) => {
    const handle = window.open(url, target);
    let finished = false;

    const listener = ({ detail }: CustomEvent) => {
      if (!detail) {
        return;
      }
      const url = new URL(detail);
      const params = parseQueryParams(url.search);
      if (!params.sessionId || params.sessionId.length !== 1) {
        return;
      }
      if (params.sessionId[0] !== sessionId) {
        return;
      }
      finished = true;
      window.removeEventListener('return_event', listener);
      resolve(params);
    };

    window.addEventListener('return_event', listener);

    const poolForClosed = () => {
      if(finished) {
        return;
      }
      if (handle.closed) {
        finished = true;
        window.removeEventListener('return_event', listener);
        reject();
        return;
      }
      setTimeout(poolForClosed, poolingInterval);
    };
    poolForClosed();
  });
}
