import React, { FunctionComponent } from 'react';
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Auth } from 'westside-types';
import IconButton from '@material-ui/core/IconButton';

type UsersListProps = {
  onSignedChange: (accountId: string, signed: boolean) => void;
  onActiveChange: (accountId: string, active: boolean) => void;
  onInviteResendRequested: (accountId: string) => void;
  users: Auth.AccountOverview[];
};

const roleDisplayNameMapping = {
  USER: 'Dev',
  ADMIN: 'Admin',
};

const UsersList: FunctionComponent<UsersListProps> = ({
  onSignedChange,
  onActiveChange,
  onInviteResendRequested,
  users,
}) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Company</TableCell>
          <TableCell>Signed</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Permissions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, index) => (
          <TableRow key={index}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.company}</TableCell>
            <TableCell>
              <Select
                fullWidth
                disableUnderline={true}
                value={user.signed}
                onChange={(event) => onSignedChange(user.id, event.target.value)}
                label="Signed"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.phone}</TableCell>
            <TableCell>
              <Select
                fullWidth
                disableUnderline={true}
                value={user.active}
                onChange={(event) => onActiveChange(user.id, event.target.value)}
                label="Active"
              >
                <MenuItem value={false}>Disabled</MenuItem>
                <MenuItem value={true}>Active</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box flex={1} maxWidth="75px">
                  {roleDisplayNameMapping[user.role]}
                </Box>
                {user.confirmed === false && (
                  <Tooltip title="Re-send Invite">
                    <IconButton onClick={() => onInviteResendRequested(user.id)}>
                      <MailOutlineIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </TableCell>
          </TableRow>
        ))}
        {users.length <= 0 && (
          <TableRow>
            <TableCell colSpan={7} align="center">
              <Typography variant="body2">List is empty</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default UsersList;
