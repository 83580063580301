import yup, { string, object } from 'yup';
import { validationMessages } from '../constants/messages';

const { password, field } = validationMessages;

const validationSchema = object().shape({
  token: string().required(field.required),
  password: string().required(password.required),
});

export type SetPasswordSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
