import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from '../config';
import { hasActiveAuthToken, getAuthToken } from './jwt';

const instance = axios.create({
  baseURL: Config.apiBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(
  async (inputConfig: AxiosRequestConfig) => {
    const config = inputConfig;
    try {
      if (hasActiveAuthToken() && config.withCredentials !== false) {
        config.headers.common.Authorization = `Bearer ${getAuthToken()}`;
      }
    } catch (error) {
      /* Nothing */
    }
    return config;
  },
  (error: AxiosError) => {
    throw error;
  },
);

instance.interceptors.response.use(
  (res: AxiosResponse) => {
    if (!`${res.status}`.startsWith('2')) {
      throw res.data;
    }

    try {
      if (typeof res.data === 'string') {
        JSON.parse(res.data);
      }
    } catch (error) {
      res.data = null;
    }

    return res;
  },
  (error: AxiosError) => {
    if (error?.response?.data) {
      throw error.response.data;
    }
    throw error;
  },
);

export default instance;
