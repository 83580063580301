import React, { FunctionComponent } from 'react';
import { Faq } from 'westside-types';
import Box from '@material-ui/core/Box';
import { StyledTypography } from './FaqEntry.styles';

type FaqEntryComponentProps = {
  faq: Faq.FaqDto;
};

const FaqEntryComponent: FunctionComponent<FaqEntryComponentProps> = ({ faq }) => (
  <Box mb={3}>
    <StyledTypography variant="h5">{faq.question}</StyledTypography>
    <StyledTypography variant="body1">{faq.answer}</StyledTypography>
  </Box>
);

export default FaqEntryComponent;
