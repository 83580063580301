export enum ResourceType {
  PS_APP = 'PS_APP',
  SAMPLE_APP = 'SAMPLE_APP',
  DOCUMENTATION = 'DOCUMENTATION',
  SIMULATOR = 'SIMULATOR',
  TUTORIAL = 'TUTORIAL',
}

export enum ResourceActions {
  ADD = 'ADD',
}

export interface ResourceIconProps {
  color?: string;
}

export type ResourceFieldTypes = 'text' | 'file' | 'document';

export interface ResourceField {
  name: string;
  type: ResourceFieldTypes;
  title?: string;
  multiple?: boolean;
  textarea?: boolean;
  optional?: boolean;
  accept?: string;
  fixed?: boolean;
  typeDescription?: string;
  amount?: number;
}

export interface ResourceSection {
  type: ResourceType;
  actions: {
    label: ResourceActions;
    link: string;
  }[];
  Icon?: ({ color }: { color: string }) => JSX.Element;
}
