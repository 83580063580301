import React, { FunctionComponent } from 'react';
import { Faq, Profile } from 'westside-types';
import { PageContainer, PageLayout } from '../UI/Layouts';
import Header from '../UI/Header';
import AppContextHeader from '../UI/Header/AppContextHeader';
import Box from '@material-ui/core/Box';
import { Account } from '../../store/account';
import { Footer, FooterLink } from '../UI/Footer/Footer';
import FaqEditor from './FaqEditor';

type FaqListProps = {
  faqs: Faq.FaqListDto | null;
  onFaqsChange: (faqs: Faq.FaqListDto) => void;
  logOut: () => void;
  account: Account;
  personalProfile: Profile.ProfileDto;
};

const FaqEditComponent: FunctionComponent<FaqListProps> = ({
  faqs,
  logOut,
  account,
  personalProfile,
  onFaqsChange,
}) => (
  <PageContainer>
    <Header logOut={logOut} account={account} />
    <AppContextHeader
      account={account}
      avatar={personalProfile?.avatarFileId}
      closeButtonText={'Edit Frequently Asked Questions'}
      closeButtonLink="/faq"
    />
    {faqs && (
      <PageLayout>
        <Box mt={4} mb={4}>
          <FaqEditor cancelLink={'/faq'} initialData={faqs} onDataChange={onFaqsChange} />
        </Box>
      </PageLayout>
    )}
    <Footer>
      <FooterLink to={null}>Privacy Policy</FooterLink>
    </Footer>
  </PageContainer>
);

export default FaqEditComponent;
