import yup, { string, object } from 'yup';
import { validationMessages } from '../constants/messages';

const { username, field } = validationMessages;

const phoneRegExp = /^[0-9]{10}$/;

const validationSchema = object().shape({
  name: string().required(username.required),
  email: string().email(field.email).required(field.required),
  phone: string().matches(phoneRegExp, 'Phone number is not valid').required(field.required),
  company: string().required(field.required),
  reCaptchaResponse: string().required(),
});

export type RegisterSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
