import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  span {
    text-transform: uppercase;
  }

  .MuiSvgIcon-root {
    margin-left: ${({ theme }) => theme.spacing()}px;
  }
`;

export const IconWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing() * 2}px;
`;
