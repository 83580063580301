import React, { Component } from 'react';
import { connect } from 'react-redux';
import { iRootState, Dispatch } from '../../store';
import { Auth } from 'westside-types';
import promiseCallbackHandler from '../../lib/promise-callback';
import Layout from '../../components/Auth/Register';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;
const mapStateToProps = (state: iRootState) => ({});
const mapDispatchToProps = ({ account, snackbar }: Dispatch) => ({
  register: account.register,
  showNotification: snackbar.open,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface RegisterState {
  loading: boolean;
  formSubmitted: boolean;
  error: string | null;
}

//@ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
//@ts-ignore
class RegisterContainer extends Component<ConnectedProps, RegisterState> {
  promiseCallback = promiseCallbackHandler(this);

  state = {
    loading: false,
    formSubmitted: false,
    error: null,
  };

  register = async (user: Auth.RegisterDto) => {
    const { register } = this.props;
    this.setState({ loading: true });
    await this.promiseCallback(register(user));
    this.setState({ formSubmitted: true });
  };

  render() {
    const { loading, error, formSubmitted } = this.state;

    return (
      <Layout
        loading={loading}
        error={error}
        formSubmitted={formSubmitted}
        register={this.register}
        recaptchaSiteKey={REACT_APP_RECAPTCHA_SITE_KEY}
      />
    );
  }
}
// @ts-ignore
export default RegisterContainer;
