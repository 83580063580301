import styled from 'styled-components';
import { Field } from 'formik';

export const StyledInput = styled(Field)`
  margin-bottom: 0;

  &.rounded {
    div:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
      border-radius: ${({ theme }) => theme.spacing() / 2}px;
      border: 1px solid #cbd4e5;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: ${({ theme }) => theme.spacing() / 2}px;
    }
    div:not(.Mui-error) .MuiFormLabel-root {
      color: #778899;
    }
  }
`;
