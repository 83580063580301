import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { Profile } from 'westside-types';
import { AccountWrapper, HeadingContainer, StyledAddButton } from './Heading.styles';
import AccountView from './AccountView';

interface HeadingProps {
  personalProfile: Profile.ProfileDto;
  companyProfile: Profile.CompanyDto;
  createAppEnabled: boolean;
}

const Heading: FunctionComponent<HeadingProps> = ({
  personalProfile,
  companyProfile,
  createAppEnabled,
}) => (
  <HeadingContainer>
    <AccountWrapper>
      <Typography variant="h1">Welcome!</Typography>
      <AccountView
        name={personalProfile && personalProfile.name}
        companyName={companyProfile && companyProfile.name}
        avatar={personalProfile?.avatarFileId}
      />
    </AccountWrapper>
    {createAppEnabled && <StyledAddButton to={'/create-app'}>Add App</StyledAddButton>}
  </HeadingContainer>
);

export default Heading;
