import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { Profile } from 'westside-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Input from '../UI/Input';
import ConnectedFileUploader from '../UI/ConnectedFileUploader';
import { states } from '../../constants/states';

type CompanyProfileEditorProps = {
  initialData: Profile.CompanyDto;
  onDataChange: (data: Profile.CompanyDto) => void;
  cancelLink: string;
};

const CompanyProfileEditor: FunctionComponent<CompanyProfileEditorProps> = ({
  onDataChange,
  initialData,
  cancelLink,
}) => (
  <Formik
    initialValues={initialData}
    validationSchema={Profile.CompanyDtoValidation}
    onSubmit={onDataChange}
    validateOnMount={true}
  >
    {(props) => (
      <form onSubmit={props.handleSubmit}>
        <Box>
          <Box mb={3}>
            <Input className="rounded" label="Company Name" type="text" name="name" required />
          </Box>
          <Box mb={3}>
            <Input
              className="rounded"
              label="Company Description"
              type="text"
              name="description"
              required
              multiline
              rows={6}
            />
          </Box>
          <Box mb={3}>
            <Input
              className="rounded"
              label="Street Address"
              type="text"
              name="streetAddress"
              required
            />
          </Box>
          <Box display="flex" mb={3}>
            <Box flex={2} mr={1}>
              <Input className="rounded" label="City" type="text" name="city" required />
            </Box>
            <Box flex={1} mr={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="state">State</InputLabel>
                <Select
                  className="rounded"
                  labelId="state"
                  id="state"
                  value={props.values.state}
                  onChange={(e) => props.setFieldValue('state', e.target.value)}
                  label="State"
                >
                  {states.map(({ name, shortName }) => (
                    <MenuItem value={shortName}>{name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flex={1}>
              <Input className="rounded" label="Zip" type="text" name="zip" required />
            </Box>
          </Box>
          <ConnectedFileUploader
            url={'/company/logo'}
            name="logoFileId"
            typeDescription="Company Logo"
            onChange={(file) => props.setFieldValue('logoFileId', file.id)}
            preselectedFile={
              props.values.logoFileId
                ? {
                    name: props.values.logoFileId,
                    id: props.values.logoFileId,
                  }
                : undefined
            }
            type=".png"
            useSelectedFileAsAnIcon
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Box mr={3} ml={3}>
            <Link to={cancelLink}>
              <Button type="button" variant="outlined" color="primary">
                Cancel
              </Button>
            </Link>
          </Box>
          <Box mr={3} ml={3}>
            <Button type="submit" variant="contained" color="primary" disabled={!props.isValid}>
              Save
            </Button>
          </Box>
        </Box>
      </form>
    )}
  </Formik>
);

export default CompanyProfileEditor;
