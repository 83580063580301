import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { Typography, Box } from '@material-ui/core';
import Input from '../UI/Input';
import Button from '../UI/Button';
import Logo from '../UI/Logo';
import { Auth } from 'westside-types';
import validationSchema from '../../validation/forgot-password';
import { StyledBackgroundAccent, StyledFormWrapper } from './Login.styles';
import { PageContainer } from '../UI/Layouts';

interface ResetPasswordProps {
  loading: boolean;
  error: string | null;
  resetPassword: (email: Auth.ForgotPasswordDto) => void;
}

class ResetPasswordComponent extends Component<ResetPasswordProps> {
  render() {
    const { loading, resetPassword } = this.props;
    const initialValues: Auth.ForgotPasswordDto = {
      email: '',
    };

    const onSubmit = async (values: Auth.ForgotPasswordDto): Promise<void> => {
      resetPassword(values);
    };

    return (
      <>
        <StyledBackgroundAccent />
        <PageContainer center={true}>
          <StyledFormWrapper>
            <Box textAlign="center" mb={4}>
              <Logo />
            </Box>
            <Box textAlign="center" mb={4}>
              <Typography variant="body1">
                Please provide your email, we will send you password reset link
              </Typography>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isValid }: any) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className="forget-password-form"
                >
                  <Form>
                    <Input label="Email" className="rounded" type="text" name="email" required />
                    <Box mt={4} textAlign="center">
                      <Button
                        isPending={loading}
                        disabled={loading || !isValid}
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        className="submit"
                      >
                        Send reset password link
                      </Button>
                    </Box>
                  </Form>
                </Box>
              )}
            </Formik>
          </StyledFormWrapper>
        </PageContainer>
      </>
    );
  }
}

export default ResetPasswordComponent;
