// TODO refactor this component so it uses sessions for file uploads
import React, { useEffect, useState, FunctionComponent } from 'react';
import { useDropzone } from 'react-dropzone';
import { Formik } from 'formik';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import truncate from '../../../helpers/truncate-string';
import Dialog from '../Dialog';
import Input from '../Input';
import {
  StyledDropZone,
  StyledThumbnails,
  StyledButton,
  StyledScreenshotLightbox,
} from './DropZone.styles';

interface DropZoneProps {
  loading?: boolean;
  initialScreenshots?: File[];
  onDrop?: (uploadedFiles: File[]) => void;
  onDelete?: (deletedFile: number, allFiles: File[]) => void;
}

const DropZone: FunctionComponent<DropZoneProps> = ({
  onDrop,
  onDelete,
  loading,
  initialScreenshots,
}) => {
  const [files, setFiles] = useState<File[]>(initialScreenshots || []);
  const [deleteScreenshotDialogState, toggleDeleteScreenshotDialogState] = useState<boolean>(false);
  const [editScreenshotDialogState, toggleEditScreenshotDialogState] = useState<boolean>(false);
  const [showScreenshotDialogState, toggleShowScreenshotDialogState] = useState<boolean>(false);
  const [selectedScreenshotIndex, setSelectedScreenshotIndex] = useState<number>(0);
  const [selectedScreenshotNameOverride, setSelectedScreenshotNameOverride] = useState<string>('');

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/*'],
    multiple: true,
    onDrop: (uploadedFiles: File[]): void => {
      setFiles([...files, ...uploadedFiles]);
      if (onDrop) {
        onDrop(uploadedFiles);
      }
    },
  });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(URL.createObjectURL(file) || ''));
    },
    [files],
  );

  const openDeleteDialog = (screenshotIndex: number) => {
    toggleDeleteScreenshotDialogState(true);
    setSelectedScreenshotIndex(screenshotIndex);
  };

  const openEditIconDialog = (screenshotIndex: number) => {
    toggleEditScreenshotDialogState(true);
    setSelectedScreenshotIndex(screenshotIndex);
  };

  const openImageLightbox = (screenshotIndex: number) => {
    toggleShowScreenshotDialogState(true);
    setSelectedScreenshotIndex(screenshotIndex);
  };

  const deleteScreenshot = (index: number) => {
    setSelectedScreenshotIndex(0);
    files.splice(index, 1);
    setFiles(files);
    onDelete && onDelete(index, files);
    toggleDeleteScreenshotDialogState(false);
  };

  const extractFileExtension = (fileName: string) => {
    return fileName.split('.')[1];
  };

  const extractFileName = (fileName: string) => {
    return fileName.split('.')[0];
  };

  const editScreenshot = (index: number) => {
    const allFiles = [...files];
    const selectedFile = allFiles[index];
    const selectedFileExtension = extractFileExtension(selectedFile.name);
    const blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
    const renamedFile = new File(
      [blob],
      `${selectedScreenshotNameOverride}.${selectedFileExtension}`,
      {
        type: selectedFile.type,
      },
    );
    allFiles.splice(index, 1, renamedFile);
    setFiles(allFiles);
    toggleEditScreenshotDialogState(false);
  };

  return (
    <StyledDropZone>
      <Box mb={4} display="flex" flexDirection="row" alignItems="flex-end">
        <Box flex="1">
          <Typography variant="h6">Upload Screenshots</Typography>
          <Typography variant="body1">
            Upload application screenshots. The images will appear on the public facing marketplace.
          </Typography>
        </Box>
        <StyledButton variant="contained" color="primary" disabled={loading} onClick={open}>
          Choose File
        </StyledButton>
      </Box>
      <Box {...getRootProps({ className: 'container' })}>
        <input {...getInputProps()} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="subtitle1" color="primary">
            Drag and Drop Screenshots Here
          </Typography>
        </Box>
      </Box>
      <StyledThumbnails mt={3} display="flex" flexDirection="row">
        {files.map((file, index) => (
          <Box key={`screen-${index}`} mr={3} flexDirection="column">
            <Box
              className="screenshot"
              style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}
            ></Box>
            <Box mt={1}>
              <Typography variant="caption">{truncate(file.name, 22)}</Typography>
            </Box>
            <IconButton onClick={() => openImageLightbox(index)}>
              <OpenInNewIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => openEditIconDialog(index)}>
              <EditIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => openDeleteDialog(index)}>
              <DeleteIcon color="primary" />
            </IconButton>
          </Box>
        ))}
      </StyledThumbnails>
      <Dialog
        open={showScreenshotDialogState}
        onCancel={() => toggleShowScreenshotDialogState(false)}
        disableActions
        isLightbox
      >
        {files.length && (
          <StyledScreenshotLightbox>
            <img
              src={`${URL.createObjectURL(files[selectedScreenshotIndex])}`}
              alt={`screenshot-${selectedScreenshotIndex}`}
            />
          </StyledScreenshotLightbox>
        )}
      </Dialog>
      <Dialog
        open={deleteScreenshotDialogState}
        dialogData={selectedScreenshotIndex}
        onCancel={() => toggleDeleteScreenshotDialogState(false)}
        onConfirm={deleteScreenshot}
        title="Are you sure you want to delete this screenshot?"
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
      />
      <Dialog
        open={editScreenshotDialogState}
        dialogData={selectedScreenshotIndex}
        onCancel={() => toggleEditScreenshotDialogState(false)}
        onConfirm={editScreenshot}
        title="Edit selected screenshot name"
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
      >
        <Box mt={4}>
          <Formik
            initialValues={{
              screenshot: extractFileName(files[selectedScreenshotIndex]?.name || ''),
            }}
            onSubmit={() => {}}
            validate={({ screenshot }) => {
              setSelectedScreenshotNameOverride(screenshot.replace(/\./g, ''));
            }}
          >
            <Input name="screenshot" />
          </Formik>
        </Box>
      </Dialog>
    </StyledDropZone>
  );
};

export default DropZone;
