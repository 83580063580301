import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export const StyledButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing() * 2}px;
`;

export const InputWrapper = styled(Box)`
  .MuiTextField-root {
    margin-bottom: ${({ theme }) => theme.spacing() * 3}px;
  }
`;
