import React, { FunctionComponent, useEffect } from 'react';
import { Formik } from 'formik';
import { App } from 'westside-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Input from '../UI/Input';
import { OnError } from '../UI/Form';
import Checkbox from '../UI/Checkbox';
import validationSchema from '../../validation/create-app-info-form';
import { StyledInfoForm } from './Form.styles';

export interface InfoFormDataProps {
  onDataChange: (data: App.AppInfo) => void;
  onError: (errors: object) => void;
  loading: boolean;
  initialData?: App.AppInfo;
  disabled?: boolean;
}

const InfoForm: FunctionComponent<InfoFormDataProps> = ({
  onDataChange,
  onError,
  initialData,
  loading,
  disabled,
}) => {
  const initialValues: App.AppInfo = {
    name: '',
    apiLevel: '',
    category: '',
    fingerprint: '',
    iconFileId: '',
    version: '',
    price: 0,
    dataUsage: '',
    ownershipApproved: false,
    draft: false,
  };

  useEffect(() => {
    if (!initialData) {
      onDataChange(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValidationError = (errors) => {
    onError(errors);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialData || initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validate={(values: App.AppInfo) => {
        onDataChange(values);
      }}
    >
      {(props) => {
        props.values['price'] = props.values['zeroPrice'] ? 0 : props.values['price'];

        return (
          <Box>
            <StyledInfoForm display="flex" flexDirection="row">
              <Box flex="1" mr={6}>
                <Input
                  className="rounded"
                  label="Name"
                  type="text"
                  name="name"
                  disabled={true}
                  required
                />
                <Input
                  className="rounded"
                  label="Category"
                  type="text"
                  name="category"
                  disabled={loading || disabled}
                  required
                />
                <Input
                  className="rounded"
                  label="Version"
                  type="text"
                  name="version"
                  disabled={true}
                  required
                />
                <Input
                  className="rounded"
                  label="Price"
                  type="text"
                  name="price"
                  required
                  disabled={props.values['zeroPrice'] || loading || disabled}
                />
                <Checkbox
                  label={'Set Price to "Free"'}
                  id="zeroPrice"
                  name="zeroPrice"
                  disabled={loading || disabled}
                />
                <Box mt={2}>
                  <Typography variant="caption">
                    <i>*Required Fields</i>
                  </Typography>
                </Box>
              </Box>
              <Box flex="1">
                <Input
                  className="rounded"
                  label="API Level"
                  type="text"
                  name="apiLevel"
                  disabled={true}
                  required
                />
                <Input
                  className="rounded"
                  label="Fingerprint"
                  type="text"
                  name="fingerprint"
                  disabled={true}
                  required
                />
                <Input
                  className="rounded"
                  label="Data Usage"
                  type="text"
                  name="dataUsage"
                  disabled={loading || disabled}
                  required
                />
                <Checkbox
                  label="I certify this is my app *"
                  id="ownershipApproved"
                  name="ownershipApproved"
                  disabled={loading || disabled}
                />
              </Box>
            </StyledInfoForm>
            <OnError callback={onValidationError} />
          </Box>
        );
      }}
    </Formik>
  );
};

export default InfoForm;
