import React, { useState, FunctionComponent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { StyledInput } from './PasswordInput.styles';

interface PasswordInput {
  name: string;
  [x: string]: any;
}

const PasswordInput = ({ field, form: { touched, errors }, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isError = Boolean(touched[field.name] && errors[field.name]);

  return (
    <FormControl variant="outlined" error={isError}>
      <InputLabel htmlFor="outlined-adornment-password">{props.label || 'Password'}</InputLabel>
      <OutlinedInput
        {...field}
        {...props}
        type={showPassword ? 'text' : 'password'}
        error={isError}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        id="outlined-adornment-password"
      />
      {isError && <FormHelperText>{errors[field.name]}</FormHelperText>}
    </FormControl>
  );
};

const FormikPasswordInput: FunctionComponent<PasswordInput> = (props) => {
  return <StyledInput component={PasswordInput} {...props} fullWidth />;
};

export default FormikPasswordInput;
