import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { sizes } from '../../theme';

export const StyledBox = styled(Box)`
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.palette.warning.main} 0%,
    ${({ theme }) => theme.palette.warning.light} 100%
  );
`;

export const PersistentNotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  max-width: ${sizes.headerMaxWidth}px;
`;

export const StyledTypography = styled(Typography)`
  flex: 1;
  color: ${({ theme }) => theme.palette.secondary.light};
`;
