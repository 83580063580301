import React, { FunctionComponent } from 'react';
import { Faq } from 'westside-types';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import Input from '../UI/Input';
import AddIcon from '@material-ui/icons/Add';
import { InputWrapper, StyledButton } from './FaqEditor.styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

type FaqListProps = {
  onDataChange: (data: Faq.FaqListDto) => void;
  initialData?: Faq.FaqListDto;
  cancelLink: string;
};

const FaqEditor: FunctionComponent<FaqListProps> = ({ onDataChange, initialData, cancelLink }) => (
  <Formik
    initialValues={initialData}
    validationSchema={Faq.FaqListDtoValidation}
    onSubmit={onDataChange}
  >
    {(props) => (
      <form onSubmit={props.handleSubmit}>
        {props.values.faqs.map((value, i) => (
          <Box display="flex" flexDirection="row" key={i}>
            <InputWrapper flex="1" mr={2}>
              <Input
                className="rounded"
                label={`Question #${i + 1}`}
                type="text"
                name={`faqs[${i}].question`}
                required
              />
              <Input
                className="rounded"
                label={`Answer #${i + 1}`}
                type="text"
                name={`faqs[${i}].answer`}
                required
                multiline
                rows={6}
              />
            </InputWrapper>
            <Box>
              <IconButton
                color="primary"
                onClick={() =>
                  props.setValues({
                    faqs: [...props.values.faqs.filter((_, index) => index !== i)],
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        <Button
          type="button"
          disableElevation
          color="primary"
          onClick={() =>
            props.setValues({ faqs: [...props.values.faqs, { question: '', answer: '' }] })
          }
        >
          <AddIcon />
          <span>Add Question & Answer</span>
        </Button>
        <Box mt={3} display="flex" justifyContent="center" variant="contained">
          <Link to={cancelLink}>
            <StyledButton type="button" variant="outlined" color="primary">
              Cancel
            </StyledButton>
          </Link>
          <StyledButton type="submit" variant="contained" color="primary" disabled={!props.isValid}>
            Save
          </StyledButton>
        </Box>
      </form>
    )}
  </Formik>
);

export default FaqEditor;
