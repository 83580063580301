export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expireInDays: number = 365,
): void => {
  const d = new Date();
  d.setTime(d.getTime() + expireInDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = (cookieName: string): string | null => {
  const cookieNamePlus = cookieName.length + 1;

  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, cookieNamePlus) === `${cookieName}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(cookieNamePlus));
      })[0] || null
  );
};

export const deleteCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
