import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

export const StyledFileUploader = styled(Box)`
  padding: ${({ theme }) => theme.spacing() * 3}px;
  background: ${({ isDragActive }) => (isDragActive ? 'rgba(39, 170, 225, 0.06)' : '#ffffff')};
  box-shadow: 0px 0px 16px rgba(39, 170, 225, 0.23);
  border-radius: 4px;
  min-height: 84px;
  box-sizing: border-box;

  input {
    display: none;
  }
  img {
    width: ${({ theme }) => theme.spacing() * 6}px;
    height: ${({ theme }) => theme.spacing() * 6}px;
    border-radius: ${({ theme }) => theme.spacing()}px;
  }
  .MuiButton-label {
    text-transform: uppercase;
  }
`;

export const StyledDropZone = styled(Box)`
  border: 1px dashed ${({ theme }) => theme.palette.primary.light};
  box-sizing: border-box;
  border-radius: 4px;
  width: ${({ theme }) => theme.spacing() * 6}px;
  height: ${({ theme }) => theme.spacing() * 6}px;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const StyledLinearProgress = styled(LinearProgress)`
  width: 100%;
`;

export const StyledLoader = styled(CircularProgress)`
  margin-right: ${({ theme }) => theme.spacing() * 2}px;
`;
