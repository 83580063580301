import React, { Component } from 'react';
import Layout from '../../components/NotFound/Layout';
import { connect } from 'react-redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

type NotFoundProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type NotFoundState = {};

@connect(mapStateToProps, mapDispatchToProps)
export default class NotFoundContainer extends Component<NotFoundProps, NotFoundState> {
  render() {
    return <Layout />;
  }
}
