import { Component } from 'react';
import * as moment from 'moment';

export type DurationProps = {
  date: string | number | moment.Moment;
  updatingInterval?: number;
};

type DurationState = {
  text: string;
  interval: number | null;
};

export default class Duration extends Component<DurationProps, DurationState> {
  state = {
    text: '',
    interval: null,
  };

  componentDidMount() {
    const updatingInterval = this.props.updatingInterval || 1000;
    const interval = setInterval(() => {
      const { date } = this.props;
      const now = moment();
      const diff = moment(date).subtract(now);
      const text = moment.duration(diff).humanize(true);
      this.setState({ text });
    }, updatingInterval);
    this.setState({ interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    if (interval) {
      clearInterval(interval);
    }
  }

  render() {
    const { text } = this.state;
    return text;
  }
}
