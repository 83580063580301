import { ResourceType } from '../types/resources';

const { PS_APP, SAMPLE_APP, DOCUMENTATION, SIMULATOR, TUTORIAL } = ResourceType;

export default {
  [PS_APP]: {
    subTitle: 'Review, test and use.',
    description:
      "Test your SDK's with our proprietary applications. Select and app to view details and download an APK for your use.",
  },
  [SAMPLE_APP]: {
    subTitle: 'Leverage our proprietary apps',
    description: "Select an app to view the functions, view API's, download and more.",
  },
  [DOCUMENTATION]: {
    subTitle: 'Re-create real engine events and telematic events',
    description: 'View and download our mobile and desktop app simulators to help you innovate.',
  },
  [SIMULATOR]: {
    subTitle: 'User guide and helpful tips',
    description: 'View and download resources.',
  },
  [TUTORIAL]: {
    subTitle: 'Find the information to help you along the way',
    description: 'View or download a document.',
  },
};
