import yup, { string, object } from 'yup';
import { validationMessages } from '../constants/messages';

const { username, password } = validationMessages;

const validationSchema = object().shape({
  username: string().required(username.required),
  password: string().required(password.required),
});

export type LoginSchema = yup.InferType<typeof validationSchema>;
export default validationSchema;
