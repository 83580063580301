import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, iRootState } from '../../store';
import Layout from '../../components/Agreements/List';
import { Agreement } from 'westside-types';

const mapStateToProps = (state: iRootState) => ({
  account: state.account,
  personalProfile: state.profile.personalProfile,
  companyProfile: state.profile.companyProfile,
  agreements: state.agreement.agreements,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logOut: dispatch.account.logOut,
  showNotification: dispatch.snackbar.open,
  loadConfigAgreements: dispatch.agreement.loadConfigAgreements,
  setAgreementTypeConfig: dispatch.agreement.setAgreementTypeConfig,
});

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type LandingState = {};

@connect(mapStateToProps, mapDispatchToProps)
class ListContainer extends Component<ConnectedProps, LandingState> {
  componentDidMount() {
    const { loadConfigAgreements } = this.props;
    loadConfigAgreements();
  }

  private async handleAgreementSelected(id: string, type: Agreement.AgreementType) {
    const { loadConfigAgreements, setAgreementTypeConfig, showNotification } = this.props;
    await setAgreementTypeConfig({ id, type });
    showNotification('Agreement type set');
    await loadConfigAgreements();
  }

  render() {
    const { account, logOut, agreements, personalProfile, companyProfile } = this.props;

    return (
      <Layout
        account={account}
        personalProfile={personalProfile}
        companyProfile={companyProfile}
        logOut={logOut}
        agreements={agreements}
        onAgreementSelected={(id, type) => this.handleAgreementSelected(id, type)}
      />
    );
  }
}

export default ListContainer;
