import React, { FunctionComponent } from 'react';

export const IconGradient: FunctionComponent = () => (
  <svg
    style={{ width: '0', height: '0', position: 'absolute' }}
    aria-hidden="true"
    focusable="false"
  >
    <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor="#1C75BC" />
      <stop offset="100%" stopColor="#1C75BC" />
    </linearGradient>
  </svg>
);
