import { Resources } from 'westside-types';
import formatErrorMessage from '../lib/format-error-messages';
import initialState, { AppInitialState } from '../store/resources';
import { Dispatch } from '../store';
import api from '../lib/api';

export default {
  namespace: 'resources',
  state: initialState,
  reducers: {
    empty(state: AppInitialState) {
      return state;
    },
  },
  effects: (dispatch: Dispatch) => ({
    async getAllResources(): Promise<Resources.ResourceListEntry[]> {
      try {
        const response = await api.get(`/resource/list`);
        return response.data;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async getDocuments(): Promise<Resources.Document[]> {
      try {
        const response = await api.get(`/document`);
        return response.data.documents;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async addResource({
      resource,
      type,
    }: {
      resource: Resources.Resource;
      type: Resources.ResourceType;
    }): Promise<void> {
      try {
        return await api.post(`/resource`, { ...resource, type });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async addDocument({ document }: { document: Resources.Document }): Promise<void> {
      try {
        return await api.post(`/document`, document);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async getResourceDetails(id: string): Promise<Resources.Resource> {
      try {
        const resourceDetails = await api.get(`/resource/${id}`);
        return resourceDetails.data;
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async updateResource({
      id,
      resource,
      type,
    }: {
      id: string;
      resource: Resources.Resource;
      type: Resources.ResourceType;
    }): Promise<void> {
      try {
        return await api.put(`/resource/${id}`, { ...resource, type });
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async deleteResource(id: string): Promise<void> {
      try {
        return await api.delete(`/resource/${id}`);
      } catch (error) {
        throw formatErrorMessage(error);
      }
    },
    async deleteDocument(id: string): Promise<void> {
      try {
        return await api.delete(`/document/${id}`);
      } catch (e) {
        throw formatErrorMessage(e);
      }
    },
  }),
};
