export const generalMessages = {};

export const successMessages = {
  default: 'Yay - Success',
};

export const validationMessages = {
  field: {
    required: 'This field is required',
    email: 'Email must be a valid email',
  },
  username: {
    required: 'Username is required',
    email: 'Username should take email form',
  },
  password: {
    required: 'Password field is required',
  },
  name: {
    required: 'Name field is required',
  },
};

export const errorMessages = {
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  memberNotAuthd: 'User not authenticated',
  invalidJson: 'Response returned is not valid JSON',
  missingData: 'Missing data',
};
