import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;

  font-family: ${({ theme }) => theme.typography.fontFamily};
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.secondary.main};

  .MuiSvgIcon-root {
    margin-left: 5px;
  }
`;
