import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledPill = styled(IconButton)`
  height: ${({ small }) => (small ? '21px' : '25px')};
  border-radius: 16px;
  padding: 0 ${({ theme, contained }) => (!contained ? 0 : theme.spacing() * 2)}px;

  .MuiTypography-root {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  ${({ theme, contained, success, computedState }) => {

    if (computedState) {
      return `
        &,
        &:hover {
            background-color: ${theme.appReviewPalette[computedState]};
        }

        .MuiTypography-root {
            font-size: 10px;
            color: ${theme.palette.secondary.light}
        }  
      `;
    }

    return contained
      ? `
        &,
        &:hover {
            background-color: ${!success ? theme.pillPalette.default : theme.pillPalette.success};
        }  
        
        .MuiTypography-root {
            font-size: 10px;
            color: ${theme.palette.secondary.light}
        }
        `
      : `
        &:hover {
            background: none;
        }  
      `;
  }}
`;
